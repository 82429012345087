import React, { Component } from "react";
import { Link } from "react-router-dom";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import card_ico from "./../../../assets/images/card-ico.svg";
import paypal_ico from "./../../../assets/images/paypal-ico.svg";
import product_img1 from "./../../../assets/images/product-img1.png";
import truck_ico from "./../../../assets/images/truck-ico.svg";
import { PrimaryBtn } from "../../../component/button/Button";

class TopUp extends Component {
  render() {
    return (
      <>
        <section className="row main-section">
          <div className="container large summary-container topup-payment">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-12 review-title-wrap mb-4">
                    <Link to="/wallet" className="back-ico">
                      <img src={next_arrow} alt="icon" />
                    </Link>
                    <h1>Order Summary</h1>
                  </div>
                  <div className="col-12">
                    <div className="address-blk">
                      <div className="addr-sec">
                        <p>Complete the payment to top up your e-wallet.</p>
                        <h3>$ 100.00</h3>
                        <p>Singapore Dollar</p>
                      </div>
                      <a href="/#" className="text-link">
                        Change
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="payment-method">
                      <div className="row">
                        <div className="col-12">
                          <h5>Payment Method</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="pm-snip">
                            <input type="radio" name="payment-method" />
                            <div className="snip-cont">
                              <img
                                className="img-fluid mr-2"
                                src={card_ico}
                                alt="ico"
                              />
                              <h6>
                                Card <small>Pay the order with E-Wallet</small>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pm-snip">
                            <input type="radio" name="payment-method" />
                            <div className="snip-cont">
                              <img
                                className="img-fluid mr-2"
                                src={paypal_ico}
                                alt="ico"
                              />
                              <h6>
                                PayPal{" "}
                                <small>Pay the order with E-Wallet</small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="cart-table w-100">
                        <tbody>
                          <tr>
                            <td>
                              <input className="mr-0" type="checkbox" name="" />
                            </td>
                            <td>
                              <div className="single-product">
                                <div className="img-wrap">
                                  <img
                                    className="img-fluid"
                                    src={product_img1}
                                    alt="img"
                                  />
                                </div>
                                <div className="sp-cont">
                                  <h6>Myprotein Impact Whey Protein</h6>
                                  <p className="mb-2">Product Varian</p>
                                  <span className="price">x1</span>
                                </div>
                              </div>
                              <div className="shipping-opt">
                                <img src={truck_ico} alt="icon" />
                                <p>
                                  Shipping Option{" "}
                                  <span
                                    data-toggle="modal"
                                    data-target="#shipping-modal"
                                  >
                                    Standard Express
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="qty-sec mt-4">
                                <p className="mb-0">
                                  <strong>$ 0.00</strong>
                                </p>
                              </div>
                              <a
                                href="/#"
                                className="change"
                                data-toggle="modal"
                                data-target="#cart-modal"
                              >
                                Change
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input className="mr-0" type="checkbox" name="" />
                            </td>
                            <td>
                              <div className="single-product">
                                <div className="img-wrap">
                                  <img
                                    className="img-fluid"
                                    src={product_img1}
                                    alt="img"
                                  />
                                </div>
                                <div className="sp-cont">
                                  <h6>Myprotein Impact Whey Protein</h6>
                                  <p className="mb-2">Product Varian</p>
                                  <span className="price">x1</span>
                                </div>
                              </div>
                              <div className="shipping-opt">
                                <img src={truck_ico} alt="icon" />
                                <p>
                                  Shipping Option <span>Standard Express</span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="qty-sec mt-4">
                                <p className="mb-0">
                                  <strong>$ 0.00</strong>
                                </p>
                              </div>
                              <a
                                href="/#"
                                className="change"
                                data-toggle="modal"
                                data-target="#cart-modal"
                              >
                                Change
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input className="mr-0" type="checkbox" name="" />
                            </td>
                            <td>
                              <div className="single-product">
                                <div className="img-wrap">
                                  <img
                                    className="img-fluid"
                                    src={product_img1}
                                    alt="img"
                                  />
                                </div>
                                <div className="sp-cont">
                                  <h6>Myprotein Impact Whey Protein</h6>
                                  <p className="mb-2">Product Varian</p>
                                  <span className="price">x1</span>
                                </div>
                              </div>
                              <div className="shipping-opt">
                                <img src={truck_ico} alt="icon" />
                                <p>
                                  Shipping Option <span>Standard Express</span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="qty-sec mt-4">
                                <p className="mb-0">
                                  <strong>$ 0.00</strong>
                                </p>
                              </div>
                              <a
                                href="/#"
                                className="change"
                                data-toggle="modal"
                                data-target="#cart-modal"
                              >
                                Change
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="order-lft-snip">
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Sub Total</p>
                    <p className="col-4 text-right mb-0">$ $ 0.00</p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Estimated Tax</p>
                    <p className="col-4 text-right mb-0">$ $ 0.00</p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">
                      <strong>Total</strong>
                    </p>
                    <p className="col-4 text-right mb-0">
                      <strong>$ 100.00</strong>
                    </p>
                    <div className="col-12 mt-4">
                      <Link to="/wallet">
                        <PrimaryBtn text="Confirm Payment" block />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TopUp;
