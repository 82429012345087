import React from 'react'
import { Menu } from 'antd'

const ViewOrderSortingMenu = ({ handleClick }) => {
  return (
    <Menu style={{ padding: '0 8px'}}>
      <Menu.Item key="1" onClick={() => handleClick('Name')}>
        Name
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleClick('Quantity')}>
        Quantity
      </Menu.Item>{' '}
      <Menu.Item key="3" onClick={() => handleClick('Price')}>
        Price
      </Menu.Item>
      <Menu.Item key="4" onClick={() => handleClick('Order Time')}>
        Order Time
      </Menu.Item>
     
    </Menu>
  )
}

export default ViewOrderSortingMenu
