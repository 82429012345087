import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import api from "../../apis/index";

export default function Paypal(props) {
  console.log("Paypal props >>", props);
	let history = useHistory();
  const paypal = useRef();

  const createOrderConfirm = (order, callback) => {
    console.log("createOrder order >>", order);
    let requestBody = {
      profile_id: `${props.user.email}` || "N/A",
      payment_method: "paypal",
      order_id: `${order.id || "N/A"}`,
      paypal_order_id: `${order.id || "N/A"}`,
      paypal_payer_id: `${order.id || "N/A"}`,
      order_paypal_status: "COMPLETED",
      shipping_address: `${props.user.address}` || "N/A",
      shipping_recipient: `${props.user.name}` || "N/A",
      total_amount: `${props.amountValue ? props.amountValue : 0}`,
      products_details: `${props.orderDetailVal ? props.orderDetailVal : "N/A"}`,
      create_time: `${order.create_time || "N/A"}`,
      update_time: `${order.update_time || "N/A"}`,
      delivery_status: "0",
      order_items: JSON.stringify(props.items)
    };
    console.log("createOrder calling requestBody >>", requestBody);
    props.createOrder(requestBody, () => {
      console.log("createOrder callback", true);
						callback();
      // 8. empty cart after order payment success
      api.deleteUserCarts({ profile_id: requestBody.profile_id }).then(res => {
        console.log("Carts Deleted!", res);
      }).catch(err => {
        console.log("Error", err);
      })
    });
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            // purchase_units: props.items,
            purchase_units: [
              {
                amount: {
                  // charge users $499 per order
                  value: props.amountValue ? props.amountValue : 0,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("onApprove order >>", order);
          const cb = () => {
            // window.location = `/order-paid/${order.id}/`;
												history.push(`/order-paid/${order.id}/`);
												// history.push('/order-paid/543')
          };
          createOrderConfirm(order, cb);
        },
        onError: async (err) => {
          console.log("onError err", err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
