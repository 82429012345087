import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import truncate from "truncate";
import { ProductReviewCard, UserReviewCard } from "./ReviewCards";
import ReviewStat from "./ReviewStat";
import ReviewProgress, { ReviewNumbers } from "./ReviewChart";

export default function Reviews({
  ratingAvg = "5.0",
  totalR = "15",
  productName = "My Protein Impact Whey Protens",
  price = "0.0",
  desc = "Product description lorem ipusum dolor sit amet...",
  avatar,
  customerName = "Customer Name",
  date = "20/01/2022",
  review = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  rating,
  AvgStarRate = "5",
  customerStarRate = "3",
}) {
  return (
    <section className="row main-section">
      <div className="container large review-container">
        <Row>
          <div className="col-lg-12 mt-5">
            <Col span={24}>
              <h4>
                <Link to="/">
                  <FaArrowLeft />
                </Link>{" "}
                <span className="pl-3">{productName} | Reviews</span>
              </h4>
            </Col>
          </div>
        </Row>
        <div className="mt-5">
          <Row gutter={[46, 26]}>
            <Col lg={12} xl={8}>
              <ProductReviewCard
                productName={truncate(productName, 20)}
                desc={desc}
                price={price}
              />
            </Col>
            <Col xs={24} lg={12} xl={4}>
              <div className="text-center">
                <ReviewStat
                  ratingAvg={ratingAvg}
                  totalR={totalR}
                  AvgstarRate={AvgStarRate}
                />
              </div>
            </Col>
            <Col xs={24} lg={24} xl={12}>
              <Row gutter={0}>
                <Col xs={24} sm={6}>
                  <ReviewNumbers />
                </Col>
                <Col xs={24} sm={18}>
                  <ReviewProgress rate={rating} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <Row gutter={[20, { xs: 16, sm: 16, md: 24, lg: 28, xl: 35 }]}>
            {[...Array(9)].map(() => (
              <Col xs={24} lg={12} xl={12}>
                <UserReviewCard
                  avatar={avatar}
                  customerName={customerName}
                  date={date}
                  review={review}
                  customerStarRate={customerStarRate}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
}
