import Cookies from "js-cookie";

export const getCookie = (cookieName) => {
  return Cookies.get(cookieName);
};

export const setCookie = (cookieName, cookieValue) => {
  Cookies.set(cookieName, cookieValue);
};

export const removeCookie = (cookieName) => {
  Cookies.remove(cookieName);
};

// export const serverurl = "http://localhost:6002";
export const serverurl = "https://api.eamr.life";
// export const serverurl = "https://f542-105-113-19-159.ngrok-free.app";
