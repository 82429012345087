import {combineReducers} from 'redux'
import {reducer as reduxFormReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from "./App";
import modalReducer from "./Modal";
import AuthReducer from './AuthReducer';
import ProductReducer from './ProductReducer';
import userReducer from './userReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart']
}

const rootReducer = combineReducers({
	App: appReducer,
	auth:AuthReducer,
	user: userReducer,
	Modal: modalReducer,
	product: ProductReducer,
	form: reduxFormReducer
});


export default persistReducer(persistConfig, rootReducer);
