import React, { useContext } from "react";
import { Radio } from "antd";
import get from "lodash/get";
import map from "lodash/map";
import { FormContext } from "./hooks";
import { handleInputChange } from "./services";
import { CgAsterisk } from "react-icons/cg";

const RadioField = ({ label, name }) => {
    const { state, action } = useContext(FormContext);
    const options = get(state, `${name}.options`);
    const selectedValue = get(state, `${name}.value`) ?? "";

    return (
        <div className="col-lg-6">
            <div className="form-group">
                <label className="d-flex flex-column">
                    <span htmlFor={name} className="__labe_l mb-2">
                        {label}
                        {/* {require ? (
                            <sup>
                                <CgAsterisk className="text-danger small" />
                            </sup>
                        ) : (
                            <></>
                        )} */}
                    </span>
                    <div className="col-lg-6 px-0">
                        <div className="form-group">
                            <Radio.Group
                                className="d-flex pl-1"
                                name={name}
                                value={selectedValue}
                                onChange={(e) => {
                                    e.preventDefault();
                                    handleInputChange(state, action, name, {
                                        update: {
                                            [name]: {
                                                value: e.target.value,
                                            },
                                        },
                                    });
                                }}
                            >
                                {map(options, ({ value, label }, index) => (
                                    <Radio
                                        key={index}
                                        value={value}
                                        className={`${label==='Not comfortable to share'?'block-radio': null}`}
                                        style={{ marginRight: "16px" }}
                                    >
                                        {label}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default RadioField;
