import PropTypes from "prop-types";
import { format } from "date-fns";

const DateFormatter = ({ date, pattern = "dd/MM/yyyy HH:mm" }) => {
  return <>{format(new Date(date), pattern)}</>;
};

DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default DateFormatter;
