import {
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCT_REQUEST,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILED,
  PAYPAL_PAYMENT,
  PAYPAL_PAYMENT_FAILED,
} from "../Types";

export const getAllProductsActionRequest = (body, cb) => {
  return {
    type: GET_ALL_PRODUCTS_REQUEST,
    body: { ...body },
    cb: cb || null,
  };
};
export const getAllProductsActionSuccess = (data) => {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const getAllProductsActionFailed = (errorInfo) => {
  return {
    type: GET_ALL_PRODUCTS_FAILED,
    payload: errorInfo,
  };
};
export const getSingleProductActionRequest = (body, cb) => {
  return {
    type: GET_SINGLE_PRODUCT_REQUEST,
    body: { ...body },
    cb: cb || null,
  };
};
export const getSingleProductActionSuccess = (data) => {
  return {
    type: GET_SINGLE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const getSingleProductActionFailed = (errorInfo) => {
  return {
    type: GET_SINGLE_PRODUCT_FAILED,
    payload: errorInfo,
  };
};

export const paypalPaymentActionRequest = (body, cb) => {
  console.log(cb);
  return {
    type: PAYPAL_PAYMENT,
    body: { ...body },
    cb: cb || null,
  };
};

export const paypalPaymentActionFailed = (body, cb) => {
  console.log(cb);
  return {
    type: PAYPAL_PAYMENT_FAILED,
    body: { ...body },
    cb: cb || null,
  };
};
