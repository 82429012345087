import { Input, Modal, Tooltip } from 'antd';
import jsCookie from 'js-cookie';
import React, { useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { BsCart4, BsChatLeftDots, BsJournalText } from 'react-icons/bs';
import { FiLogIn, FiMoreVertical, FiUser, FiUserPlus } from 'react-icons/fi';
import { GrAppsRounded } from 'react-icons/gr';
import { IoHomeOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { removeCookie } from '../../../helper';
import { AUTH_STATUS } from '../../../store/actions/actions';
import {
  loginActionSuccess,
  userUpdateProductsSearch,
} from '../../../store/actions/user';
import { NavvLink } from './NavLink';
import './styles.css';

export default function MobileNav(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const signupbtnMentod = () => {
    // props.menuProps.getModal("", "Text", null, null, "register_modal");
    history.push('/signup');
  };
  const loginbtnMentod = () => {
    // props.menuProps.getModal(
    //   "",
    //   "Text",
    //   null,
    //   null,
    //   "send_otp_modal",
    //   props.getModal,
    //   props.closeModals
    // );
    history.push('/login');
  };

  const logoutUser = () => {
    alert('Logout');
    removeCookie('token');
    removeCookie('DeviceId');
    removeCookie('loggedIn');
    removeCookie('userinfo');
    jsCookie.remove('userinfo');
    history.push('/login');
    dispatch(loginActionSuccess({ type: AUTH_STATUS, payload: 0 }));
    window.location.reload();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <header className='fixed-bottom d-md-none px-3 py-3 __mobile_nav'>
        <nav className=''>
          <ul className='list-unstyled mb-0'>
            {/* Home */}
            <li className='nav-item'>
              <NavvLink to='/' title='Home' className='__mobile_navlink '>
                <span className='d-flex flex-column justify-content-center align-items-center'>
                  <Tooltip title='Home' color='#275DAD' placement='top'>
                    <IoHomeOutline />
                  </Tooltip>
                  <span className='__text_h'>Home</span>
                </span>
              </NavvLink>
            </li>
            {props.menuProps.userAuth ? (
              <>
                {/* Cart */}
                <li className='nav-item'>
                  <NavvLink to='/cart' className='__mobile_navlink'>
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='Cart' color='#275DAD' placement='top'>
                        <BsCart4 />
                      </Tooltip>
                      <span className='__text_h'>Cart</span>
                    </span>
                  </NavvLink>
                </li>
                {/* More items */}
                <li className='nav-item'>
                  <NavvLink to='/live' className='__mobile_navlink'>
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='More' color='#275DAD' placement='top'>
                        <GrAppsRounded style={{ fontSize: '1.4rem' }} />
                      </Tooltip>
                      <span className='__text_h font-weight-bold'>
                        Live Deal
                      </span>
                    </span>
                  </NavvLink>
                </li>
                {/* Chat */}
                <li className='nav-item'>
                  <NavvLink to='/chat' className='__mobile_navlink'>
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='Chat' color='#275DAD' placement='top'>
                        <BsChatLeftDots />
                      </Tooltip>
                      <span className='__text_h'>Chat</span>
                    </span>
                  </NavvLink>
                </li>
                {/* Profile */}
                <li className='nav-item'>
                  <button
                    type='button'
                    className='__mobile_navbtn'
                    onClick={() => setIsModalVisible(true)}
                  >
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='More' color='#275DAD' placement='top'>
                        <FiMoreVertical />
                      </Tooltip>
                      <span className='__text_h'>More</span>
                    </span>
                  </button>
                </li>
              </>
            ) : (
              <>
                {/* Terms and conditions */}
                <li className='nav-item'>
                  <NavvLink to='/terms' className='__mobile_navlink'>
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip
                        title='Terms/Conditions'
                        color='#275DAD'
                        placement='top'
                      >
                        <BsJournalText />
                      </Tooltip>
                      <span className='__text_h'>Terms</span>
                    </span>
                  </NavvLink>
                </li>
                {/* Login */}
                <li className='nav-item'>
                  <button
                    type='button'
                    className='__mobile_authbtn'
                    onClick={() => {
                      loginbtnMentod();
                    }}
                  >
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='Login' color='#275DAD' placement='top'>
                        <FiLogIn />
                      </Tooltip>
                      <span className='__text_h'>Log in</span>
                    </span>
                  </button>
                </li>
                {/* Sign up */}
                <li className='nav-item'>
                  <button
                    type='button'
                    className='__mobile_authbtn'
                    onClick={() => {
                      signupbtnMentod();
                    }}
                  >
                    <span className='d-flex flex-column justify-content-center align-items-center'>
                      <Tooltip title='Sign up' color='#275DAD' placement='top'>
                        <FiUserPlus />
                      </Tooltip>
                      <span className='__text_h'>Sign up</span>
                    </span>
                  </button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <MoreModal
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        logoutUser={logoutUser}
      />
    </>
  );
}

// More modal
function MoreModal({ isModalVisible, handleOk, handleCancel }, props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const logoutUser = () => {
    // alert('Logout');
    removeCookie('token');
    removeCookie('DeviceId');
    removeCookie('loggedIn');
    removeCookie('userinfo');
    jsCookie.remove('userinfo');
    history.push('/login');
    dispatch(loginActionSuccess({}));
    window.location.reload();
  };
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      title={null}
      zIndex={9999}
    >
      <ul className='list-unstyled mb-0 __modal_list'>
        {/* Live Deal */}
        {/* <li className="__modal_navitem" onClick={() => handleCancel()}>
          <NavvLink to="/live" className=" __modal_navlink">
            <span className="__icon text-white">
              <BsHeart size={22} />
            </span>
            <span>Live Deal</span>
          </NavvLink>
        </li> */}
        {/* Notifications */}
        {/* <li className="__modal_navitem">
          <NavvLink to="/notification" className=" __modal_navlink">
            <span className="__icon text-white">
              <MdOutlineNotificationsActive size={22} />
            </span>
            <span>Notifications</span>
          </NavvLink>
        </li> */}
        {/* Settings */}
        <li className='__modal_navitem'>
          <NavvLink to='/profile' className=' __modal_navlink'>
            <span className='__icon text-white'>
              <FiUser size={22} />
            </span>
            <span>Profile</span>
          </NavvLink>
        </li>
        {/* Terms */}
        <li className='__modal_navitem'>
          <NavvLink to='/terms' className=' __modal_navlink'>
            <span className='__icon text-white'>
              <BsJournalText size={22} />
            </span>
            <span>Terms and Conditions</span>
          </NavvLink>
        </li>

        {/* Log out */}
        <li className='__modal_navitem'>
          <button
            type='button'
            className=' __modal_navlink text-danger __modal_logout'
            onClick={logoutUser}
          >
            <span className='__icon text-white'>
              <BiLogOut size={22} />
            </span>
            <span>Log out</span>
          </button>
        </li>
      </ul>
    </Modal>
  );
}

// Search Modal

export function SearchModal({ isModalVisible, handleOk, handleCancel }) {
  const searchValue = useSelector((state) => state.user.headerSearchValue);
  const dispatch = useDispatch();
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      title={null}
      zIndex={9999}
    >
      <div className='mt-5'>
        <Input.Search
          allowClear
          placeholder='Search'
          size='large'
          value={searchValue}
          onChange={(e) => dispatch(userUpdateProductsSearch(e.target.value))}
        />
      </div>
    </Modal>
  );
}
