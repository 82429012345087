import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const PhoneNumberInput = ({ value, onValueChange }) => {
    return (
        <PhoneInput
            inputStyle={{
                backgroundColor: "rgb(255 255 255)",
                color: `black`,
                width: "100%"
            }}
            buttonStyle={{ backgroundColor: "rgb(255 255 255)" }}
            enableAreaCodeStretch
            placeholder={"Enter your telephone number*"}
            country={"sg"}
            value={value}
            onChange={(changeValue, country, e, formattedValue) => {
                onValueChange(changeValue, country, e, formattedValue);
            }}
        />
    );
};

export default PhoneNumberInput;
