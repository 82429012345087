import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styled from "@emotion/styled";
import { FaCalendarAlt } from "react-icons/fa";
// import { parseJSON } from "date-fns";

const DateWrapper = styled.div`
  position: relative;
  background: transparent;
  .react-datepicker-popper {
    width: max-content;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    background: transparent;
  }
  .react-datepicker__input-container input {
    border: 0;
    background: transparent;
    &:focus {
      outline: 0;
    }
  }
  .date-picker-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`;

const DatePickerComp = ({
  changeHandler = (date) => console.log(date),
  showTime = false,
  initialDate,
  updateDateComp = updateDateComp || 0,
  minDate = minDate || new Date(),
  showMinDate = true,
  ...rest
}) => {
  // console.log("DatePickerComp minDate >>", minDate);
  const dateFormat = showTime ? "d MMMM yyyy h:mm aa" : "d MMMM yyyy";
  const [date, setDate] = useState(new Date());
  const [showCalender, setShowCalender] = useState(false);

  const handleDate = (date) => {
    changeHandler(date);
    setDate(date);
  };

  // useEffect(() => {
  //   if (initialDate) {
  //    console.log("initial date >>", initialDate);
  //     setDate(initialDate);
  //   }
  // }, []);

  useEffect(() => {
    if (initialDate) {
      // console.log("initial date >>", initialDate);
      setDate(initialDate);
    }
  }, [updateDateComp]);

  return (
    <DateWrapper className="form-control">
      {showMinDate ? (
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          disabledKeyboardNavigation
          selected={date}
          onChange={(date) => handleDate(date)}
          showTimeSelect={showTime}
          dateFormat={dateFormat}
          minDate={minDate}
          autoFocus={showCalender}
          {...rest}
        />
      ) : (
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          disabledKeyboardNavigation
          selected={date}
          onChange={(date) => handleDate(date)}
          showTimeSelect={showTime}
          dateFormat={dateFormat}
          autoFocus={showCalender}
          {...rest}
        />
      )}
      <span className="date-picker-icon" onClick={setShowCalender}>
        <FaCalendarAlt />
      </span>
    </DateWrapper>
  );
};

export default DatePickerComp;
