import React, { Component } from "react";
import { Link } from "react-router-dom";
import product_img1 from "../../assets/images/product-img1.png";
import avatar from "../../assets/images/avatar.png";
import chat_ftr from "../../assets/images/chat-ftr.svg";
import "./shell.css";

const Chat = (props) =>{
    return (
      <>
        <section className="row main-section">
          <div className="container large chat-container">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <h5 className="col-12">Chat</h5>
                  <div className="col-12 chat-left">
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                    <div className="chat-blk">
                      <div className="avatar-img">
                        <img className="img-fluid" src={avatar} alt="" />
                      </div>
                      <div className="chat-cont">
                        <span className="name">Client Name</span>
                        <p className="msg">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vehicula euismod nunc, ut ultricies
                          mauris iaculis aliquet. Ut orci purus, convallis in..
                        </p>
                        <span className="time">01:00 PM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="chat-right">
                  <div className="chat-head">
                    <div className="avatar-img">
                      <img className="img-fluid" src={avatar} alt="icon" />
                    </div>
                    <h6>Client Name</h6>
                  </div>
                  <div className="chat-body">
                    <div className="chat-item justify-content-end">
                      <div className="deal-snip with-img">
                        <div className="ds-left">
                          <div className="prod-img">
                            <img className="img-fluid" src={product_img1} />
                          </div>
                          <div className="ch-desc">
                            <h6>Product Name</h6>
                            <p className="desc">
                              Product description lorem ipsum dolor sit amet..
                            </p>
                            <p className="opacity-3 mb-0">
                              <strong>$ 0.00</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="chat-item justify-content-end">
                      <span className="time mr-3">10:00 AM</span>
                      <div className="chat-bubble blue">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Donec quis rutrum purus..
                        </p>
                      </div>
                    </div>
                    <div className="chat-item justify-content-start">
                      <div className="chat-bubble">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Donec quis rutrum purus..
                        </p>
                      </div>
                      <span className="time ml-3">10:00 AM</span>
                    </div>
                  </div>
                  <div className="chat-footer">
                    <img className="img-fluid" src={chat_ftr} alt="ico" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

export default Chat;
