import {Space, Typography, Rate} from 'antd'

const { Title, Text } = Typography;

// Review Average
export default function ReviewStat({ratingAvg, totalR, AvgstarRate}) {
  return (
    <Space direction="vertical" align="center" size="small">
      <Title level={1} strong>
        {ratingAvg}
      </Title>
      <Rate disabled value={AvgstarRate} />
      <Text strong>Based on {totalR} reviews</Text>
    </Space>
  );
}