import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  toggleOpen,
  logoutUser,
  deleteWishlistActionRequest,
  viewWishlistActionRequest,
} from "../../../store/actions/user";
import { openModal, closeModal } from "../../../store/actions/Modals";
import CartItem from "./WishlistItem";
import cogoToast from "cogo-toast";
import { SecBtn } from "../../../component/button/Button";
import NoResult from "../Error/NoResult";

const Wishlist = (props) => {
  // console.log("Cart props >>", props.userWishlist);
  const [cartCount, setCartCount] = useState(0);

  // loadUserWishlist
  useEffect(() => {
    console.log("useEffect Cart >>", true);
    if (props.user && props.user.email) {
      let requestBody = {
        profile_id: props.user.email,
      };
      props.loadUserWishlist(requestBody);
    }
  }, []);

  const deleteWishlistItem = (id) => {
    console.log("deleteWishlistItem id/sno", id);
    let requestBody = {
      sno: id,
    };
    props.deleteWishlistItemActionRequest(requestBody, () => {
      if (props.user && props.user.email) {
        let requestBody = {
          profile_id: props.user.email,
        };
        props.loadUserWishlist(requestBody);
      }
    });
  };
  if (props.userWishlist && props.userWishlist.length > 0) {
    return (
      <>
        <section className="row main-section">
          <div className="container large cart-container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12 review-title-wrap mb-4">
                    <h1>Wishlist</h1>
                    <button
                      onClick={() => {
                        setCartCount(cartCount + 1);
                      }}
                      className="btn btn-outline"
                    >
                      Refresh
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="cart-table w-100">
                        <thead>
                          <tr>
                            <th colspan="3">
                              <label className="select-all">
                                <input type="checkbox" name="" />
                                Select all
                              </label>
                            </th>
                          </tr>
                        </thead>
                        {props.userWishlist && props.userWishlist.length > 0 ? (
                          <tbody>
                            {props.userWishlist.map((item) => {
                              return (
                                <CartItem
                                  deleteItem={() => {
                                    deleteWishlistItem(item.sno);
                                  }}
                                  item={item}
                                />
                              );
                            })}
                          </tbody>
                        ) : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section className="row main-section">
          <div className="container large cart-container">
            <div className="row">
              <div className="col-12 review-title-wrap mb-4">
                <h1>Wishlist</h1>
                <SecBtn
                  text="Refresh"
                  onClick={() => {
                    setCartCount(cartCount + 1);
                  }}
                />
              </div>
            </div>
          </div>
          <NoResult
            desc="Your wishlist is Empty. Please add products."
            link="/"
            text="Add products"
            _chat={true}
          />
        </section>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
  userWishlist: state.user.userWishlist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    loadUserWishlist: (...args) => {
      dispatch(viewWishlistActionRequest(...args));
    },
    deleteWishlistItemActionRequest: (...args) => {
      dispatch(deleteWishlistActionRequest(...args));
    },
    toggleOpen: (status) => dispatch(toggleOpen(status)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
