import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { HiOutlineTruck } from 'react-icons/hi'
import StarRating from '../StarRating'
import './style.css'

const dummyData = [
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '1',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '2',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '4',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '5',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '4',
    product_id: '6',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '7',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '1',
    product_id: '8',
  },
  {
    ordered_time: '20/04/2021',
    product_img: '',
    product_name: 'Product Name',
    product_variant: 'Product Variant',
    product_no: '1',
    product_price: '0.00',
    ordered_time_full: '2021-01-01 01:44 PM',
    product_description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero in autem magnam dolore, sequi recusandae laboriosam odit a aliquid sit obcaecati quis eaque et sed rem molestiae omnis! Possimus, eaque.',
    order_id: '2',
    product_id: '9',
  },
]

const ProductReviewList = () => {
  const history = useHistory()
  const handleClick = (product_id) =>
    history.push('/product-detail/' + product_id)
  return (
    <div className="prl__container">
      <div className="prl__header">
        <ArrowLeftOutlined />
        <p>My Reviews</p>
      </div>
      <div class="prl__card-container">
        {dummyData.map((order) => (
          <div className="prl__card" key={order.order_id + order.product_id}>
            <div className="prl__card__header">{`Ordered On ${order.ordered_time}`}</div>
            <div className="prl__card__product-few-details">
              <img src={order.product_img} width="80px" height="80px" alt="" />
              <div className="prl__card__product-name-section">
                <p>{order.product_name}</p>
                <p>{order.product_variant}</p>
                <p>{`x${order.product_no}`}</p>
              </div>
              <div className="prl__card__product-price-section">{`$ ${order.product_price}`}</div>
            </div>
            <div className="prl__card__product-status-details">
              <div className="prl__card__product-delivery-details">
                <HiOutlineTruck size={28} className="prl__icon" />{' '}
                <span className="prl__text">{`Item Received on ${order.ordered_time}`}</span>
              </div>
              <p>{order.ordered_time_full}</p>
            </div>
            <div className="prl__card__product-rating-and-date">
              <StarRating rate={3} />
              <p>{order.ordered_time}</p>
            </div>
            <div className="prl__card__product-des">
              {order.product_description}
            </div>
            <button
              className="prl__card-btn"
              onClick={() => handleClick(order.order_id, order.product_id)}
            >
              View Product
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductReviewList
