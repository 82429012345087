import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "./style.css";

const PhoneNumberInput = ({
    value,
    placeholder = "",
    changeHandler,
    error = "",
    disabled,
    inputStyle,
    buttonStyle,
}) => {
    return (
        <div
            className={`phone-number-input-with-country ${
                error ? "invalid" : "valid"
            }`}
        >
            <PhoneInput
                inputStyle={inputStyle}
                buttonStyle={buttonStyle}
                enableAreaCodeStretch
                placeholder={placeholder}
                disabled={disabled}
                country={"us"}
                value={value}
                onChange={(changeValue, country, e, formattedValue) => {
                    changeHandler(changeValue, country, e, formattedValue);
                }}
            />
            {error && <div className="phone-number-input-error">{error}</div>}
        </div>
    );
};

export default PhoneNumberInput;
