import React, { Component } from "react";
import { Link } from "react-router-dom";
import product_img1 from "./../../../assets/images/product-img1.png";
import trash_ico from "./../../../assets/images/trash-ico.svg";


const WishListItem = (props) => {
  // console.log("CartItem props >>", props);
  return (
    <>
      <tr>
        <td>
          <input className="mr-0" type="checkbox" name="" />
        </td>
        <td>
          <div className="single-product">
            <div className="img-wrap">
              <img
                className="img-fluid"
                src={
                  props.item.product_photo &&
                  process?.env?.REACT_APP_PRODUCTIMAGE_BASEPATH
                    ? `${process?.env?.REACT_APP_PRODUCTIMAGE_BASEPATH}${props.item.product_photo}`
                    : product_img1
                }
                alt="img"
              />
            </div>
            <div className="sp-cont">
              <h6>
                {props.item.product_name ? props.item.product_name : "N/A"}
              </h6>
              <p className="mb-2">Product Varian</p>
              <span className="price">
                $ {props.item.product_price ? props.item.product_price : "N/A"}
              </span>
            </div>
          </div>
          {/* <div className="shipping-opt">
            <img src={truck_ico} alt="icon" />
            <p>
              Shipping Option <span>Standard Express</span>
            </p>
          </div> */}
        </td>
        <td>
          <div className="qty-sec">            
            {/* <a href="#" className="trash-ico"> */}
            <img
              className="img-fluid"
              onClick={() => {
                if (props.deleteItem) {
                  props.deleteItem();
                }
              }}
              src={trash_ico}
              alt="icon"
            />
            {/* </a> */}
          </div>          
        </td>
      </tr>
    </>
  );
};

export default WishListItem;

// userWishlist
// newWishlistItem