import styled from "styled-components";
import { Button } from "antd";

export const TableButton = styled(Button)`
  border: 1px solid #007bff;
  outline: none;
  color: #007bff;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer
  transition: 0.5s;
  // color: ${(props) => (props.disabled ? "#007bff" : "#464696")} !important;
  &:hover{
    color: ${(props) => (props.disabled ? "" : "#fff")} !important;
    background-color: #007bff;
    outline:none
  }
  &:disabled{
    border: 1px solid;
    outline:none;
    transition:none;
    pointer-events:none
  }
`;

export const StyledButton = styled(Button)`
  outline: none;
  background-color: #275DAD !important;
  color: ${(props) => (props.disabled ? "#464696" : "#fff")} !important;
  border-radius: 20px !important;
  padding: 4px 16px;
  width: 100%;
  height: 32px;
  ${'' /* border: 1px solid #275DAD;
  border-radius: 20px; */}
  &:hover,
  &:focus {
    background-color: #fff !important;
    border: 1px solid #464696 !important;
    color: #464696 !important;
  }
`;

export const TableSwitchButton = styled.button`
  padding: 0 16px;
  height: 32px;
  width: 100%;
  line-height: 1;
  border: 1px solid #9694ac;
  color: #9694ac;
  background-color: transparent;
  cursor: pointer;
  &:focus,
  &:hover {
    color: #1d194d;
  }
`;

export const AddButton = styled.button`
  border: 1px solid #49d3ce;
  border-radius: 50px;
  height: 40px;
  width: 120px;
  font-size: 16px;
  // margin-top: 16px;
  color: #49d3ce;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #fff !important;
    background-color: #49d3ce;
  }
`;
