export const AUTH_START = "AUTH_START"
export const AUTH_STATUS = "AUTH_STATUS"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const MESSAGE_FROM_BACKEND = "MESSAGE_FROM_BACKEND"
export const AUTH_FAILURE = "AUTH_FAILURE"
export const AUTH_ERROR = "AUTH_ERROR"
export const TOKEN_EXPIRED = "AUTH_FAILURE"
export const AUTH_LOGOUT = "AUTH_LOGOUT"

/*Modal Action*/

export const MODAL_CLOSE = "MODAL_CLOSE"
export const TOGGLE_OPEN = "TOGGLE_OPEN"
export const UPDATE_SYNC_ERRORS = `@@redux-form/UPDATE_SYNC_ERRORS`

/*Product Action*/

export const UCT_LIST = "PRODUCT_LIST"
export const PRODUCT_DETAIL = "PRODUCT_DETAIL"
