import { useContext } from "react";
import { CgAsterisk } from "react-icons/cg";
import get from "lodash/get";
import { FormContext } from "./hooks";
import { handleInputChange } from "./services";

// Input with label field
export const InputLabel = ({ label, type, require, name, placeholder }) => {
    const { state, action } = useContext(FormContext);
    const errMsg = get(state, `${name}.errMsg`);
    return (
        <div className="col-lg-6">
            <div className="form-group">
                <div className="d-flex flex-column">
                    <label htmlFor={name} className="__labe_l mb-2">
                        {label}
                        {require ? (
                            <sup>
                                <CgAsterisk className="text-danger small" />
                            </sup>
                        ) : (
                            <></>
                        )}
                    </label>
                    <input
                        style={{ outline: errMsg ? "1px solid #dc3545" : "" }}
                        className="form-control __custom_input rounded-sm"
                        type={type || "text"}
                        name={name}
                        placeholder={placeholder}
                        value={get(state, `${name}.value`) ?? ""}
                        onChange={(e) => {
                            e.preventDefault();
                            handleInputChange(state, action, name, {
                                propToValidate: `${name}.value`,
                                update: { [name]: { value: e.target.value } },
                            });
                        }}
                    />
                    {errMsg ? (
                        <span className="form-error-span">{errMsg}</span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
