import React, { useState, useEffect } from "react";
import ContentHeader from "./contentHeader";
import TableWrapper from "../../component/react-table";
import RadioWrapper from "../../component/radioButton/Index";
import userAvatar from "../../images/Tim-01.png";
import AvatarWrapper from "../../component/avatar/index";
import {
  TableButton,
  StyledButton,
} from "../../component/styled-component/styledButton";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Select, Tooltip, Radio, Modal } from "antd";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
// import UserDetailModal from "./UserDetailModal";
// import { setCookie } from "../../helper";
import orderData from "../../mockdata/orders";
import "./shell.css";

const { Option } = Select;

const OrderList = (props) => {
  const history = useHistory();
  console.log("Props >>>> ", props);
  const [customerId, setCustomerId] = useState("");


  // Table Filter
  const [data, setData] = useState([]);
  const [textbox, setTextbox] = useState("");
  const [selectbox, setSelectbox] = useState("");
  const [UserData, setUserData] = useState("");

  const textboxSearch = (e) => {
    setTextbox(e.target.value);
  };

  const selectboxSearch = (value) => {
    setSelectbox(value);
  };

  const textSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      return (
        value.customer.toLowerCase().includes(textbox.toLowerCase())
      );
    });
    setData(filteredData);
  };

  const selectSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      console.log(value, "value >>>");
      return value.orderStatus
        .toLowerCase()
        .includes(selectbox.toLowerCase());
    });
    setData(filteredData);
  };

  // Actions
  const actionHandle = (r) => {
    console.log("Response >>>> ", r);
    if (r.original.applicationStatus === "submitted") {
      return (
        <>
          <Tooltip title="Accept">
            <AiOutlineCheckCircle
              onClick={() => console.log("AiOutlineCheckCircle onClick", true)}
              style={{ color: "green", fontSize: "25px", cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Decline">
            <AiOutlineCloseCircle
              onClick={() => console.log("AiOutlineCloseCircle onClick", true)}
              style={{
                color: "red",
                fontSize: "25px",
                cursor: "pointer",
                marginLeft: 15,
              }}
            />
          </Tooltip>
        </>
      );
    } else if (r.original.applicationStatus === "approved") {
      return (
        <p
          style={{
            color: "green",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Accepted
        </p>
      );
    } else if (r.original.applicationStatus === "rejected") {
      return (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Rejected
        </p>
      );
    } else if (r.original.applicationStatus === "pending") {
      return (
        <p
          style={{
            display: "inline-block",
            color: "black",
            fontSize: "12px",
            marginBottom: 0,
            width: "intrinsic" /* Safari/WebKit uses a non-standard name */,
            width: "-moz-max-content" /* Firefox/Gecko */,
            width: "-webkit-max-content" /* Chrome */,
            width: "max-content",
          }}
        >
          Application not submitted
        </p>
      );
    }
  };

  useEffect(() => textSearch(), [textbox]);
  useEffect(() => selectSearch(), [selectbox]);
  useEffect(() => setData(props.tableData), [props.tableData]);

  console.log("UserData", UserData);
  return (
    <div style={{ width: "100%" }}>
      {/* <UserDetailModal
        modal={modal}
        setModal={setModal}
        data={UserData}
        title="User Detail"
      /> */}
      <ContentHeader
        title="Orders List"
        handleTextSearch={textboxSearch}
        handleSelectSearch={selectboxSearch}
        // count={props.tableData ? props.tableData.length : 0}
        count={data.length}
        options={[
          { label: "View All", value: "" },
          { label: "Delivered", value: "Delivered" },
          { label: "Canceled", value: "Canceled" },
          { label: "Pending", value: "Pending" },
        ]}
      />
      <div style={{ minWidth: 1280, overflow: "auto", padding: 5 }}>
        <TableWrapper
          tableData={data}
          loading={props.loading}
          pageSize={6}
          enableReInit={true}
          content={[
            {
              name: "Order Date",
              id: "date",
              small: true,
              sortable: false,
              render: (r) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >                    
                    <span>
                      {r.original.date}
                    </span>
                  </div>
                );
              },
            },
            { name: "Customer", id: "customer", sortable: false },
            {
              name: "Order Id",
              id: "orderId",
              sortable: false,
            },
            // {
            //   name: "Paid",
            //   id: "actions",
            //   sortable: false,
            //   render: (r) => {
            //     return (
            //       <div>
            //         <RadioWrapper
            //           key={r.original._id}
            //           value={r.original.isPaid}
            //           onChange={(e) => {
            //             console.log("RadioWrapper onChange", e);
            //           }}
            //         >
            //           <Radio value={true}>Yes</Radio>
            //           <Radio value={false}>No</Radio>
            //         </RadioWrapper>
            //       </div>
            //     );
            //   },
            // },

            // {
            //   name: "Details",
            //   id: "status",
            //   sortable: false,
            //   render: (r) => {
            //     return (
            //       <TableButton
            //         onClick={() => {
            //           // setModal(true);
            //           // setUserData(r.original);
												// 										console.log("TableButton onClick >>", true);
            //         }}
            //         disabled={
            //           r.original.applicationStatus === "pending"
            //             ? "disabled"
            //             : ""
            //         }
            //       >
            //         <b>View</b>
            //       </TableButton>
            //     );
            //   },
            // },            
            {
              name: "Action",
              id: "orderId",
              sortable: false,
              render: (r) => {
                return (
                  <StyledButton
																				style={{width:"40%"}}
                    onClick={() =>
                      // window.open(`${r.original.orderId}`, "_blank")
																						console.log("onClick orderId", true)
                    }
                    // disabled={
                    //   r.original.applicationStatus === "approved"
                    //     ? ""
                    //     : "disabled"
                    // }
                  >
                    {/* <a href={r.original.orderId} target="_blank"> */}
                      View Order
                    {/* </a> */}
                  </StyledButton>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    tableData: state.getAllOrders || orderData,
    // garbage: state.getAllOrders.garbage || null,
  };
};

export default connect(mapStateToProps)(OrderList);
