import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import close_dark from "./../../assets/images/close-dark-ico.svg";
import next_arrow from "./../../assets/images/next-arrow.svg";
import common from "../../common";
import { registerValidation } from "../../validation";
import RegisterForm from "../../container/Modal/RegisterForm";
import { openModal, closeModal } from "../../store/actions/Modals";

const RegisterModal = (props) => {
  // console.log("RegisterModal >>", props);

  return (
    <Modal backdrop= {'static'} show={props.visible} onHide={props.closeModals}>
      <Modal.Body>
        <div className="login-modal" id="login-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={props.closeModals}
                data-dismiss="modal"
              >
                <img src={close_dark} alt="icon" />
              </button>
              <div className="modal-body">
                <div className="login-encl">
                  <h5>Register</h5>
                  <RegisterForm
                    getModal={props.getModal}
                    closeModals={props.closeModals}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  is_show: state.auth.is_show,
  authstatus: state.auth.authstatus,
  authmessage: state.auth.authmessage,
  dropdown: state.auth.dropdown,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);
