import Notify from "cogo-toast";
import filter from "lodash/filter";
import api from "../../../apis";

export const deleteDeal = (userId, dealId) => {
  return api
    .deleteDeal({ userid: userId, dealid: dealId })
    .then((res) => {
      if (res.status === 200) {
        Notify.success(res.message);
        return true;
      } else {
        Notify.error("Error deleting live deal");
        return false;
      }
    })
    .catch((err) => {
      Notify.error("Error deleting live deal");
      return false;
      // console.log(err);
    });
};

export const excludeObjByKey = (dealKeyValue, allDeals, key) => {
  return filter(allDeals, (deal) => deal[key] !== dealKeyValue);
};

export const excludeObj = (dealToRemove, allDeals) => {
  return filter(allDeals, (deal) => deal !== dealToRemove);
};

export const sortDeals = (state) => {
  const isAscend = state.isAscend ? 1 : -1;
  let sortFn;

  console.log("isAscend");
  if (state.sortType === "Search") {
    sortFn = (a, b) => compareStrWithAscend(a.deal_name, b.deal_name, isAscend);
  } else if (state.sortType === "Min Price") {
    sortFn = (a, b) =>
      isAscend * (parseFloat(a.min_value) - parseFloat(b.min_value));
  } else if (state.sortType === "Max Price") {
    sortFn = (a, b) =>
      isAscend * (parseFloat(a.max_value) - parseFloat(b.max_value));
  } else if (state.sortType === "Result") {
    sortFn = (a, b) =>
      isAscend * (getDealResult(state, a) - getDealResult(state, b));
  } else if (state.sortType === "Date Added") {
    return isAscend
      ? [...(state.deals ?? [])].reverse()
      : [...(state.deals ?? [])];
  } else {
    throw new Error("Invalid sort type");
  }

  return [...(state.deals ?? [])].sort(sortFn);
};

export const getFromOffsetToLimit = (arr, offset, limit) => {
  return arr.slice(offset, offset + limit);
};

export const truncateNum = (num, min, max) => {
  if (num < min) return min;
  if (num > max) return max;
  return num;
};

function compareStr(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

function compareStrWithAscend(a, b, isAscend) {
  return isAscend * compareStr(a, b);
}

export function getDealResult(state, deal) {

  return state.products.filter((product) => {
    const dealname = deal.deal_name.toLowerCase();
    const checkName = dealname.replace(/ /g, "");
    const productName = product.name.toLowerCase();
    const ps = productName.split(" ");
    const tests = dealname.split(" ");

    return (
    (
      productName.replace(/ /g, "").indexOf(checkName) > -1 ||
      productName.replace(/ /g, "").indexOf(checkName.slice(0,9)) > -1 ||
      (ps[0]+(ps[2] || "")+(ps[1] || "")).indexOf(checkName) > -1 ||
      ((ps[1] || "")+ps[0]+(ps[2] || "")).indexOf(checkName) > -1 ||
      (ps[0]+(ps[1] || "")+(ps[3] || "")).indexOf(checkName) > -1 ||
      ((ps[2] || "")+ps[0]+(ps[1] || "")).indexOf(checkName) > -1 ||
      ((ps[2] || "")+(ps[1] || "")+ps[0]).indexOf(checkName) > -1 ||
      productName.match(RegExp(`^(?=.*${tests[0]})^(?=.*${tests[1] || tests[0]}).*${tests[tests.length-1]}`))
    ) &&
      product.price >= deal.min_value &&
      product.price <= deal.max_value
)
  //   return (
  //     (
  //       product.name.toLowerCase().indexOf(deal.deal_name.toLowerCase()) > -1 ||
  //       deal.deal_name.toLowerCase().indexOf(product.name.toLowerCase()) > -1||
  //       deal.deal_name.replace(/ /g, "").toLowerCase().indexOf(product.name.toLowerCase()) > -1 ||
  //       product.name.replace(/ /g, "").toLowerCase().indexOf(deal.deal_name.toLowerCase()) > -1 ||
  //       product.name.toLowerCase().match(RegExp(`^(?=.*${dealname[0]})^(?=.*${dealname[1] || dealname[0]}).*${dealname[dealname.length-1]}`))
  //         ) &&
  //     product.price >= deal.min_value &&
  //     product.price <= deal.max_value
  //   );
  }).length;
}
