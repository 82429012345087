import * as Types from "../Types";
import jsCookie from "js-cookie";

export const toggleOpen = (data) => {
    return {
        type: Types.TOGGLE_OPEN,
        payload: data,
    };
};
export const logoutUser = () => {
    jsCookie.remove("userinfo");
    return {
        type: Types.LOGOUT,
        // payload: data
    };
};
export const registerActionRequest = (body, cb) => {
    return {
        type: Types.REGISTER_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const registerActionSuccess = (data) => {
    return {
        type: Types.REGISTER_SUCCESS,
        payload: data,
    };
};
export const registerActionFailed = (errorInfo) => {
    return {
        type: Types.REGISTER_FAILED,
        payload: errorInfo,
    };
};

export const loginActionRequest = (body, cb) => {
    return {
        type: Types.LOGIN_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const loginActionSuccess = (data) => {
    return {
        type: Types.LOGIN_SUCCESS,
        payload: data,
    };
};
export const loginActionFailed = (errorInfo) => {
    return {
        type: Types.LOGIN_FAILED,
        payload: errorInfo,
    };
};
export const sendOtpActionRequest = (body, cb) => {
    return {
        type: Types.SEND_OTP_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const sendOtpActionSuccess = (data) => {
    return {
        type: Types.SEND_OTP_SUCCESS,
        payload: data,
    };
};
export const sendOtpActionFailed = (errorInfo) => {
    return {
        type: Types.SEND_OTP_FAILED,
        payload: errorInfo,
    };
};
export const verifyOtpActionRequest = (body, cb) => {
    return {
        type: Types.VERIFY_OTP_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const verifyOtpActionSuccess = (data) => {
    return {
        type: Types.VERIFY_OTP_SUCCESS,
        payload: data,
    };
};
export const verifyOtpActionFailed = (errorInfo) => {
    return {
        type: Types.VERIFY_OTP_FAILED,
        payload: errorInfo,
    };
};
export const viewCartActionRequest = (body, cb) => {
    return {
        type: Types.VIEW_CART_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const viewCartActionSuccess = (data) => {
    return {
        type: Types.VIEW_CART_SUCCESS,
        payload: data,
    };
};
export const viewCartActionFailed = (errorInfo) => {
    return {
        type: Types.VIEW_CART_FAILED,
        payload: errorInfo,
    };
};
export const addCartActionRequest = (body, cb) => {
    return {
        type: Types.ADD_CART_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};

export const addCartActionSuccess = (data) => {
    return {
        type: Types.ADD_CART_SUCCESS,
        payload: data,
    };
};
export const addCartActionFailed = (errorInfo) => {
    return {
        type: Types.ADD_CART_FAILED,
        payload: errorInfo,
    };
};
export const deleteCartActionRequest = (body, cb) => {
    return {
        type: Types.DELETE_CART_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const deleteCartActionSuccess = (data) => {
    return {
        type: Types.DELETE_CART_SUCCESS,
        payload: data,
    };
};
export const deleteCartActionFailed = (errorInfo) => {
    return {
        type: Types.DELETE_CART_FAILED,
        payload: errorInfo,
    };
};
export const deleteCartItemActionRequest = (body, cb) => {
    return {
        type: Types.DELETE_CART_ITEM_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const deleteCartItemActionSuccess = (data) => {
    return {
        type: Types.DELETE_CART_ITEM_SUCCESS,
        payload: data,
    };
};
export const deleteCartItemActionFailed = (errorInfo) => {
    return {
        type: Types.DELETE_CART_ITEM_FAILED,
        payload: errorInfo,
    };
};
export const incrementCartActionRequest = (body, cb) => {
    return {
        type: Types.INCREMENT_CART_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const incrementCartActionSuccess = (data) => {
    return {
        type: Types.INCREMENT_CART_SUCCESS,
        payload: data,
    };
};
export const incrementCartActionFailed = (errorInfo) => {
    return {
        type: Types.INCREMENT_CART_FAILED,
        payload: errorInfo,
    };
};
export const decrementCartActionRequest = (body, cb) => {
    return {
        type: Types.DECREMENT_CART_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const decrementCartActionSuccess = (data) => {
    return {
        type: Types.DECREMENT_CART_SUCCESS,
        payload: data,
    };
};
export const decrementCartActionFailed = (errorInfo) => {
    return {
        type: Types.DECREMENT_CART_FAILED,
        payload: errorInfo,
    };
};
export const userProfileActionRequest = (body, cb) => {
    return {
        type: Types.USER_PROFILE_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const userProfileActionSuccess = (data) => {
    return {
        type: Types.USER_PROFILE_SUCCESS,
        payload: data,
    };
};
export const userProfileActionFailed = (errorInfo) => {
    return {
        type: Types.USER_PROFILE_FAILED,
        payload: errorInfo,
    };
};
export const userUpdateActionRequest = (body, cb) => {
    return {
        type: Types.USER_UPDATE_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const userUpdateActionSuccess = (data) => {
    return {
        type: Types.USER_UPDATE_SUCCESS,
        payload: data,
    };
};
export const userUpdateActionFailed = (errorInfo) => {
    return {
        type: Types.USER_UPDATE_FAILED,
        payload: errorInfo,
    };
};
export const addReviewActionRequest = (body, cb) => {
    return {
        type: Types.ADD_REVIEW_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const addReviewActionSuccess = (data) => {
    return {
        type: Types.ADD_REVIEW_SUCCESS,
        payload: data,
    };
};
export const addReviewActionFailed = (errorInfo) => {
    return {
        type: Types.ADD_REVIEW_FAILED,
        payload: errorInfo,
    };
};
export const getReviewActionRequest = (body, cb) => {
    return {
        type: Types.GET_REVIEW_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const getReviewActionSuccess = (data) => {
    return {
        type: Types.GET_REVIEW_SUCCESS,
        payload: data,
    };
};
export const getReviewActionFailed = (errorInfo) => {
    return {
        type: Types.GET_REVIEW_FAILED,
        payload: errorInfo,
    };
};
export const checkoutActionRequest = (body, cb) => {
    return {
        type: Types.CHECKOUT_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const checkoutActionSuccess = (data) => {
    return {
        type: Types.CHECKOUT_SUCCESS,
        payload: data,
    };
};
export const checkoutActionFailed = (errorInfo) => {
    return {
        type: Types.CHECKOUT_FAILED,
        payload: errorInfo,
    };
};
export const createOrderActionRequest = (body, cb) => {
    return {
        type: Types.CREATE_ORDER_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const createOrderActionSuccess = (data) => {
    return {
        type: Types.CREATE_ORDER_SUCCESS,
        payload: data,
    };
};
export const createOrderActionFailed = (errorInfo) => {
    return {
        type: Types.CREATE_ORDER_FAILED,
        payload: errorInfo,
    };
};
export const userOrdersActionRequest = (body, cb) => {
    return {
        type: Types.USER_ORDERS_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
// userOrders:[],
// 		newOrder:[],
// 		orderDetail:[],
export const userOrdersActionSuccess = (data) => {
    return {
        type: Types.USER_ORDERS_SUCCESS,
        payload: data,
    };
};
export const userOrdersActionFailed = (errorInfo) => {
    return {
        type: Types.USER_ORDERS_FAILED,
        payload: errorInfo,
    };
};

/**
 *
 * @param {string} newSearchValue
 * @returns
 */
export const userUpdateProductsSearch = (newSearchValue) => {
    return {
        type: Types.USER_UDPATE_HEADER_SEARCH_VALUE,
        payload: newSearchValue,
    };
};

export const orderDetailActionRequest = (body, cb) => {
    return {
        type: Types.ORDER_DETAIL_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const orderDetailActionSuccess = (data) => {
    return {
        type: Types.ORDER_DETAIL_SUCCESS,
        payload: data,
    };
};
export const orderDetailActionFailed = (errorInfo) => {
    return {
        type: Types.ORDER_DETAIL_FAILED,
        payload: errorInfo,
    };
};
/// Deals
export const addDealActionRequest = (body, cb) => {
    return {
        type: Types.ADD_DEAL_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const addDealActionSuccess = (data) => {
    return {
        type: Types.ADD_DEAL_SUCCESS,
        payload: data,
    };
};
export const addDealActionFailed = (errorInfo) => {
    return {
        type: Types.ADD_DEAL_FAILED,
        payload: errorInfo,
    };
};
export const viewDealActionRequest = (body, cb) => {
    return {
        type: Types.VIEW_DEAL_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const viewDealActionSuccess = (data) => {
    return {
        type: Types.VIEW_DEAL_SUCCESS,
        payload: data,
    };
};
export const viewDealActionFailed = (errorInfo) => {
    return {
        type: Types.VIEW_DEAL_FAILED,
        payload: errorInfo,
    };
};
export const deleteDealActionRequest = (body, cb) => {
    return {
        type: Types.DELETE_DEAL_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const deleteDealActionSuccess = (data) => {
    return {
        type: Types.DELETE_DEAL_SUCCESS,
        payload: data,
    };
};
export const deleteDealActionFailed = (errorInfo) => {
    return {
        type: Types.DELETE_DEAL_FAILED,
        payload: errorInfo,
    };
};
/// Wishlist
export const addWishlistActionRequest = (body, cb) => {
    return {
        type: Types.ADD_WISHLIST_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const addWishlistActionSuccess = (data) => {
    return {
        type: Types.ADD_WISHLIST_SUCCESS,
        payload: data,
    };
};
export const addWishlistActionFailed = (errorInfo) => {
    return {
        type: Types.ADD_WISHLIST_FAILED,
        payload: errorInfo,
    };
};
export const viewWishlistActionRequest = (body, cb) => {
    return {
        type: Types.VIEW_WISHLIST_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const viewWishlistActionSuccess = (data) => {
    return {
        type: Types.VIEW_WISHLIST_SUCCESS,
        payload: data,
    };
};
export const viewWishlistActionFailed = (errorInfo) => {
    return {
        type: Types.VIEW_WISHLIST_FAILED,
        payload: errorInfo,
    };
};
export const deleteWishlistActionRequest = (body, cb) => {
    return {
        type: Types.DELETE_WISHLIST_REQUEST,
        body: { ...body },
        cb: cb || null,
    };
};
export const deleteWishlistActionSuccess = (data) => {
    return {
        type: Types.DELETE_WISHLIST_SUCCESS,
        payload: data,
    };
};
export const deleteWishlistActionFailed = (errorInfo) => {
    return {
        type: Types.DELETE_WISHLIST_FAILED,
        payload: errorInfo,
    };
};

//Live deal
export const addLiveDealRequest = (data) => {
    return {
        type: Types.ADD_DEAL_REQUEST,
        payload: data,
    };
};
export const addLiveDealSuccess = (data) => {
    return {
        type: Types.ADD_DEAL_SUCCESS,
        payload: data,
    };
};
export const addLiveDealFailed = (data) => {
    return {
        type: Types.ADD_DEAL_FAILED,
        payload: data,
    };
};
