import React from 'react'
import { Image } from "antd";

import illus from "../../assets/images/auth.svg";
import logo from "../../assets/images/logo.png";

const Auth = ({title, subTitle, children}) => {
  return (
    <div className="__auth_container">
        <div className="col-lg-5 d-none d-lg-block px-0 text-center">
            <div className="__info_img">
                <Image
                    src={illus}
                    preview={false}
                    alt="login"
                    width={500}
                />
            </div>
        </div>
        <div className="__auth_content py-5 px-0 h-100 col-lg-7">
            <div className="__form_container col-10 h-100 mx-auto">
                <div className="__auth_home-btn_container">
                    <a href="/">Products Page &#8594;</a>
                </div>
                <div className="__info_content overflow-auto">
                    <div className="__info_con mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <Image
                                src={logo}
                                alt="EAMR Logo"
                                width={50}
                                preview={false}
                            />
                            <h3>EAMR</h3>
                        </div>
                        <h4>{title}</h4>
                        <p>{subTitle}</p>
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default Auth