import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Checkbox } from 'antd'
import { InputLabel, SelectLabel } from './Input'
import { getPhoneFromUser } from './service'

export default function ShippingAddress({
  formik,
  isChecked,
  onToggleChecked,
}) {

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-12">
          <Checkbox
            checked={isChecked}
            onChange={onToggleChecked}
            style={{ fontSize: '17px', fontWeight: 600 }}
          >
            Same as Billing Address
          </Checkbox>
        </div>
        {/* Street */}
        <div className="form-group mb-3 mt-4 col-12">
          <InputLabel
            name="street"
            label="No. &amp; Street name"
            value={formik.values.street}
            onChange={formik.handleChange}
            placeholder="17, Sample Street"
            {...formik.getFieldProps('street')}
          />
          {formik.touched.street && formik.errors.street ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.street}
            </p>
          ) : null}
        </div>
        {/* City */}
        <div className="form-group mb-3 col-12">
          <InputLabel
            name="city"
            label="City/Area/Locality"
            value={formik.values.city}
            onChange={formik.handleChange}
            placeholder="Sample city area locality"
            {...formik.getFieldProps('city')}
          />
          {formik.touched.city && formik.errors.city ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.city}
            </p>
          ) : null}
        </div>
        {/* Landmark */}
        <div className="form-group mb-3 col-12">
          <InputLabel
            name="landmark"
            label="Landmark"
            value={formik.values.landmark}
            onChange={formik.handleChange}
            placeholder="Sample landmark"
            {...formik.getFieldProps('landmark')}
          />
          {formik.touched.landmark && formik.errors.landmark ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.landmark}
            </p>
          ) : null}
        </div>
        {/* Country */}
        <div className="form-group mb-3 col-md-6 pr-md-0">
          <SelectLabel
            name="country"
            label="Country"
            value={formik.values.country}
            choices={['Nigeria', 'Singapore', 'India']}
            onChange={formik.handleChange}
            defaultValue="--select country--"
            {...formik.getFieldProps('country')}
          />
          {formik.touched.country && formik.errors.country ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.country}
            </p>
          ) : null}
        </div>
        {/* Postal code */}
        <div className="form-group mb-3 col-md-6 pl-md-0">
          <InputLabel
            name="postalCode"
            label="Postal Code"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            placeholder="`e.g 34093`"
            {...formik.getFieldProps('postalCode')}
          />
          {formik.touched.postalCode && formik.errors.postalCode ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.postalCode}
            </p>
          ) : null}
        </div>
      </div>
    </form>
  )
}

// Billing Address
export function BillingAddress({ formik }) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Street */}
        <div className="form-group mb-3 mt-md-5 col-12">
          <InputLabel
            name="street"
            label="No. &amp; Street name"
            value={formik.values.street}
            onChange={formik.handleChange}
            placeholder="17, Sample Street"
            {...formik.getFieldProps('street')}
          />
          {formik.touched.street && formik.errors.street ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.street}
            </p>
          ) : null}
        </div>
        {/* City */}
        <div className="form-group mb-3 col-12">
          <InputLabel
            name="city"
            label="City/Area/Locality"
            value={formik.values.city}
            onChange={formik.handleChange}
            placeholder="Sample city area locality"
            {...formik.getFieldProps('city')}
          />
          {formik.touched.city && formik.errors.city ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.city}
            </p>
          ) : null}
        </div>
        {/* Landmark */}
        <div className="form-group mb-3 col-12">
          <InputLabel
            name="landmark"
            label="Landmark"
            value={formik.values.landmark}
            onChange={formik.handleChange}
            placeholder="Sample landmark"
            {...formik.getFieldProps('landmark')}
          />
          {formik.touched.landmark && formik.errors.landmark ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.landmark}
            </p>
          ) : null}
        </div>
        {/* Country */}
        <div className="form-group mb-3 col-md-6 pr-md-0">
          <SelectLabel
            name="country"
            label="Country"
            value={formik.values.country}
            choices={['Nigeria', 'Singapore', 'India']}
            onChange={formik.handleChange}
            defaultValue="--select country--"
            {...formik.getFieldProps('country')}
          />
          {formik.touched.country && formik.errors.country ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.country}
            </p>
          ) : null}
        </div>
        {/* Postal code */}
        <div className="form-group mb-3 col-md-6 pl-md-0">
          <InputLabel
            name="postalCode"
            label="Postal Code"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            placeholder="`e.g 34093`"
            {...formik.getFieldProps('postalCode')}
          />
          {formik.touched.postalCode && formik.errors.postalCode ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.postalCode}
            </p>
          ) : null}
        </div>
      </div>
    </form>
  )
}

// General Information

export function GeneralInformation({userData}) {
  const formik = useFormik({
    initialValues: {
      fullname: userData?.name ?? '',
      email: userData?.email ?? '',
      phone: getPhoneFromUser(userData) ?? ''
    },

    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('FullName Is Required.'),
      email: Yup.string().required('Email Is Required'),
      phone: Yup.string()
        .typeError('Contact Number is not valid')
        .matches(/^\+\d{9,18}/, "Contact Number is not valid")
        .required('Contact Number Is Required'),
    }),

    onSubmit: (e) => {
      e.preventDefault()
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Fullname */}
        <div className="form-group mb-3 col-md-6">
          <InputLabel
            name="fullname"
            label="Full Name"
            value={formik.values.fullname}
            onChange={formik.handleChange}
            placeholder="`e.g John Doe`"
            {...formik.getFieldProps('fullname')}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.fullname}
            </p>
          ) : null}
        </div>
        {/* Phone */}
        <div className="form-group mb-3 col-md-6">
          <InputLabel
            // type="number"
            name="phone"
            label="Contact No#"
            value={formik.values.phone}
            onChange={formik.handleChange}
            placeholder="`e.g +234803 5571 435`"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.phone}
            </p>
          ) : null}
        </div>
        {/* Email */}
        <div className="form-group mb-3 col-md-6">
          <InputLabel
            type="email"
            name="email"
            label="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="`e.g johndoe908@domain.com`"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <p
              className="mb-0"
              style={{
                paddingTop: '0.5rem',
                fontSize: '12px',
                color: 'red',
              }}
            >
              {formik.errors.email}
            </p>
          ) : null}
        </div>
      </div>
    </form>
  )
}
