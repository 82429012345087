import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import set from "lodash/set";
import some from "lodash/some";
import { registerActionRequest } from "../../../store/actions/user";
import { initialFormState, FormContext } from "./hooks";
import { InputLabel } from "./input-field";
import PhoneNumberInput from "./phone-input";
import { fieldsName as fn, handleSubmit } from "./services";
import RadioField from "./radio-field";

// SEE: Login use Phone hook, line 10 for how field Error works. Errors for displaying error messages
const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Not comfortable to share", value: "Others" },
    
];

const SignUpForm = () => {
    const [formState, setFormState] = useState(
        set(initialFormState, `${fn.gender}.options`, genderOptions)
    );
    const dispatch = useDispatch();
    const history = useHistory()
    return (
        <FormContext.Provider
            value={{ state: formState, action: setFormState }}
        >
            <Form
                noValidate
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formState, fn, (reqBody) =>{
                        console.log(reqBody);
                        console.log(formState)
                        dispatch(registerActionRequest(reqBody, () => history.push('/login')))
                    }
                    );
                }}
            >
                <div className="row">
                    <InputLabel
                        label="Name"
                        name={fn.name}
                        require
                        placeholder="`Full Name`"
                    />
                    <InputLabel
                        type="email"
                        label="Email"
                        name={fn.email}
                        require
                        placeholder="`test@example.com`"
                    />

                    <PhoneNumberInput
                        label="Phone"
                        name={fn.phone}
                        require
                        placeholder={`"Enter your telephone number"`}
                    />

                    <RadioField label="Gender" name={fn.gender}  />

                    <InputLabel
                        type="date"
                        label="Date of Birth"
                        name={fn.dateOfBirth}
                        placeholder="`Select date of birth`"
                    />

                    <InputLabel
                        label="Address"
                        name={fn.address}
                        require
                        placeholder="`Shipping Address`"
                    />

                    <InputLabel
                        label="Referral Code"
                        name={fn.refCode}
                        placeholder="`Referral Code`"
                    />

                    <div className="col-12">
                        <button
                            type="submit"
                            className="__auth_btn mx-auto"
                            disabled={
                                some(
                                    formState,
                                    (inputState) => !!inputState.errMsg
                                ) 
                                // ||
                                // some(fn, (field) => !formState[field]?.value)
                            }
                        >
                            Sign Up
                        </button>
                    </div>
                    <div className="text-center pb-3 mt-3 mx-auto">
                        <span> Already have an account? </span>
                        <Link className="font-weight-bold" to="/login">
                            Log In
                        </Link>
                    </div>
                </div>
            </Form>
        </FormContext.Provider>
    );
};

export default SignUpForm;
