import * as Yup from "yup";
import { useFormik } from "formik";

export const useAddressFormik = (initialValues) => {
    return useFormik({
        initialValues: initialValues ?? {
            street: "",
            city: "",
            landmark: "",
            country: "",
            postalCode: "",
        },

        validationSchema: Yup.object().shape({
            street: Yup.string().required("Street Is Required."),
            city: Yup.string().required("City Is Required"),
            country: Yup.string().required("Country Is Required"),
        }),

        onSubmit: (e) => {
            e.preventDefault();
        },
    });
};
