import React from "react";
import { Menu } from "antd";
import MenuWrapper from "../menu";
import { Link } from "react-router-dom";
import { Col } from "antd";
import {
  SendOutlined,
  DashboardOutlined,
  ReadOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const LeftSideBar = (props) => {
  console.log("Props in Menu bar:", props);
  return (
    <MenuWrapper mode="inline" theme="light" inlineCollapsed={props.collapsed}>
      <Menu.Item key="1" icon={<DashboardOutlined />}>
        <Link to="/dashboard">Products</Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<SettingOutlined />}>
        <Link to="/dashboard/orders-list">Order</Link>
      </Menu.Item>

      <Menu.Item key="3" icon={<SendOutlined />}>
        <Link to="/dashboard/chat">Chat</Link>
      </Menu.Item>

      <Menu.Item key="4" icon={<ReadOutlined />}>
        <Link to="/dashboard/profile">Profile</Link>
      </Menu.Item>
    </MenuWrapper>
  );
};
export default LeftSideBar;
