import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Typography, Tooltip } from "antd";
import truncate from "truncate";
import next_arrow from "./../../../assets/images/next-arrow.svg";
// import product_img1 from "./../../../assets/images/product-img1.png";
import arrow_small_ico from "./../../../assets/images/arrow-small-ico.svg";
import chat_ico from "./../../../assets/images/chat-ico.svg";
import jsCookie from "js-cookie";
import api from "../../../apis/index";
import Notify from "cogo-toast";
import { SecBtn } from "../../../component/button/Button";
import Layout from "../Layouts/PageLayouts";
import { deleteDeal } from "./service";

const { Text } = Typography;
class LiveDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            item: {},
            dealresults: [],
        };
    }

    componentDidMount() {
        const userid = JSON.parse(jsCookie.get("userinfo")).sno;
        const deal = this.props.location.state;
        console.log("Deallal", deal);
        if (!deal || !deal.id) {
            // this.props.location.push = "add-live";
            this.props.history.replace("/add-live");
        }
        this.setState({ item: deal, userId: userid }, () => {
            api.liveDealResults({ userid, deal_id: deal ? deal.id : "" })
                .then((res) => {
                    this.setState({ dealresults: res.data });
                    console.log(res.data)
                })
                .catch((err) => {
                    Notify.error("Error fetching results..");
                    console.log(err);
                });
        });
        // console.log({ userid, deal_id:"" });
        // api.liveDealDetails({}).then(res => {
        //     console.log(res)
        // })
        console.log("Propss", this.props.location);
    }

    render() {
        return (
            <Layout classNamt="" style={{ marginTop: "200px" }}>
                <div className="container-fluid large cart-container">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-12 review-title-wrap mb-5">
                                    <h1>
                                        <div
                                            onClick={() =>
                                                this.props.history.goBack()
                                            }
                                            className="back-ico"
                                        >
                                            <img src={next_arrow} alt="icon" />
                                        </div>
                                        Live Deal
                                    </h1>
                                    <div className="__live_details_btn_container">
                                        <button
                                            className="__live__btn __live__btn_update"
                                            onClick={() =>
                                                this.props.history.push({
                                                    pathname: "/update-live",
                                                    state: this.props.location
                                                        .state,
                                                })
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="__live__btn __live__btn_delete"
                                            onClick={() =>
                                                // this.props.history.push({
                                                //     pathname: "/delete-live",
                                                //     state: this.props.location
                                                //         .state,
                                                // })
                                                deleteDeal(
                                                    this.state.userId,
                                                    this.state.item.id
                                                ).then(
                                                    (isSuccessful) =>
                                                        isSuccessful &&
                                                        this.props.history.push(
                                                            "/live"
                                                        )
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 mt-md-2 mt-lg-4">
                                    <div className="row mb-2">
                                        <p className="col-md-3 opacity-4 mb-0">
                                            <strong>Product Name</strong>
                                        </p>
                                        <p className="col-md-9 opacity-4 mb-0">
                                            {this.state.item
                                                ? this.state.item.deal_name
                                                : ""}
                                        </p>
                                    </div>
                                    <div className="row mb-2">
                                        <p className="col-md-3 opacity-4 mb-0">
                                            <strong>Price Range</strong>
                                        </p>
                                        <p className="col-md-9 opacity-4 mb-0">
                                            $
                                            {this.state.item
                                                ? this.state.item.min_value
                                                : ""}{" "}
                                            - $
                                            {this.state.item
                                                ? this.state.item.max_value
                                                : ""}
                                        </p>
                                    </div>
                                    <div className="row mb-2">
                                        <p className="col-md-3 opacity-4 mb-0">
                                            <strong>Created On</strong>
                                        </p>
                                        {this.state.item ? (
                                            <p className="col-md-9 opacity-4 mb-0">
                                                {new Date(
                                                    this.state.item.date
                                                ).getDate()}
                                                /
                                                {new Date(
                                                    this.state.item.date
                                                ).getMonth() < 9
                                                    ? "0" +
                                                      (1 +
                                                          new Date(
                                                              this.state.item.date
                                                          ).getMonth())
                                                    : 1 +
                                                      new Date(
                                                          this.state.item.date
                                                      ).getMonth()}
                                                /{" "}
                                                {new Date(
                                                    this.state.item.date
                                                ).getFullYear()}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="row my-4">
                                        <p className="col-12 opacity-4 mb-0">
                                            {this.state.dealresults.length}{" "}
                                            Products that matches the Live Deal.
                                        </p>
                                    </div>
                                    <div className="row">
                                        {this.state.dealresults.map(
                                            (result) => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="deal-snip with-img mb-4">
                                                            <div className="ds-left">
                                                                <div className="prod-img">
                                                                    <Image
                                                                        src={
                                                                            result.photo1
                                                                        }
                                                                        preview={
                                                                            false
                                                                        }
                                                                        alt="product"
                                                                        width={
                                                                            60
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                            border:
                                                                                "1px solid #eee",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <Link
                                                                    to={
                                                                        "/product-detail/" +
                                                                        result.sno
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            result.name
                                                                        }
                                                                        color="#275DAD"
                                                                        placement="top"
                                                                    >
                                                                        <Text
                                                                            className="mb-0"
                                                                            style={{
                                                                                lineHeight:
                                                                                    "1.3",
                                                                            }}
                                                                            // title={result.name}
                                                                            strong
                                                                        >
                                                                            {truncate(
                                                                                result.name,
                                                                                70
                                                                            )}
                                                                        </Text>
                                                                    </Tooltip>
                                                                    <p className="opacity-3 mb-0 mt-1 text-dark">
                                                                        <strong>
                                                                            $
                                                                            {
                                                                                result.price
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                </Link>
                                                            </div>
                                                            <div className="ds-right">
                                                                <Link
                                                                    to="/chat"
                                                                    className="chat mr-3"
                                                                >
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={
                                                                            chat_ico
                                                                        }
                                                                        alt="icon"
                                                                    />
                                                                </Link>
                                                                <Link
                                                                    to={
                                                                        "/product-detail/" +
                                                                        result.sno
                                                                    }
                                                                    className="next"
                                                                >
                                                                    <img
                                                                        src={
                                                                            arrow_small_ico
                                                                        }
                                                                        alt="icon"
                                                                    />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(LiveDetail);
