import React, { Component } from "react";
import { Link } from "react-router-dom";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import calendar_ico from "./../../../assets/images/calendar-ico.svg";
import PageFooter from "../../../component/footer/Footer";

class Terms extends Component {
  render() {
    return (
      <>
        <section className="row main-section pb-0">
          <div className="container large cart-container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-12 review-title-wrap mb-4">
                    <h1>
                      <Link to="/" className="back-ico">
                        <img src={next_arrow} alt="icon" />
                      </Link>
                      Privacy Policy and Terms & Conditions
                    </h1>
                    {/* <button className="btn choose-date">
                      <img className="img-fluid" src={calendar_ico} alt="ico" />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
								<div className="row main-section notify-list p-0">
          <div className="container large">
            <div className="row">
              <div className="col-12">
                <p className="fs-11 opacity-3">                  
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row main-section notify-list p-0">
          <div className="container large">
            <div className="row">
              <div className="col-12 terms-container">
                <div>
                  <h3 style={{padding: "10px"}}>INTRODUCTION</h3>
                  <p style={{padding: "10px"}}>We are committed to protecting and respecting your privacy.<br /><br/>
                  EAMR collects User Data from you in order to provide you with a safe, smooth, efficient and customized experience with EAMR. The collection of User Data enables EAMR to provide services and products that are most likely to meet your needs and requirements.<br/><br/>
                  As such, this Private Policy outlines EAMR‘s policy and responsibility in relation to the collection, processing and disclosure of your User Data.
                  </p>
                </div>
                <div>
                  <h4>Types of User Data</h4>
                  <p style={{padding: "10px"}}>The types of User Data that EAMR collects depends on the circumstances of collection and on the nature of the service requested or transaction undertaken. There are two broad categories of User Data that we collect at EAMR:</p>
                  <ol type="I">
                    <li>Individual User Data which is personal information that links back to an individual e.g. name, address, phone number and email address etc., and</li>
                    <li>Statistical Data e.g. hits to website or mobile application. This is stored purely for analytical purposes and is entirely anonymous. This information will not be stored to your User record and will only be aggregated for statistical analysis so that we can better understand EAMR’s users profile and improve EAMR service offering.</li>
                  </ol>
                  <p style={{padding: "10px"}}>For purposes of this policy statement, the phrase User Data includes individual and statistical data.</p>
                </div>

                <div>
                  <h3>OPENNESS</h3>
                  <p>Our data protection policy (Privacy Policy) is displayed on the web site and the policy is set out in the same language medium as the web site. We encourage all users to read our Privacy Policy to understand the objective of collecting their personal data.</p>
                  <p>All complaints and requests can be made to the following email address: support@eamr.life</p>
                </div>

                <div>
                  <h3>PURPOSE FOR COLLECTION, PROCESSING AND DISCLOSURE</h3>
                  <h4>Collection </h4>
                  <p>Generally, EAMR collects User Data in order to fulfill the following purposes:</p>
                  <ul>
                    <li>- Providing service to you such as processing your purchase and facilitate transactions</li>
                    <li>- To personalize your experience and improve customer service;</li>
                    <li>- Marketing and email communicating with you in relation to available products offered by  EAMR, and our affiliated service providers;</li>
                    <li>- Safety, security and legal compliance</li>
                  </ul>
                  <p>Please note that only necessary personal data is collected from registered users for business purposes only. Both the amount and the type of data collected shall be limited to that which is necessary to fulfill the purposes identified above.</p>
                  <p>We offer you choices regarding the collection, use, and sharing of your Personal Information. You have a right at any time to stop us from contacting you for marketing purposes. When you receive promotional communications from us, you may indicate a preference to stop receiving further promotional communications from us and you will have the opportunity to "opt-out" by following the unsubscribe instructions provided in the promotional e-mail you receive or by contacting us directly at support@eamr.life.</p>
                
                  <h4>Accountability</h4>
                  <ol type="I">
                    <li>EAMR will be responsible for all personal data collected. All personal data collected will be used and processed fairly and lawfully while in our possession or custody. We ensure all users that we would be accountable for our organisation's compliance to our Privacy Policy.</li>
                    <li>Should there be a requirement for data are to be transferred to someone (other than the individual or the organisation or its employees), we will take reasonable steps to ensure that the data which is to be transferred will not be processed inconsistently with this Privacy Policy</li>
                    <li>The identity of the person(s) designated by our organisation to oversee the organisation's compliance with the principles shall be made known upon request.</li>
                  </ol>

                  <h4>Processing and Disclosure</h4>
                  <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.</p>
                  <p>We may process and disclose your User Data with and to third parties, such as delivery service providers, products suppliers or business-related parties.  EAMR shall use its best endeavor to ensure that its employees, officers, agents, consultants, contractors and such other parties mentioned above who are involved in the collection, processing and disclosure of User Data will observe and adhere to the terms of this Privacy Policy.</p>
                  <p>In addition, we may disclose User Data to our lawyers and legal advisors for establishing, exercising or defending our legal rights, or as otherwise authorized or required by law. Further,  EAMR may disclose User Data as is necessary to prevent a threat to  EAMR and to investigate suspected unlawful activities including but not limited to fraud, intellectual property infringement or privacy.</p>
                </div>

                <div>
                  <h3>TRANSFER OF INFORMATION OVERSEAS</h3>
                  <p>EAMR is based in Singapore but User Data may be transmitted to data storage facilities where we keep our central records. Your User Data is transferred for EAMR to per our contract with you. The User Data may also be transmitted to our offices and appointed agents in other countries in connection with our performance of the contract with you.</p>
                </div>

                <div>
                  <h3>CONSENT</h3>
                  <p>In the course of  EAMR performing servicing functions such as sourcing and supplying products, User Data will by nature of the transaction, be provided by users to  EAMR. In such instances, consent will necessarily be implied from the users that they are agreeable to providing User Data in order for EAMR to provide the requested or necessary service and/or products to them. However, if and when required by law, EAMR will adopt an “opt-in” policy for attaining users consent, in which event, express written consent will be sought from users when collecting your User Data e.g. signing a form or checking a box.</p>
                  <p>You are able to withdraw your consent at any time by contacting EAMR in writing.</p>
                </div>

                <div>
                  <h3>RETENTION</h3>
                  <p> EAMR will retain your User Data for as long as it is necessary to fulfill the purpose for which it was collected or as required by relevant laws. Dormant data (either you have closed your account with us or have unsubscribe from our database) will be purged from our database after 7 years</p>
                </div>

                <div>
                  <h3>ACCURACY</h3>
                  <p> EAMR NEEDS YOUR ASSISTANCE TO ENSURE THAT YOUR User Data is current, complete and accurate. As such, please inform EAMR of changes to your User Data by contacting EAMR and submitting your updated particulars to EAMR in writing.  EAMR may also request User Data updates from you from time to time. As detailed above under the “Processing and Disclosure” sub-section, your User Data and transaction involving you may be disclosed to appropriate parties and/or authorities involved as required by law. As such, it is important to ensure that User Data contained is current, complete and accurate.</p>
                </div>

                <div>
                  <h3>INDIVIDUAL ACCESS AND CORRECTION</h3>
                  <p>Users can access their personal information by using their respective username and password to gain access to their personal information.</p>
                  <p>If your personal information changes, please contact support@eamr.life for further assistance.</p>
                  <p>We will respond to our customer’s request within a reasonable time.</p>
                </div>

                <div>
                  <h3>SECURITY SAFEGUARDS</h3>
                  <p>We use cookies to enhance your online experience while logging onto to EAMR Cookies are alphanumeric identifiers that are transferred to your computer’s hard drive from your web browser to recognize your preference and to tailor content to you. The cookies do not contain your User Data (unless you specifically chose our “Remember Me” feature) but only statistical data which is entirely anonymous. Please refer to your browser documentation to check if cookies have been enabled on your computer or to request not to receive cookies.</p>
                  <p>Information and procedures regarding cookies handling are provided by the respective browsers documentation within the browser itself by clicking on “help” and performing a search on “cookies”.</p>
                </div>

                <div>
                  <h3>COOKIES</h3>
                  <p>In order to improve your online experience, EAMR may track online behavior or clickstream data to advance your use of our web pages and track referrals from other websites. Such data will not be stored to your User record and will only be aggregated for statistical analysis</p>
                </div>

                <div>
                  <h3>CLICKSTREAM DATA</h3>
                  <p>In order to improve your online experience, EAMR may track online behavior or clickstream data to advance your use of our web pages and track referrals from other websites. Such data will not be stored to your User record and will only be aggregated for statistical analysis</p>
                </div>

                <div>
                  <h3>LINKS TO OTHER WEBSITES</h3>
                  <p>We provide you with links to other websites for your convenience and information. While we will protect your User Data on EAMR website, we cannot control the policies of other sites we may have a link to, or the use of any User Data you may share with them. Our Privacy Policy does not cover these other websites and strongly advise that you are apprised of their specific policies.</p>
                </div>

                <div>
                  <h3>PROTECTION OF MINORS</h3>
                  <p>Children (users under the age of 18 years) are not eligible to use our services or purchase any products online unsupervised.</p>
                  <p>We request that children do not submit any personal information to us online. If you are under the age of 18 years, you may only surf the Site only if you have obtained consent from your parents/legal guardian or if you are under the supervision of your parent or legal guardian.</p>
                </div>

                <div>
                  <h3>CHALLENGING COMPLIANCE</h3>
                  <p>Our mechanisms and processes are in place to receive and address complaints or inquiries about our policies and procedures relating to the handling of personal data. Appropriate measures will be taken to amend policies and procedures if a complaint is found to be justified.</p>
                </div>

                <div>
                  <h3>UPDATES TO THE PRIVACY POLICY</h3>
                  <p>We will amend this Privacy Policy from time to time and the updated versions will be posted on our website and date stamped so that you are aware of when the Privacy Policy was last updated.</p>
                  <p>Subject to applicable laws, the prevailing language of the Privacy Policy will be English. In the event of any inconsistency in interpretation between the English version and any translation of the Privacy Policy, this Privacy Policy statement in English shall prevail.</p>
                </div>

                <div>
                  <h3>CONTACT US</h3>
                  <p>If you have comments or questions about this Privacy Policy statement, please contact us at support@eamr.life, titled “Privacy Policy”.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter />
      </>
    );
  }
}

export default Terms;
