import { Avatar, Image, Space } from 'antd';
import { BsCartPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import defaultImg from './../../../assets/images/default.png';

// System notification
export default function SystemChat({
  imageSrc,
  merName,
  pdtName,
  msg,
  date,
  link,
  status,
  deleteNotification,
  ...rest
}) {
  return (
    <>
      <div className='__chat' {...rest}>
        <div className='d-flex align-items-center'>
          {/* <Avatar
            size={40}
            className="__avtr"
            src={logo}
          /> */}
          <Image src={logo} preview={false}  className="product_avtr" alt={pdtName} width={40} height={40}  />
          <div
            className='__block'
            style={status == 0 ? { color: '#ccc' } : { color: '#111' }}
          >
            <span
              className='__mer'
              style={status == 0 ? { color: '#ccc' } : { color: '#111' }}
            >
              {merName}
            </span>
            <p className='__pdt mb-0 d-sm-flex align-items-center'>
              {/* {pdtName ? <BsCartPlus className="d-none d-sm-block" /> : <></>} */}
              <span
                className='__pdt_name'
                style={status == 0 ? { color: '#ccc' } : { color: '#111' }}
              >
                {pdtName}
              </span>
            </p>
            <p>
              {msg} product(s) matches your live deal. Click here to see listing
              {/* <Link to={link} className="__sys_clink_">
              <span className="__lst"> {msg}</span>
            </Link> */}
            </p>
            {status == 0 && (
              <a href='#' onClick={deleteNotification} style={{ color: 'red' }}>
                Delete
              </a>
            )}
          </div>
        </div>
        <div className='__dtmg'>
          <p className='__dat_e'>{date}</p>
          {/* <Image
            src={imageSrc}
            preview={false}
            alt="product"
            width={60}
            style={{ borderRadius: "5px", border: "1px solid #eee" }}
          /> */}
        </div>
      </div>
    </>
  );
}

// Merchant chat

export const MerchantChat = ({
  avatar,
  imageSrc,
  merName,
  pdtName,
  lastMsg,
  date,
  count,
  ...rest
}) => {
  return (
    <>
      <div className='__chat' {...rest}>
        <div className='d-flex align-items-center'>
          <Avatar
            size={60}
            className='__avtr'
            style={{ border: '1px solid #eee' }}
            src={avatar}
          />
          <div className='__block'>
            <span className='__mer'>{merName}</span>
            <p className='__pdt mb-0 d-sm-flex align-items-center'>
              {pdtName ? <BsCartPlus className='d-none d-sm-block' /> : <></>}
              <span className=' pl-sm-1'>{pdtName}</span>
            </p>
            <span className='__lst'>{lastMsg}</span>
          </div>
        </div>
        <div className='__dtmg'>
          <p className='__dat_e'>{date}</p>
          {/* {count > 0 ? (
            <Badge count={count} style={{ background: "#1890ff" }} />
          ) : null} */}
        </div>
      </div>
      {/* <Divider /> */}
    </>
  );
};

// System generated chat body

export const SysChatBody = ({
  company_name,
  product_id,
  photo,
  product_name,
  description,
  price,
  dealobj,
  getDealResults,
}) => {
  return (
    <div className='chat-body mt-2' style={{ overflow: 'auto' }}>
      <div
        className='chat-item justify-content-end'
        style={{ margin: '0px !important' }}
      >
        <div className='deal-snip with-img'>
          <Space size={25}>
            <Link to={'/product-detail/' + product_id}>
              <div className=''>
                <Image
                  src={photo || defaultImg}
                  preview={false}
                  alt='product'
                  width={80}
                  style={{
                    borderRadius: '5px',
                    border: '1px solid #eee',
                  }}
                />
              </div>
            </Link>
            <div className='ch-desc'>
              <Link to={'/product-detail/' + product_id}>
                <h6 className='text-capitalize'>{product_name}</h6>
              </Link>
              <p className='desc'>{description}</p>
              <p className='opacity-3 mb-0'>
                <strong>${price}.00</strong>
              </p>
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
};
