import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import comingImg from "../../../assets/images/cming-soon.png";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import { PrimaryBtn } from "../../../component/button/Button";
import  Layout  from "../Layouts/PageLayouts";

const { Title, Text } = Typography;

export default function ComingSoon() {
  return (
    <>
      <Layout style={{marginTop: "200px"}}>
        <div className="container large wallet-container">
          <div className="row flex flex-column justify-content-center align-items-center">
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-12 review-title-wrap mb-4">
                  <h1>
                    <Link to="/profile" className="back-ico">
                      <img src={next_arrow} alt="icon" />
                    </Link>
                    E-Wallet
                  </h1>
                </div>

                <div className="col-12 mt-5">
                  <div className="d-flex flex-column  text-center mt-5">
                    <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={comingImg}
                      className="img-fluid"
                      alt="coming-soon"
                    /></div>
                    <Text strong>This Page Is Under Construction.</Text>
                    <Title level={4}>
                      We'll be here soon with our E-Wallet System.
                    </Title>
                    <Link to="/profile" className="text-center mt-4">
                      <PrimaryBtn text="Go Back" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
