import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { connect } from "react-redux";

import arrow_small_ico from "./../../../assets/images/arrow-small-ico.svg";
import { DateFormatter } from "../../../component/generic";
import {
  logoutUser,
  verifyOtpActionSuccess,
} from "../../../store/actions/user";
import { openModal, closeModal } from "../../../store/actions/Modals";
import { userprofileApi } from "../../../store/apis";
import {
  DangerBtn,
  PrimaryBtn,
  SecBtn,
} from "../../../component/button/Button";
import Layout from "../Layouts/PageLayouts";

const Profile = (props) => {
  console.log("Profile props >>", props);
  const [userProfileData, setUserProfileData] = useState(null);

  const getUserProfile = async () => {
    try {
      let requestBody = {
        profile_id: `${props?.userData?.profile_id}`,
      };
      console.log("userprofileApi requestBody >>", requestBody);

      let res = await userprofileApi(requestBody);

      console.log("res >>", res);

      if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.status &&
        res.data.status === 200 &&
        res.data.data
      ) {
        setUserProfileData(res.data.data);
        console.log("User datatata", res.data.data)
        return res.data.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log("error >", error);
    }
  };

  useEffect(() => {
    if (!props.userAuth) {
      window.location.href = "/";
    } else {
      getUserProfile(props);
    }
  }, [props.userAuth]);

  useEffect(() => {
    console.log("useEffect userProfileData >>", userProfileData);
    if (userProfileData && userProfileData != null && userProfileData.name) {
      props.updateStoreWithUserData(userProfileData);
    }
  }, [userProfileData]);

  return (
    <Layout style={{ marginTop: "100px" }}>
      <div className="container large cart-container profile-container">
        <div className="row flex flex-column justify-content-center align-items-center">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-12 review-title-wrap mb-4">
                <div className="avatar-img">
                  {/* <img className="img-fluid" src={avatar} alt="icon" /> */}
                  <Avatar
                    name={props.user.userData && props.user.userData.name}
                    size="80"
                    round="60px"
                    textSizeRatio={1}
                  />
                </div>
                <SecBtn
                  text="Edit"
                  onClick={() => {
                    props.getModal(
                      "",
                      "Profile Details",
                      null,
                      null,
                      "user_profile",
                      props.getModal,
                      props.closeModals
                    );
                  }}
                />
              </div>
              <div className="col-12">
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Name</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {props.user.userData && props.user.userData.name
                      ? props.user.userData.name
                      : "N/A"}
                  </p>
                </div>
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Phone Number</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {props.user.userData &&
                    props.user.userData.dialing_code &&
                    props.user.userData.phonenumber
                      ? `+${props.user.userData.dialing_code}${props.user.userData.phonenumber}`
                      : "N/A"}
                  </p>
                </div>
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Email</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {props.user.userData ? props.user.userData.email : "N/A"}
                  </p>
                </div>
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Date of Birth</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {
                      <DateFormatter
                        date={
                          props.user.userData && props.user.userData.dob
                            ? props.user.userData.dob
                            : new Date()
                        }
                        pattern="dd/MM/yy"
                      />
                    }
                  </p>
                </div>
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Gender</strong>
                  </p>
                  <div className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    <label className="custom-checkbox radio mr-3 mb-0">
                      {props.user.userData && props.user.userData.gender && (
                        <input
                          disabled={
                            props.user.userData && props.user.userData.gender
                              ? props.user.userData.gender === "Male"
                                ? false
                                : true
                              : true
                          }
                          type="radio"
                          name="gender"
                          checked={props.user.userData.gender === "Male"}
                        />
                      )}{" "}
                      Male
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-checkbox radio mb-0 mr-3">
                      {props.user.userData && props.user.userData.gender && (
                        <input
                          disabled={
                            props.user.userData.gender
                              ? props.user.userData.gender === "Female"
                                ? false
                                : true
                              : true
                          }
                          type="radio"
                          name="gender"
                          checked={props.user.userData.gender === "Female"}
                        />
                      )}{" "}
                      Female
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-checkbox radio mb-0">
                      {props.user.userData && props.user.userData.gender && (
                        <input
                          disabled={
                            props.user.userData.gender
                              ? props.user.userData.gender === "Others"
                                ? false
                                : true
                              : true
                          }
                          type="radio"
                          name="gender"
                          checked={props.user.userData.gender === "Others"}
                        />
                      )}{" "}
                      Not comfortable to share
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Reward Balance</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {props.user.userData ? props.user.userData.wallet_money : "N/A"}
                  </p>
                </div>
                <div className="row opacity-4 mb-2">
                  <p className="col-md-6 mb-0">
                    <strong>Your Referral Code</strong>
                  </p>
                  <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                    {props.user.userData ? props.user.userData.referral_code : "N/A"}
                  </p>
                </div>
                {/* <div className="row opacity-4 mb-2">
                      <p className="col-md-6 mb-0">
                        <strong>Password</strong>
                      </p>
                      <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                        *********
                      </p>
                    </div> */}
                <hr className="my-4" />
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/order-list" className="mb-1 text-dark">
                      <strong>My Orders</strong>
                      {/* <small>Ongoing 1 order</small> */}
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/order-list" className="next mt-1">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/live" className="mb-1 text-dark">
                      <strong>My Live Deal</strong>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/live" className="next">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/cart" className="mb-1 text-dark">
                      <strong>My Cart</strong>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/cart" className="next">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/wallet" className="mb-1 text-dark">
                      <strong>E-Wallet</strong>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/wallet" className="next">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/myreviews" className="mb-1 text-dark">
                      <strong>My Reviews</strong>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/myreviews" className="next">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/chat" className="mb-1 text-dark">
                      <strong>Chat</strong>
                      <small>0 unread message</small>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/chat" className="next mt-1">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>

                {/* <div className="row mb-3">
                    <p className="col-6 mb-0">
                      <Link to="/notification" className="mb-1 text-dark">
                        <strong>Settings</strong>
                        <small>Notification, Password</small>
                      </Link>
                    </p>
                    <div className="col-6 text-right">
                      <Link to="/notification" className="next mt-1">
                  <div className="setting-opt">
                    <div className="row mb-3">
                      <p className="col-12 mb-0">
                        How do you want to know about latest new and updates
                      </p>
                    </div>
                    <div className="row mb-3">
                      <p className="col-6 mb-0 mt-1">Push Notification</p>
                      <div className="col-6 text-right">
                        <label className="switch mb-0">
                          <input type="checkbox" checked="checked" />
                          <span className="drag-ball">
                            <span className="off" />
                            <span className="on" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <p className="col-6 mb-0 mt-1">Email</p>
                      <div className="col-6 text-right">
                        <label className="switch mb-0">
                          <input type="checkbox" checked="checked" />
                          <span className="drag-ball">
                            <span className="off" />
                            <span className="on" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <p className="col-6 mb-0 mt-1">SMS</p>
                      <div className="col-6 text-right">
                        <label className="switch mb-0">
                          <input type="checkbox" />
                          <span className="drag-ball">
                            <span className="off" />
                            <span className="on" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <p className="col-6 mb-0 mt-1">Email</p>
                    <div className="col-6 text-right">
                      <label className="switch mb-0">
                        <input type="checkbox" checked="checked" />
                        <span className="drag-ball">
                          <span className="off" />
                          <span className="on" />
                        </span>
                      </label>
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    <p className="col-6 mb-0">
                      <Link to="/rate-us" className="mb-1 text-dark">
                        <strong>Rate Us</strong>
                        <small>Apps ver. 0.01.20</small>
                      </Link>
                    </p>
                    <div className="col-6 text-right">
                      <label className="switch mb-0">
                        <input type="checkbox" />
                        <span className="drag-ball">
                          <span className="off" />
                          <span className="on" />
                        </span>
                      </label>
                    </div>
                  </div> */}
                <div className="row mb-3">
                  <p className="col-6 mb-0">
                    <Link to="/support" className="mb-1 text-dark">
                      <strong>Support</strong>
                    </Link>
                  </p>
                  <div className="col-6 text-right">
                    <Link to="/support" className="next">
                      <img src={arrow_small_ico} alt="arrow" />
                    </Link>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-sm-6 order-2 order-sm-1 mt-3 mt-sm-0">
                    <DangerBtn
                      block
                      text="Log out"
                      onClick={() => {
                        props.logoutUser();
                      }}
                    />
                  </div>
                  <div className="col-sm-6 order-2 order-sm-1 mt-3 mt-sm-0">
                    <PrimaryBtn
                      block
                      text="Update"
                      onClick={() => {
                        props.getModal(
                          "",
                          "Profile Details",
                          null,
                          null,
                          "user_profile",
                          props.getModal,
                          props.closeModals
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  store: state,
  user: state.user,
  userData:
    state.user.userData && state.user.userData ? state.user.userData : [],
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status === 1
      ? true
      : false,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    updateStoreWithUserData: (...args) => {
      dispatch(verifyOtpActionSuccess(...args));
    },
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
