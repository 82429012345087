export const filterOrders = (orders, filterType) => {
  let filterFn;
  switch (filterType) {
    case "All":
      filterFn = (a) => true;
      break;

    case "To Receive":
      filterFn = (a) => a.status == 0;
      break;

    case "Completed":
      filterFn = (a) => a.status == 2 || a.status == 1 ;
      break;

    case "Rejected":
      filterFn = (a) => a.status == '-1';
      break;

    case "Cancelled":
      filterFn = (a) => a.status === '-2';
      break;

    default:
      throw new Error("Unsupported Filter Type");
  }
  return (orders ?? []).filter(filterFn);
  // return (orders ?? []).filter(() => true);
};

export const truncateNum = (num, min, max) => {
  if (num < min) return min;
  if (num > max) return max;
  return num;
};

export const getFromOffsetToLimit = (arr, offset, limit) => {
  return arr.slice(offset, offset + limit);
};
