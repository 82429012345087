import React from "react";
import Auth from "../../Auth";
import SignUpForm from "./form";


const SignUp = () => {
    return (
        <Auth
            title="Create an Account!"
            subTitle="Please fill in valid details"
        >
            <SignUpForm />
        </Auth>
    );
};

export default SignUp;
