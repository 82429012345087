import React, { Component } from "react";
import product_img1 from "./../../../assets/images/product-img1.png";
import avatar from "./../../../assets/images/avatar.png";
import star_yellow_ico from "./../../../assets/images/star-yellow-ico.svg";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import { Link } from "react-router-dom";
import Layout from "../Layouts/PageLayouts";
import SingleReview from "./ProductReview/SingleReview";
import api from "../../../apis";
import Notify from "cogo-toast";

class ProductReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            reviews: [],
            productID: "",
        };
    }

    componentDidMount() {
        const productID = window.location.href.split("product-review/")[1];
        api.productDetail(productID)
            .then((res) => {
                this.setState({ product: res });
                this.setState({ productID });
                api.getProductReview({ product_id: productID })
                    .then((res) => {
                        this.setState({ reviews: res.result });
                        console.log(res.result);
                    })
                    .catch((err) => {
                        Notify.error("Error fetching product reviews").then(
                            () => {
                                window.location.href =
                                    "/product-detail/" + productID;
                            }
                        );
                    });
            })
            .catch((err) => {
                Notify.error("Error fetching reviews").then(() => {
                    window.location.href = "/product-detail/" + productID;
                });
            });
    }

    formatDate = (num) => {
        return num < 10 ? "0" + num : num;
    };

    render() {
        return (
            <>
                <Layout style={{ marginTop: "100px" }}>
                    <div className="container large">
                        <div className="row">
                            <div className="col-12 review-title-wrap mb-4">
                                <Link
                                    to={
                                        "/product-detail/" +
                                        this.state.productID
                                    }
                                    className="back-ico"
                                >
                                    <img src={next_arrow} alt="icon" />
                                </Link>
                                <h1>{this.state.product.name}</h1>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-4 col-md-12">
                                <div className="rprod-snippet">
                                    <div className="prod-img">
                                        <img
                                            className="img-fluid"
                                            src={
                                                this.state.product.photo1 ||
                                                product_img1
                                            }
                                            alt="product"
                                        />
                                    </div>
                                    <div className="snippet-cont">
                                        <div className="d-flex">
                                            <h5>{this.state.product.name}</h5>
                                            <span className="price">
                                                ${this.state.product.price}
                                            </span>
                                        </div>
                                        <p>
                                            {this.state.product.description &&
                                                this.state.product.description.slice(
                                                    0,
                                                    50
                                                )}
                                            ...
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-2 col-md-6 rate-point-sec">
                <h4>5.0</h4>
                <div className="star-wrap">
                  <img src={star_yellow_ico} alt="ico" />
                  <img src={star_yellow_ico} alt="ico" />
                  <img src={star_yellow_ico} alt="ico" />
                  <img src={star_yellow_ico} alt="ico" />
                  <img src={star_yellow_ico} alt="ico" />
                </div>
                <p>Based on 15 reviews</p>
              </div> */}
                            {/* <div className="col-lg-6 col-md-6 rating-list">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-5">
                    <p>
                      1
                      <span className="star-grp">
                        <img src={star_yellow_ico} alt="ico" />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-7 col-7">
                    <span className="rating-bar">
                      <span style={{ width: "85%" }}></span>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-5">
                    <p>
                      2
                      <span className="star-grp">
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-7 col-7">
                    <span className="rating-bar">
                      <span style={{ width: "75%" }}></span>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-5">
                    <p>
                      3
                      <span className="star-grp">
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-7 col-7">
                    <span className="rating-bar">
                      <span style={{ width: "65%" }}></span>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-5">
                    <p>
                      4
                      <span className="star-grp">
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-7 col-7">
                    <span className="rating-bar">
                      <span style={{ width: "55%" }}></span>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-5">
                    <p>
                      5
                      <span className="star-grp">
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                        <img src={star_yellow_ico} alt="ico" />
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-7 col-7">
                    <span className="rating-bar">
                      <span style={{ width: "25%" }}></span>
                    </span>
                  </div>
                </div>
              </div> */}
                        </div>

                        <div className="row mt-5">
                            {this.state.reviews.reverse().map((review) => (
                                <SingleReview
                                    avatar={avatar}
                                    customer_name={review.name}
                                    star_icon={star_yellow_ico}
                                    date={
                                        this.formatDate(
                                            new Date(
                                                review.createddate
                                            ).getDate()
                                        ) +
                                        "/" +
                                        this.formatDate(
                                            new Date(
                                                review.createddate
                                            ).getMonth() + 1
                                        ) +
                                        "/" +
                                        this.formatDate(
                                            new Date(
                                                review.createddate
                                            ).getFullYear()
                                        )
                                    }
                                    comment={review.reviewdesc}
                                    rating={review.review_rating}
                                />
                            ))}
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default ProductReview;
