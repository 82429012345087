import cogoToast from 'cogo-toast';
let loader;
const notificationMiddleware = (store) => (next) => (action) => {
  if (action.meta && action.meta.notify) {
    if (action.meta.notify.status === 'Loading') {
      loader = cogoToast.loading(action.meta.notify.message || 'Loading', {
        hideAfter: 3,
      });
    }
    if (action.meta.notify.status === 'Success') {
      if (loader) {
        loader.hide();
      }

      cogoToast.success(action.meta.notify.message || 'Success');
    }
    if (action.meta.notify.status === 'Failed') {
      if (loader) {
        loader.hide();
      }
      cogoToast.error(action.meta.notify.message || 'Failed', {
        hideAfter: 6,
      });
    }
  }
  if (action) {
    return next(action);
  }

  // setTimeout(() => { dispatch({type: 'FETCHING_ITEMS'}); }, 0);
};
export default notificationMiddleware;
