import React  from "react";
import { Link } from "react-router-dom";
import checkmark_circle_ico from "./../../../assets/images/checkmark-circle-ico.svg";
import { PrimaryBtn } from "../../../component/button/Button";


const OrderPaid = (props) => {
  // const order_id = props.match.params.id;

  return (
    <>
      <section className="row main-section">
        <div className="container large">
          <div className="orderpaid-container">
            <img
              className="opacity-4 img-fluid"
              src={checkmark_circle_ico}
              alt="icon"
            />
            <h4>Order ID : {props.match.params.id}</h4>
            <h6>Order has been Paid</h6>
            <p className="mb-5 opacity-4 text-center">
              Your order has been paid. Your order will
              <br /> be processed soon by merchant
            </p>
            <Link to="/">
              <PrimaryBtn text="Back to Home" />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderPaid;
