import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { BsMinecartLoaded, BsTruck } from 'react-icons/bs'

import {
  toggleOpen,
  logoutUser,
  checkoutActionRequest,
  createOrderActionRequest,
  viewCartActionRequest,
} from '../../../../store/actions/user'
import { openModal, closeModal } from '../../../../store/actions/Modals'

import Paypal from '../../../../component/Paypal/Paypal'
///
import next_arrow from './../../../../assets/images/next-arrow.svg'
import pin_ico from './../../../../assets/images/pin-ico.svg'
import ewallet_ico from './../../../../assets/images/ewallet-ico.svg'
import card_ico from './../../../../assets/images/card-ico.svg'
import paypal_ico from './../../../../assets/images/paypal.svg'
import truck_ico from './../../../../assets/images/truck-ico.svg'

import Layout from '../../Layouts/PageLayouts'
import { PrimaryBtn } from '../../../../component/button/Button'
import SingleOrderProduct from './singleProduct'
import { InputField, InputLabel } from './Input'
import ShippingAddress, { BillingAddress, GeneralInformation } from './Address'
import { useAddressFormik } from './hooks'

const { Title } = Typography
const OrderSummary = (props) => {
  console.log("props.OrderSummary >>", props);
  const [checkout, setCheckOut] = useState(false)
  const [checkoutItems, setCheckoutItems] = useState([])
  const [amountValue, setAmountValue] = useState(0)
  const [orderDetailVal, setOrderDetailVal] = useState('')
  const shippingFormik = useAddressFormik()
  const billingFormik = useAddressFormik()
  const [isShipAndBillSameValues, setIsShipAndBillSameValues] = useState(false)

  useEffect(() => {
    if (!props.userAuth || !props.user) {
      window.location.href = "/";
    } else {
      let finalAmount = amountValue
      // + (0.07 * amountValue);
      let orderDetail = ''
      if (props.userCart && props.userCart.length > 0) {
        props.userCart.forEach((cartItem) => {
          finalAmount =
            finalAmount +
            (cartItem.product_price && cartItem.product_quantity
              ? cartItem.product_price * cartItem.product_quantity
              : 0)
          orderDetail = orderDetail.concat(
            `${cartItem.sno}_${cartItem.product_quantity}_${cartItem.product_price},`,
          )
        })

        let items = props.userCart.map((cartItem) => {
          let finalItem = {
            name: cartItem.product_name,
            price: cartItem.product_price,
            currency: 'USD',
            quantity: cartItem.product_quantity,
            photo: cartItem.product_photo,
          }
          return finalItem
        })
        console.log('finalAmount >>', finalAmount)
        setAmountValue(finalAmount)
        setCheckoutItems(items)
        setOrderDetailVal(orderDetail)
      } else {
        setCheckOut(false)
      }
    }
  }, [props.userAuth])

  useEffect(() => {
      if (isShipAndBillSameValues && billingFormik.values !== shippingFormik.values) shippingFormik.setValues(billingFormik.values)  

  }, [isShipAndBillSameValues, shippingFormik.values, billingFormik])


  return (
    <>
      <Layout
        styl={{
          background: '#f7fcff',
          paddingBottom: '150px !important',
        }}
      >
        
       
        <div className="container large pb-5 mb-5 summary-container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-12 review-title-wrap mb-5">
                  <Link to="/cart" className="back-ico">
                    <img src={next_arrow} alt="icon" />
                  </Link>
                  <h1>Order Summary</h1>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <Title
                      level={4}
                      className="text-center text-md-left border-bottom pb-3"
                    >
                      General Information
                    </Title>
                  </div>
                  <GeneralInformation userData={props.user}/>
                </div>
                <div className="col-12 my-md-4">
                  <div className="row">
                    {/* Shipping address */}
                    <div className="col-md-6 order-2">
                      <div className="mb-3">
                        <Title
                          level={4}
                          className="text-center text-md-left border-bottom pb-3"
                        >
                          Shipping Address
                        </Title>
                      </div>
                      <ShippingAddress
                        formik={shippingFormik}
                        isChecked={isShipAndBillSameValues}
                        onToggleChecked={() =>
                          setIsShipAndBillSameValues((s) => !s)
                        }
                        // handleChange={handleShipInputChange}
                        // billingFormik={billingFormik}
                      />
                    </div>
                    {/* Billing Address */}
                    <div className="col-md-6 mt-5 mt-md-0 order-1">
                      <div className="mb-3">
                        <Title
                          level={4}
                          className="text-center text-md-left border-bottom pb-3"
                        >
                          Billing Address
                        </Title>
                      </div>
                      <BillingAddress
                        formik={billingFormik}
                        shippingFormik={shippingFormik}
                        isSameWithShipping={
                          isShipAndBillSameValues
                          //   false
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="payment-method">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Title
                          level={4}
                          className="text-center text-md-left border-bottom pb-3"
                        >
                          Payment Method
                        </Title>
                      </div>
                      <div className="col-md-6">
                        <div className="pm-snip mb-3 mb-md-0">
                          <input checked type="radio" name="payment-method" />
                          <div className="snip-cont py-1">
                            <img
                              className="img-fluid mr-2"
                              src={paypal_ico}
                              alt="ico"
                            />
                            {/* <h6>
                              PayPal
                              <small>Pay with Paypal</small>
                            </h6> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="pm-snip">
                          <input disabled type="radio" name="payment-method" />
                          <div className="snip-cont" style={{opacity: .3}}>
                            <img
                              className="img-fluid mr-2"
                              src={ewallet_ico}
                              alt="ico"
                            />
                            <Title level={4} className="mb-0 pb-0">
                              E-Wallet {/* <small>Pay with E-Wallet</small> */}
                            </Title>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="pm-snip">
                          <input disabled type="radio" name="payment-method" />
                          <div className="snip-cont" style={{opacity: .3}}>
                            <img
                              className="img-fluid mr-2"
                              src={card_ico}
                              alt="ico"
                            />
                            <Title level={4} className="mb-0 pb-0">
                              Stripe Payment
                            </Title>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <Title
                level={4}
                className="text-center font-weight-bold text-md-left mb-md-3"
              >
                Order Information
              </Title>
              <div className="order-lft-snip pt-0 bg-white border rounded">
                <div className="row">
                  <div className="rounded px-0 mx-0 col-12">
                    {props.userCart.map((item) => (
                      <SingleOrderProduct item={item} />
                    ))}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <p className="mb-0">Sub Total</p>
                    <p className="mb-0 font-weight-bold">
                      {`$${amountValue.toFixed(2)}`}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <p>GST</p>
                    <p className="font-weight-bold">7% (inclusive)</p>
                  </div>
                </div>
                <form className="col-12 px-0 mx-0 mb-3">
                  <div className="d-flex">
                    <InputField name="promo" placeholder="Apply Promo Code" />
                    <button type="submit" className="__btn_promo">
                      APPLY
                    </button>
                  </div>
                </form>
                <div className="row mt-4 mx-0">
                  <div className="col-12 px-0 border py-3">
                    <div className="d-flex align-items-center justify-content-between px-3">
                      <p className=" mb-0">
                        <strong>Total</strong>
                      </p>
                      <p className=" text-right mb-0">
                        <strong> {`$${amountValue.toFixed(2)}`}</strong>
                      </p>
                    </div>
                  </div>
                  <p className="col-12 p-terms mt-4 mb-0">
                    By placing this order, you agree to
                    <br />
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </p>
                  <div className="col-12 mt-4">
                    {/* <Link to="order-paid"> */}
                    {checkout ? (
                      <Paypal
                        orderDetailVal={orderDetailVal}
                        user={props.user}
                        createOrder={props.createOrder}
                        amountValue={amountValue}
                        items={checkoutItems}
                      />
                    ) : (
                      <PrimaryBtn
                        block
                        text="COMPLETE PURCHASE"
                        onClick={() => {
                          setCheckOut(!checkout)
                        }}
                      />
                    )}
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
  userCart: state.user.userCart,
  orderAmount: state.user.orderAmount,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args))
    },
    closeModals: () => {
      dispatch(closeModal())
    },
    loadUserCart: (...args) => {
      dispatch(viewCartActionRequest(...args))
    },
    checkoutConfirm: (...args) => {
      dispatch(checkoutActionRequest(...args))
    },
    createOrder: (...args) => {
      dispatch(createOrderActionRequest(...args))
    },
    toggleOpen: (status) => dispatch(toggleOpen(status)),
    logoutUser: () => dispatch(logoutUser()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary)
