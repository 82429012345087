import { TOGGLE_MODE_APP } from "../Types"

const INITIAL_STATE = {
  mode: "light"
};

 const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MODE_APP: {
      return Object.assign({}, state, {mode: state.mode === "light" ? "dark" : "light"});
    }
    default: {
      return state;
    }
  }
}

export default appReducer