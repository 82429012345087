import React from "react";
import { Layout } from "antd";
import AppLogo from "../../assets/images/hdr-logo.svg";
import styled from "styled-components";
import { LeftSideBarMenu } from "../sidebarMenus/index";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  background: #007bff !important;
  min-height: 100vh;
		height: 100%;		
  position: sticky;
  top: 0;
  .ant-layout-sider-children ul {
    background-color: transparent !important;
  }
`;

const LeftSideMenuWrapper = (props) => {
  let imageVisible;
  props.state.collapsed
    ? (imageVisible = "hidden")
    : (imageVisible = "visible");

  return (
    <div>
      <StyledSider
        trigger={null}
        collapsible
        collapsed={props.state.collapsed}
        collapsedWidth={80}
        width="256px"								
      >
        <div>
          <img
            src={AppLogo}
            alt="eamr"
            style={{
              position: "relative",
              margin: "0 30px",
              padding: "20px",
              width: "70%",
              visibility: imageVisible,
              userSelect: "none",
              pointerEvents: "none",
            }}
          />
        </div>

        <div>
          <LeftSideBarMenu collapsed={props.state.collapsed} />
        </div>
      </StyledSider>
    </div>
  );
};

export default LeftSideMenuWrapper;
