import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Typography, Rate } from "antd";
import product_img1 from "./../../../assets/images/product-img1.png";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import Layout from "../Layouts/PageLayouts";
import { PrimaryBtn } from "../../../component/button/Button";
import api from "../../../apis";
import jsCookie from "js-cookie";
import Notify from "cogo-toast";

const { Title } = Typography;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const rates = ["1.0", "2.0", "3.0", "4.0", "5.0"];
class AddReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            inputText: "",
            value: 0,
            productID: "",
        };
    }

    componentDidMount() {
        let userstring = jsCookie.get("userinfo");
        const productID = window.location.href.split("add-review/")[1];
        if (!userstring || !JSON.parse(userstring)) {
            return (window.location.href = "/product-detail/" + productID);
        }
        api.productDetail(productID)
            .then((res) => {
                this.setState({ product: res });
                this.setState({ productID });
            })
            .catch((err) => {
                console.log(err);
                return (window.location.href = "/product-detail/" + productID);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let userstring = jsCookie.get("userinfo");
        const user = JSON.parse(userstring);
        const productID = window.location.href.split("add-review/")[1];
        const data = {
            profile_id: user.sno,
            product_id: productID,
            reviewdesc: this.state.inputText,
            review_rating: this.state.value,
        };
        api.addProductReview(data)
            .then((res) => {
                if (res.status === 200) {
                    Notify.success("Review added successfully!");
                    setTimeout(() => {
                        window.location.href = "/product-detail/" + productID;
                    }, 1000);
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleChange = (value) => {
        this.setState({ value });
    };

    render() {
        const { value } = this.state;
        return (
            <Layout style={{ marginTop: "200px" }}>
                <div className="container large write-review">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-12 review-title-wrap mb-4">
                                    <h1>
                                        <Link
                                            to={
                                                "/product-detail/" +
                                                this.state.productID
                                            }
                                            className="back-ico"
                                        >
                                            <img src={next_arrow} alt="icon" />
                                        </Link>
                                        Write Review
                                    </h1>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="rprod-snippet">
                                                <div className="prod-img">
                                                    <img
                                                        className="img-fluid"
                                                        src={
                                                            this.state.product
                                                                ? this.state
                                                                      .product
                                                                      .photo1
                                                                : product_img1
                                                        }
                                                        alt="product"
                                                    />
                                                </div>
                                                <div className="snippet-cont">
                                                    <div className="d-flex">
                                                        <h5>
                                                            {
                                                                this.state
                                                                    .product
                                                                    .name
                                                            }
                                                        </h5>
                                                        <span className="price">
                                                            $
                                                            {
                                                                this.state
                                                                    .product
                                                                    .price
                                                            }
                                                        </span>
                                                    </div>
                                                    <p>
                                                        {this.state.product &&
                                                            this.state.product
                                                                .description &&
                                                            this.state.product.description.slice(
                                                                0,
                                                                50
                                                            )}
                                                        ...
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 rate-point-sec">
                                            <Title level={4}>
                                                Insert your rating here
                                            </Title>
                                            <div className="d-flex flex-column">
                                                <Rate
                                                    tooltips={desc}
                                                    onChange={this.handleChange}
                                                    value={value}
                                                    // allowHalf
                                                />
                                                {value ? (
                                                    <h5
                                                        strong
                                                        className="ant-rat mt-3"
                                                    >
                                                        {rates[value - 1]}
                                                    </h5>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form">
                                        <div className="form-group">
                                            <label>
                                                Write Your Review Here
                                            </label>
                                            <textarea
                                                placeholder="Describe your experience"
                                                className="form-control"
                                                value={this.state.inputText}
                                                onChange={(e) => {
                                                    this.setState({
                                                        inputText:
                                                            e.target.value,
                                                    });
                                                }}
                                            ></textarea>
                                        </div>
                                        {/* <div className="form-group">
                      <label>Upload Photo</label>
                      <input className="upload-btn" type="file" name="" />
                    </div> */}
                                        <div className="form-group text-center mt-5">
                                            {/* <Link to="/view-order"> */}
                                            <PrimaryBtn
                                                block
                                                text="Submit Review"
                                                onClick={this.handleSubmit}
                                            />
                                            ;{/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default AddReview;
