import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Upload, InputNumber } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import "./shell.css";

const { TextArea } = Input;

const StyledContent = styled.section`
  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
  }

  .form-label {
    font-weight: 600;
  }
  input,
  textarea {
    padding: 10px;
    border-radius: 4px;
  }
  .upload {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border: 2px dashed #a4a4a4;
    border-radius: 4px;
    cursor: pointer;
  }
  .submit-button {
    width: 100%;
    color: #fff;
    background-color: #275dad;
    border: none;
    padding: 12px 32px;
    font-weight: 600;
    border-radius: 8px;
  }
`;

const AddProduct = (props) => {
  return (
    <StyledContent>
      <div className="heading">
        <Link to={`/dashboard`}>
          <ArrowLeftOutlined style={{ marginRight: 48, paddingBottom:10 }} />
        </Link>
        <div>Add Product</div>
      </div>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <label className="form-label">Product Name</label>
          <Input />
        </Col>

        <Col span={12}>
          <label className="form-label">Price</label>
          <Input type="number" />
        </Col>

        <Col span={12}>
          <label className="form-label">Dimension</label>
          <Input />
        </Col>

        <Col span={12}>
          <label className="form-label">Stock</label>
          <Input />
        </Col>

        <Col span={12}>
          <label className="form-label">Delivery Type</label>
          <Input />
        </Col>

        <Col span={24}>
          <label className="form-label">Description</label>
          <TextArea rows={4} autoSize={{ maxRows: 8, minRows: 5 }} />
        </Col>

        <Col span={24}>
          <label className="form-label">Description</label>
          <label for="upload" className="upload">
            <Upload id="upload">+ Upload</Upload>
          </label>
        </Col>

        <Col offset={3} span={8}>
          <button className="submit-button">Create</button>
          <br />
          <br />
        </Col>
      </Row>
    </StyledContent>
  );
};

export default AddProduct;
