import React, { useEffect, useRef } from "react";
import jsCookie from "js-cookie";
import Api from "../../apis";

const Paypal = () => {
  const paypal = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Your description",
                amount: {
                  currency_code: "USD",
                  value: 50.0,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          let requestBody = {
            profile_id: jsCookie.get("userinfo")
              ? JSON.parse(jsCookie.get("userinfo")).profile_id
              : "peterjoshua828@gmail.com",
            // JSON.parse(jsCookie.get("userinfo")).sno
          };

          const order = await actions.order.capture();

          const payerDetails = order?.purchase_units[0];

          let orderRequest = {
            profile_id: `${requestBody.profile_id}` || "N/A",
            payment_method: "paypal",
            order_id: `${order?.id || "N/A"}`,
            paypal_order_id: `${order?.id || "N/A"}`,
            paypal_payer_id: `${order?.payer?.payer_id} || "N/A"}`,
            order_paypal_status: `${order?.status || "COMPLETED"}`,
            shipping_address: `${payerDetails?.shipping?.address?.address_line_1}`,
            shipping_recipient: `${payerDetails?.shipping?.name?.full_name}`,
            total_amount: `${payerDetails?.amount?.value}`,
            products_details: `${payerDetails?.description}`,
            create_time: `${order?.create_time}`,
            update_time: `${order?.update_time}`,
            delivery_status: "1",
          };
          //   setPaid(true);
          console.log("Orderrr", order);
          console.log("Datatatata", data);
          console.log("Actions", actions);
          await Api.addProductOrders(orderRequest)
            .then((data) => {
              console.log(data);
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onError: (err) => {
          //   setError(err),
          console.error("Wahala>>>", err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
};

export default Paypal;
