import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import HeaderWrapper from "../../component/header/index";
import { LeftSideMenuWrapper } from "../../component/sidebars/index";
import ContentWrapper from "../../component/maincontent/index";
import FooterWrapper from "../../component/footer/index";
import "./shell.css";
//------------------//
//     All Merchant Views    //
//------------------//

import ErrorModal from "../../component/modals/errorModal";
import ProductListing from "./ProductListing";
import AddProduct from "./AddProduct";
import OrderList from "./OrderList";
import Chat from "./Chat";
import Profile from "./Profile";

const Shell = (props) => {
  const [state, setState] = useState({ collapsed: false });
  const [queryError, setQueryError] = useState({
    occur: false,
    error: "",
  });

  //-----------------------------//
  //   Toggle for Sidebar ||   //
  //------------------------\/---//

  const toggleCollapsed = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  return (
    <div className="App">
      <ErrorModal
        title="Error"
        visible={queryError.occur}
        onCancel={setQueryError}
        errorMessage={queryError.error}
      />
      <Layout>
        <LeftSideMenuWrapper state={state} />

        <Layout>
          <HeaderWrapper
            state={state}
            toggleCollapsed={toggleCollapsed}
            dispatch={props.dispatch}
          />

          <ContentWrapper>
            <Switch>
              <Route exact path="/dashboard/orders-list" component={OrderList} />
              <Route exact path="/dashboard/add-product" component={AddProduct} /> 
														<Route exact path="/dashboard/chat" component={Chat} />
														<Route exact path="/dashboard/profile" component={Profile} /> 
              <Route exact path="/dashboard" component={ProductListing} />            
              {/* <Route exact path="/dashboard" component={AddProduct} />             */}
            </Switch>
          </ContentWrapper>

          <FooterWrapper />
        </Layout>
      </Layout>
    </div>
  );
};

export default Shell;
