import jsCookie from 'js-cookie';
import { put, takeLatest } from 'redux-saga/effects';
// import cogoToast from "cogo-toast";
import Notify from 'cogo-toast';
import {
  ADD_CART_REQUEST,
  ADD_DEAL_REQUEST,
  ADD_WISHLIST_REQUEST,
  CHECKOUT_REQUEST,
  CREATE_ORDER_REQUEST,
  DECREMENT_CART_REQUEST,
  DELETE_CART_ITEM_REQUEST,
  DELETE_WISHLIST_REQUEST,
  INCREMENT_CART_REQUEST,
  LOGIN_REQUEST,
  ORDER_DETAIL_REQUEST,
  REGISTER_REQUEST,
  SEND_OTP_REQUEST,
  USER_ORDERS_REQUEST,
  USER_UPDATE_REQUEST,
  VERIFY_OTP_REQUEST,
  VIEW_CART_REQUEST,
  VIEW_WISHLIST_REQUEST,
} from '../Types';
import {
  addCartActionFailed,
  addCartActionSuccess,
  addDealActionFailed,
  addDealActionRequest,
  addWishlistActionFailed,
  addWishlistActionSuccess,
  checkoutActionFailed,
  checkoutActionSuccess,
  createOrderActionFailed,
  createOrderActionSuccess,
  decrementCartActionFailed,
  decrementCartActionSuccess,
  deleteCartItemActionFailed,
  deleteCartItemActionSuccess,
  deleteWishlistActionFailed,
  deleteWishlistActionSuccess,
  incrementCartActionFailed,
  incrementCartActionSuccess,
  loginActionFailed,
  loginActionSuccess,
  registerActionFailed,
  registerActionSuccess,
  sendOtpActionFailed,
  sendOtpActionSuccess,
  userOrdersActionFailed,
  userOrdersActionSuccess,
  userUpdateActionFailed,
  userUpdateActionSuccess,
  verifyOtpActionSuccess,
  viewCartActionFailed,
  viewCartActionSuccess,
  viewWishlistActionFailed,
  viewWishlistActionSuccess,
} from '../actions/user';

import {
  addCartApi,
  addDealApi,
  addWishlistApi,
  checkoutApi,
  createOrderApi,
  decrementInCartApi,
  deleteCartItemApi,
  deleteWishlistApi,
  incrementInCartApi,
  loginApi,
  orderDetailsApi,
  registerApi,
  sendOtpApi,
  updateprofileApi,
  verifyOtpApi,
  viewCartApi,
  viewOrdersApi,
  viewWishlistApi,
} from '../apis/index';

// const token = getImTokenFromBrowserCookie();
//Api Calls

// Generators

function* registerUser(action) {
  console.log('registerUser generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield registerApi(action.body);
    console.log('Response registerApi >>', res);
    console.log('Response registerApi res.data>>', res.data);

    if (res.data && res.data && res.data.status && res.data.status === 200) {
      yield put(registerActionSuccess(res.data));
      loader.hide();
      Notify.success('Registered Successfully');
      if (action.cb) {
        console.log('callback registerUser works!');
        action.cb(res);
      }
    } else if (
      res.data &&
      res.data &&
      typeof res.data.status != 'undefined' &&
      res.data.status === 400
    ) {
      loader.hide();
      let message = res.data.message ? res.data.message : '';
      Notify.error(`${message}`);
      yield put(registerActionFailed(message));
    } else {
      loader.hide();
      Notify.error(`Please try again later!`);
      yield put(registerActionFailed(`Please try again later!`));
    }
  } catch (error) {
    loader.hide();
    Notify.error(
      error?.response?.data?.error ?? error?.message ?? 'Error signing up'
    );
    yield put(registerActionFailed(error.response.data.error));
  }
}

function* LoginUser(action) {
  console.log('LoginUser generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield loginApi(action.body);
    console.log('Response loginApi >>', res);
    yield put(loginActionSuccess(res.data));
    loader.hide();
    Notify.success('Logged In Successfully');
    if (action.cb) {
      console.log('callback LoginUser works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(loginActionFailed(error.response.data.error));
  }
}

function* sendOtp(action) {
  console.log('LoginUser generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield sendOtpApi(action.body);
    console.log('Response loginApi >>', res);
    if (res && res.status && res.data && res.data.status === 200) {
      yield put(sendOtpActionSuccess(res.data));
      loader.hide();
      Notify.success('OTP sent Successfully');
      if (action.cb) {
        console.log('callback LoginUser works!');
        action.cb(res);
      }
    } else {
      loader.hide();
      Notify.error(res.data.message);
      yield put(sendOtpActionFailed(res.data.message));
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(sendOtpActionFailed(error.response.data.error));
  }
}

function* verifyOtp(action) {
  console.log('verifyOtp generator action ==>> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield verifyOtpApi(action.body);
    console.log('Response verifyOtpApi ===>>', res.data);
    if (res.data.status !== 400) {
      yield put(
        verifyOtpActionSuccess(
          res && res.data && res.data.data && res.data.data[0]
            ? res.data.data[0]
            : {}
        )
      );
      loader.hide();
      Notify.success('OTP verification Successfully');
      const userdata = {
        sno: res.data.data[0].sno,
        profile_id: res.data.data[0].profile_id,
        phonenumber: res.data.data[0].phonenumber,
        dialing_code: res.data.data[0].dialing_code,
      };
      jsCookie.set('userinfo', userdata);
      if (action.cb) {
        console.log('callback verifyOtp works!');
        action.cb(res);
      }
    } else {
      Notify.error(res.data.message);
      loader.hide();
    }
    // 	  console.log("response data(arib)==", res.data);
    //   if (res.data.status !== 400) {
    // } else {
    // }
  } catch (error) {
    console.log('Wahala');
    loader.hide();
    console.log(error);
    // Notify.error(error.response.data.error);
    // yield put(verifyOtpActionFailed(error.response.data.error));
  }
}

function* viewCart(action) {
  console.log('viewCartApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield viewCartApi(action.body);
    console.log('Response viewCartApi >>', res);
    yield put(
      viewCartActionSuccess(
        res && res.data && res.data.data && res.data.data[0]
          ? res.data.data
          : []
      )
    );
    loader.hide();
    Notify.success('Your Cart is Available Now.');
    if (action.cb) {
      console.log('callback viewCartApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(viewCartActionFailed(error.response.data.error));
  }
}
function* addCart(action) {
  console.log('addCartApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield addCartApi(action.body);
    console.log('Response addCartApi >>', res);
    yield put(
      addCartActionSuccess(
        res && res.data && res.data.data ? res.data.data : {}
      )
    );
    loader.hide();
    Notify.success('Added to Cart.');
    if (action.cb) {
      console.log('callback addCartApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(addCartActionFailed(error.response.data.error));
  }
}
function* incrementCartItem(action) {
  console.log('incrementCartItem generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield incrementInCartApi(action.body);
    console.log('Response incrementInCartApi >>', res);
    yield put(incrementCartActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Another Product added to Cart');
    if (action.cb) {
      console.log('callback incrementInCartApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(incrementCartActionFailed(error.response.data.error));
  }
}
function* decrementCartItem(action) {
  console.log('decrementCartItem generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield decrementInCartApi(action.body);
    console.log('Response decrementInCartApi >>', res);
    yield put(decrementCartActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Product removed from Cart');
    if (action.cb) {
      console.log('callback decrementInCartApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(decrementCartActionFailed(error.response.data.error));
  }
}
function* deleteCartItem(action) {
  console.log('deleteCartItem generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield deleteCartItemApi(action.body);
    console.log('Response deleteCartItemApi >>', res);
    yield put(deleteCartItemActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Product Deleted from Cart');
    if (action.cb) {
      console.log('callback deleteCartItemApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(deleteCartItemActionFailed(error.response.data.error));
  }
}

function* checkoutCart(action) {
  console.log('checkoutApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield checkoutApi(action.body);
    console.log('Response checkoutApi >>', res);
    yield put(checkoutActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Your order has been confirmed.');
    if (action.cb) {
      console.log('callback checkoutApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(checkoutActionFailed(error.response.data.error));
  }
}

function* createOrder(action) {
  console.log('createOrder generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield createOrderApi(action.body);
    console.log('Response checkoutApi >>', res);
    yield put(createOrderActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Your order has been confirmed.');
    if (action.cb) {
      console.log('callback createOrderApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(createOrderActionFailed(error.response.data.error));
  }
}
function* userOrders(action) {
  console.log('userOrders generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield viewOrdersApi(action.body);
    console.log('Response checkoutApi >>', res);
    yield put(
      userOrdersActionSuccess(
        res && res.data && res.data.data && res.data.data[0]
          ? res.data.data
          : []
      )
    );
    loader.hide();
    Notify.success('Your orders.');
    if (action.cb) {
      console.log('callback viewOrdersApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(userOrdersActionFailed(error.response.data.error));
  }
}
function* orderDetails(action) {
  console.log('orderDetails generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield orderDetailsApi(action.body);
    console.log('Response checkoutApi >>', res);
    yield put(userOrdersActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Order Details.');
    if (action.cb) {
      console.log('callback viewOrdersApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(userOrdersActionFailed(error.response.data.error));
  }
}
function* updateUserProfile(action) {
  console.log('updateUserProfile generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield updateprofileApi(action.body);
    console.log('Response updateprofileApi >>', res);
    yield put(
      userUpdateActionSuccess(
        res && res.data && res.data.data ? res.data.data : []
      )
    );
    loader.hide();
    Notify.success('Your Profile is updated.');
    if (action.cb) {
      console.log('callback updateprofileApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(userUpdateActionFailed(error.response.data.error));
  }
}
// Wishlist Generators
function* viewWishlist(action) {
  console.log('viewWishlistApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield viewWishlistApi(action.body);
    console.log('Response viewWishlistApi >>', res);
    yield put(
      viewWishlistActionSuccess(
        res && res.data && res.data.data && res.data.data[0]
          ? res.data.data
          : []
      )
    );
    loader.hide();
    Notify.success('Your Wishlist is Available Now.');
    if (action.cb) {
      console.log('callback viewCartApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(viewWishlistActionFailed(error.response.data.error));
  }
}
function* addWishlist(action) {
  console.log('addWishlistApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield addWishlistApi(action.body);
    console.log('Response addWishlistApi >>', res);
    yield put(
      addWishlistActionSuccess(
        res && res.data && res.data.data ? res.data.data : {}
      )
    );
    loader.hide();
    Notify.success('Added to Wishlist.');
    if (action.cb) {
      console.log('callback addWishlistApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(addWishlistActionFailed(error.response.data.error));
  }
}
function* deleteWishlistItem(action) {
  console.log('deleteWishlistApi generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield deleteWishlistApi(action.body);
    console.log('Response deleteWishlistApi >>', res);
    yield put(deleteWishlistActionSuccess(res && res.data ? res.data : []));
    loader.hide();
    Notify.success('Product Deleted from Wishlist');
    if (action.cb) {
      console.log('callback deleteWishlistApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(deleteWishlistActionFailed(error.response.data.error));
  }
}

function* addLiveDeal(action) {
  console.log('addLiveDeal generator action >> ', action);
  const loader = Notify.loading('Please wait!', {
    hideAfter: 3,
  });
  let res;
  try {
    res = yield addDealApi(action.body);
    console.log('Response addDeal >>', res);
    yield put(
      addDealActionRequest(
        res && res.data && res.data.data ? res.data.data : {}
      )
    );
    loader.hide();
    Notify.success('Added to Libe Deals.');
    if (action.cb) {
      console.log('callback addLiveDealApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(addDealActionFailed(error.response.data.error));
  }
}

function* watchUser() {
  yield takeLatest(REGISTER_REQUEST, registerUser);
  yield takeLatest(LOGIN_REQUEST, LoginUser);
  yield takeLatest(SEND_OTP_REQUEST, sendOtp);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtp);
  yield takeLatest(VIEW_CART_REQUEST, viewCart);
  yield takeLatest(ADD_CART_REQUEST, addCart);
  yield takeLatest(INCREMENT_CART_REQUEST, incrementCartItem);
  yield takeLatest(DECREMENT_CART_REQUEST, decrementCartItem);
  yield takeLatest(DELETE_CART_ITEM_REQUEST, deleteCartItem);
  yield takeLatest(CHECKOUT_REQUEST, checkoutCart);
  yield takeLatest(CREATE_ORDER_REQUEST, createOrder);
  yield takeLatest(USER_ORDERS_REQUEST, userOrders);
  yield takeLatest(ORDER_DETAIL_REQUEST, orderDetails);
  yield takeLatest(USER_UPDATE_REQUEST, updateUserProfile);
  yield takeLatest(VIEW_WISHLIST_REQUEST, viewWishlist);
  yield takeLatest(ADD_WISHLIST_REQUEST, addWishlist);
  yield takeLatest(DELETE_WISHLIST_REQUEST, deleteWishlistItem);
  yield takeLatest(ADD_DEAL_REQUEST, addLiveDeal);
}

export default watchUser;
