// configureStore.js
import { createStore, applyMiddleware } from "redux";
// import { logger } from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import rootSagas from "./sagas";
import notificationMiddleware from "../middlewares/notificationMiddleware";

const saga = createSagaMiddleware();
const middleware = [thunk, saga, notificationMiddleware];
// const middleware = [thunk, saga, notificationMiddleware, logger];

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer); 

export const store = createStore(
	persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);
saga.run(rootSagas);

 
