import { Typography, Space, Progress } from "antd";
import { StarIcon } from "../../../constants/Star";

// Review numbers
const { Text } = Typography;

export const ReviewNumbers = () => (
  <div className="d-none d-sm-block">
    <Space direction="vertical" size={0}>
      <Space align="center">
        <Text strong>1</Text>
        <StarIcon fill="#FDCC0D" width={13} height={13} />
      </Space>
      <Space align="center">
        <Text strong>2</Text>
        {[...Array(2)].map(() => (
          <StarIcon fill="#FDCC0D" width={13} height={13} />
        ))}
      </Space>
      <Space align="center">
        <Text strong>3</Text>
        {[...Array(3)].map(() => (
          <StarIcon fill="#FDCC0D" width={13} height={13} />
        ))}
      </Space>
      <Space align="center">
        <Text strong>4</Text>
        {[...Array(4)].map(() => (
          <StarIcon fill="#FDCC0D" width={13} height={13} />
        ))}
      </Space>
      <Space align="center">
        <Text strong>5</Text>
        {[...Array(5)].map(() => (
          <StarIcon fill="#FDCC0D" width={13} height={13} />
        ))}
      </Space>
    </Space>
  </div>
);


// Review Progress

export default function ReviewProgress({ rate }) {
  const ratings = rate || [10, 20, 30, 40, 50];
  return (
    <div>
      {ratings.map((rating, ratingIndex) => (
        <Progress
          percent={rating}
          // showInfo={false}
          key={ratingIndex}
          strokeColor="#FDCC0D"
        />
      ))}
    </div>
  );
}
