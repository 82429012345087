import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PhoneNumberInput from "./phone-input";
import { usePhone } from "./hooks";

const LoginForm = () => {
    const [
        { phone, error, setPhone },
        { onInputChangeAction, onSubmitAction },
    ] = usePhone();

    return (
        <Form
            noValidate
            className="my-4"
            onSubmit={(e) => {
                e.preventDefault();
                onSubmitAction();
            }}
        >
            <div
                className={`m-0 mb-1 phone_input mx-auto  __login-phone-input`}
            >
                <PhoneNumberInput
                    value={phone}
                    onValueChange={(newPhoneValue, country) =>{
                        onInputChangeAction(newPhoneValue, country)
                        setPhone(newPhoneValue)}
                    }
                />
                {error ? (
                    <span className="form-error-span">{error}</span>
                ) : null}
            </div>

            <button
                type="submit"
                className="__auth_btn __login_btn mx-auto"
                disabled={error != null}
            >
                Log In
            </button>

            <div className="text-center pb-3 mt-3 mb-0">
                <span> Don't have an account? </span>
                <Link className="font-weight-bold" to="/signup">
                    Sign Up
                </Link>
            </div>
        </Form>
    );
};

export default LoginForm;
