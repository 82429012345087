import React from "react";
import { connect } from "react-redux";
//import { Modal } from 'antd';
import BasicModal from "./Basic.js";
// import LoginModal from "../../container/Modal/Login";
import RegisterModal from "./RegisterModal";
import sendOtpModal from "./sendOtpModal";
import verifyOtpModal from "./verifyLoginModal";
import userProfileModal from "./userProfileModal";
import { closeModal } from "../../store/actions/Modals";
// import styled from "styled-components"

const RootModal = (props) => {
    let modalComponent = {
        basic_modal: BasicModal,
        send_otp_modal: sendOtpModal,
        verify_otp_modal: verifyOtpModal,
        register_modal: RegisterModal,
        user_profile: userProfileModal,
    };

    // console.log("Root Modal props >>", props);
    //  console.log("Main Root Modal props", props)
    // if (props.modals && props.modals.length > 0) {
    //   return null;
    // } else {
    const ModalsToRender =
        props.modals &&
        props.modals.map((val) => {
            let ModalToRender = modalComponent[val.modalType];
            return (
                <ModalToRender
                    key={val.modalProps.title || "random-09876566778"}
                    className={props.className}
                    {...val.modalProps}
                />
            );
        });
    return (
        <div>
            {ModalsToRender &&
                ModalsToRender.length > 0 &&
                ModalsToRender.map((val) => val)}
        </div>
    );
    // }
};

const mapStateToProps = (state) => ({
    modals: state.Modal.modals,
});
const mapDispatchToProps = (dispatch) => {
    return {
        exitModal: () => {
            dispatch(closeModal());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
