import React from "react";
import { Link } from "react-router-dom";
import { Table, Space, Input, Row, Col } from "antd";
import { StarFilled } from "@ant-design/icons";
import styled from "styled-components";
import "./shell.css";

const { TextArea } = Input;


const StyledContent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .product-image {
    height: 100px;
    width: 100px;
    background-color: #e5e5e5;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    margin: 8px 0;
    font-size: 13px;
  }
  .inputs-container {
    width: calc(100% - 100px);
    margin-left: 12px;
  }
`;

const StyledRating = styled.div`
  text-align: center;
  svg {
    color: #f7c667;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: #fff;
    border-bottom: none;
  }

  .ant-table-cell {
    text-align: center;
    border-bottom: 2px solid #dcd2d2;
  }
`;

const ProductListing = (props) => {
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h3>Product List</h3>
          </Col>
          <Col>
            <span
              style={{ color: "#275DAD", marginRight: 24, fontWeight: 600 }}
            >
              Download List (PDF)
            </span>
            <Link
              to={`/dashboard/add-product`}              
            >
              <button                
                style={{
                  color: "#fff",
                  backgroundColor: "#275DAD",
                  border: "none",
                  padding: "12px 32px",
                  fontWeight: 600,
                  borderRadius: 8,
                }}
              >
                Add Product
              </button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24} style={{ marginTop: 24 }}>
        <StyledTable
          dataSource={data}
          columns={[
            {
              key: "serial",
              title: "Serial Num",
              render: () => <div>001</div>,
              width: 140,
            },
            {
              key: "product",
              title: "Product",
              render: (r) => {
                console.log("Renderer", r);
                return (
                  <StyledContent>
                    <div className="product-image" />
                    <div className="inputs-container">
                      <Input value="Myprotein Impact Whey Protein" />
                      <TextArea
                        value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dignissim imperdietrisus eu vulputate. Proin viverra lacinia libero, in varius tortor accumsan et. Mauris tincidunt tristique nulla, ac "
                        rows={4}
                        autoSize={{ minRows: 3, maxRows: 3 }}
                      />
                    </div>
                  </StyledContent>
                );
              },
            },
            {
              key: "price",
              title: "Price",
              dataIndex: "",
              render: (r) => {
                return (
                  <input
                    value="$0.00"
                    style={{ fontWeight: 600, width: 72, textAlign: "center" }}
                  />
                );
              },
              width: 60,
            },
            {
              key: "popularity",
              title: "Popularity",
              render: (r) => <p>15</p>,
              width: 60,
            },
            {
              key: "rating",
              title: "Rating",
              dataIndex: "",
              render: () => (
                <StyledRating>
                  <div className="star-icon-container">
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                  </div>
                  <div style={{ fontWeight: 600 }}>5/5 (100)</div>
                </StyledRating>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ProductListing;

const data = [
  {
    title: "Ubaid",
    type: "Developer",
    grs: "I don't know",
    soldDate: new Date(),
  },
  {
    title: "Ubaid",
    type: "Developer",
    grs: "I don't know",
    soldDate: new Date(),
  },
  {
    title: "Ubaid",
    type: "Developer",
    grs: "I don't know",
    soldDate: new Date(),
  },
];
