import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import arrow_small_ico from "./../../../assets/images/arrow-small-ico.svg";
import api from "../../../apis";
import jsCookie from "js-cookie";
import Notify from "cogo-toast";
// import { th } from "date-fns/locale";
// import client from "../../../client";
import { PrimaryBtn } from "../../../component/button/Button";
import Footer from "../../../component/footer/Footer";
import NoResult from "../Error/NoResult";
import Layout from "../Layouts/PageLayouts";
import {
  deleteDeal,
  excludeObj,
  sortDeals,
  getDealResult,
  getFromOffsetToLimit,
  truncateNum,
} from "./service";
import LiveDetailActions from "./LiveDetailActions";
import Pagination from "../../../component/pagination";
import SortOrder from "../../../component/sort-order";
import Dropdown from "../../../component/dropdown";
import SortingMenu from "./SortingMenu";

const pageLimit = 20;
class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      userid: "",
      products: [],
      sortType: "Date Added",
      isAscend: true,
      pageOffset: 0,
      loading: true,
    };
  }

  componentDidMount() {
    const userid = jsCookie.get("userinfo")
      ? JSON.parse(jsCookie.get("userinfo")).sno
      : null;
    // console.log({ userid }) ;
    if (userid) {
      this.setState({ userid });
      api.fetchUserDeals({ userid }).then((res) => {
        this.setState({ deals: res.data });
      }).finally(() => {
        this.setState({ loading: false });
      })
    } else {
      Notify.error("You need to be logged in");
    }
    // api.fetchProducts({ profile_id: "imkhaaan@gmail.com" })
    //     .then((res) => {
    //         this.setState({ products: res.data });
    //         // console.log("Product ==>", res);
    //     })
    //     .catch((err) => {
    //         // console.log(err);
    //         Notify.error("Error fetching live deal results");
    //     });

    api
      .fetchProductsForLiveResults({})
      .then((res) => {
        this.setState({ products: res.result });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    if (this.state.loading) {
      return <div className="dots-page"><div className="dots"></div></div>
    }
    if (this.state.deals?.length <=0) {
      return  <NoResult
      className="pt-md-5 pt-4"
      text="Add New"
      link="/add-live"
      desc="There is nothing to see in live deals"
    />
    }

    return (
      <main className="w-100">
        {/* {this.state.deals?.length > 0 ? ( */}
          <section className="__live_table_section pt-4">
            <div className="d-flex justify-content-between w-100 align-items-center px-4 py-2">
              <h4>Live Deal</h4>
              <Link to="/add-live">
                <PrimaryBtn text="Add New" />
              </Link>
            </div>

            <div
              style={{
                width: "fit-content",
                margin: "0 auto",
                marginTop: "8px",
              }}
            >
              <div className="__order-table-setting-container">
                <div className="__order-table-setting">
                  <div className="__sorting-container">
                    <span className="mr-1">Sort by:</span>
                    <Dropdown
                      menu={
                        <SortingMenu
                          handleClick={(value) => {
                            this.setState({ sortType: value });
                          }}
                        />
                      }
                      btnText={this.state.sortType}
                    />
                    <SortOrder
                      isAscend={this.state.isAscend}
                      handleClick={() => {
                        this.setState({ isAscend: !this.state.isAscend });
                      }}
                    />
                  </div>
                </div>
                <Pagination
                  currentFirstNo={truncateNum(
                    this.state.deals.length ? this.state.pageOffset + 1 : 0,
                    0,
                    this.state.deals.length
                  )}
                  currentLastNo={truncateNum(
                    this.state.pageOffset + pageLimit,
                    0,
                    this.state.deals.length
                  )}
                  totalNo={this.state.deals.length}
                  onPreviousClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      pageOffset: truncateNum(
                        this.state.pageOffset - pageLimit,
                        0,
                        this.state.pageOffset
                      ),
                    });
                  }}
                  onNextClick={(e) => {
                    e.stopPropagation();
                    const newOffset = truncateNum(
                      this.state.pageOffset + pageLimit,
                      0,
                      this.state.deals.length
                    );
                    this.setState({
                      pageOffset:
                        newOffset === this.state.deals.length
                          ? this.state.pageOffset
                          : newOffset,
                    });
                  }}
                />
              </div>
              <table className="__table __h-50 __product">
                <thead className="__sticky">
                  <tr>
                    <th scope="col">S/N</th>
                    <th scope="col">Product Search</th>
                    <th scope="col">Price Range</th>
                    <th scope="col">Result</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {getFromOffsetToLimit(
                    sortDeals(this.state),
                    this.state.pageOffset,
                    pageLimit
                  ).map((deal, index) => {
                    const actions = (
                      <LiveDetailActions
                        onEditClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push({
                            pathname: "/update-live",
                            state: {
                              ...deal,
                              date: deal.created,
                            },
                          });
                        }}
                        onDeleteClick={(e) => {
                          e.stopPropagation();
                          deleteDeal(this.state.userid, deal.id).then(
                            (isSuccessFul) =>
                              isSuccessFul &&
                              this.setState({
                                deals: excludeObj(deal, this.state.deals),
                              })
                          );
                        }}
                      />
                    );

                    return (
                      <>
                        <tr
                          key={deal.id}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/live-detail",
                              state: {
                                ...deal,
                                date: deal.created,
                              },
                            });
                          }}
                        >
                          {actions}
                          <th data-title="S/N" scope="row">
                            <span className="__line-clamp-3">
                              {" "}
                              {this.state.pageOffset + index + 1}
                            </span>
                          </th>
                          <td data-title="Product Search">{deal.deal_name}</td>
                          <td data-title="Price Range" className="text-right">
                            <span>{`$${parseFloat(deal.min_value).toFixed(
                              2
                            )} - $${parseFloat(deal.max_value).toFixed(
                              2
                            )}`}</span>
                          </td>
                          <td data-title="Result" className="text-right">
                            {getDealResult(this.state, deal)}
                          </td>
                          <td data-title="Actions">{actions}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        {/* // ) : this.state.userid ? (
        //   <NoResult
        //     className="pt-md-5 pt-4"
        //     text="Add New"
        //     link="/add-live"
        //     desc="There is nothing to see in live deals"
        //   />
        // ) : (
        //   <NoResult desc="There is nothing to see in live deals" />
        // )} */}
      </main>
    );
  }
}

export default withRouter(Live);
