import React, { useState } from "react";
import { Row, Col } from "antd";
import Avatar from "../avatar/index";
import { Badge } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import {
  StyledButton,
  TableButton,
} from "../../component/styled-component/styledButton";
import adminAvatar from "../../images/admin.png";
import notification_ico from "../../assets/images/notification-ico.svg";
import search_white_ico from "../../assets/images/search-white-ico.svg";
import search_dark_ico from "../../assets/images/search-dark-ico.svg";
import user_ico from "../../assets/images/user-ico.svg";
import burger_ico from "../../assets/images/burger-ico.svg";
import { useHistory, Link } from "react-router-dom";

const HeaderContent = (props) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState("none");

  const handleLogout = async () => {
    console.log("handleLogout");
  };

  return (
    <div>
      <Row span={24}>
        <Col span={14}>
          <button
            onClick={props.toggleCollapsed}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#EDF5F9",
              cursor: "pointer",
            }}
          >
            {props.state.collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: 24, color: "#1D194D" }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: 24, color: "#1D194D" }} />
            )}{" "}
            <span style={{ margin: "0px 0px 0px 20px" }}>{"Merchant Panel"}</span>
          </button>
        </Col>
        <Col span={10}>
          <Row span={24}>
            <Col span={8} style={{ position: "fixed", right: 200 }}>
              <span style={{ margin: "0 20px 0 30px" }}>
                <img src={search_dark_ico} alt="Icon" />
              </span>
            </Col>
            <Col span={8} style={{ position: "fixed", right: 160 }}>
              <Badge count={5}>
                <img className="img-fluid" src={notification_ico} alt="Ico" />
              </Badge>              
            </Col>
            <Col span={8} style={{ position: "fixed", right: 80 }}>
              <Avatar
                shape="circle"
                size="large"
                icon="user"
                style={{ color: "white" }}
                src={adminAvatar}
                onClick={() =>
                  showMenu === "none"
                    ? setShowMenu("inline-flex")
                    : setShowMenu("none")
                }
              />
            </Col>
            {/* <Col style={{ marginLeft: 16 }}>
            <StyledButton onClick={handleLogout} loading={false}>
              Logout
            </StyledButton>
          </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderContent;
