import * as Types from "../Types";

const INITIAL_STATE = {
    // userData: {
    //   status: 200,
    //   message: "User Registered Successfully",
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbmFtYWRtaW5kdkB5b3BtYWlsLmNvbSIsImlhdCI6MTYzMzAyNzY1MiwiZXhwIjoxNjMzNjMyNDUyfQ.vPq2ml9QfU6-Nr9ifwy4syWm99ay2zFKiB-stoHP5Mo",
    //   name: "Inam Khan",
    //   email: "inamadmindv@yopmail.com",
    //   profile_id: "inamadmindv@yopmail.com",
    //   phonenumber: "923454920939",
    //   dialing_code: "92",
    //   dob: "2008-09-24T16:31:40.000Z",
    //   gender: "Male",
    //   otp_verification_status: 1,
    //   type: 3,
    // },
    userData: null,
    userCart: [],
    userWishlist: [],
    orderAmount: 20,
    userOrders: [],
    newOrder: [],
    orderDetail: [],
    newCartItem: null,
    newWishlistItem: null,
    userLoading: false,
    sendOtp: null,
    error: null,
    dropdown: false,
    checkoutData: null,
    headerSearchValue: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
    //  console.log("Entered Reducer and action is >>", action);

    switch (action.type) {
        case Types.TOGGLE_OPEN: {
            return Object.assign({}, state, { dropdown: action.payload });
        }
        case Types.LOGOUT: {
            return Object.assign({}, state, { userData: null });
        }
        case Types.REGISTER_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.REGISTER_SUCCESS: {
            return Object.assign({}, state, {
                userData: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.REGISTER_FAILED: {
            return Object.assign({}, state, {
                userData: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.LOGIN_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.LOGIN_SUCCESS: {
            return Object.assign({}, state, {
                userData: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.LOGIN_FAILED: {
            return Object.assign({}, state, {
                userData: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.SEND_OTP_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.SEND_OTP_SUCCESS: {
            return Object.assign({}, state, {
                sendOtp: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.SEND_OTP_FAILED: {
            return Object.assign({}, state, {
                sendOtp: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.USER_UPDATE_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.USER_UPDATE_SUCCESS: {
            return Object.assign({}, state, {
                userData: { ...action.payload, otp_verification_status: 1 },
                error: null,
            });
        }
        case Types.USER_UPDATE_FAILED: {
            return Object.assign({}, state, {
                sendOtp: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.VERIFY_OTP_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.VERIFY_OTP_SUCCESS: {
            return Object.assign({}, state, {
                userData: { ...action.payload, otp_verification_status: 1 },
                userLoading: false,
                error: null,
            });
        }
        case Types.VERIFY_OTP_FAILED: {
            return Object.assign({}, state, {
                userData: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.VIEW_CART_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.VIEW_CART_SUCCESS: {
            let firstPayload = action.payload;
            let updatedPayload = action.payload;
            let ammountCart = 0;
            if (firstPayload && firstPayload.length > 0) {
                updatedPayload = Object.values(
                    firstPayload.reduce((acc, obj) => {
                        const curr = acc[obj.sno];
                        acc[obj.sno] = curr
                            ? curr.product_quantity < obj.product_quantity
                                ? obj
                                : curr
                            : obj;
                        return acc;
                    }, {})
                );
            }
            updatedPayload.forEach((item) => {
                if (item.product_quantity && item.product_price) {
                    ammountCart =
                        ammountCart +
                        item.product_quantity * item.product_price;
                }
            });
            return Object.assign({}, state, {
                userCart: updatedPayload,
                orderAmount: ammountCart,
                userLoading: false,
                error: null,
            });
        }
        case Types.VIEW_CART_FAILED: {
            return Object.assign({}, state, {
                userCart: [],
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.ADD_CART_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.ADD_CART_SUCCESS: {
            return Object.assign({}, state, {
                newCartItem: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.ADD_CART_FAILED: {
            return Object.assign({}, state, {
                newCartItem: {},
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.DELETE_CART_ITEM_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.DELETE_CART_ITEM_SUCCESS: {
            return Object.assign({}, state, {
                userLoading: false,
                error: null,
            });
        }
        case Types.DELETE_CART_ITEM_FAILED: {
            return Object.assign({}, state, {
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.INCREMENT_CART_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.INCREMENT_CART_SUCCESS: {
            return Object.assign({}, state, {
                userLoading: false,
                error: null,
            });
        }
        case Types.INCREMENT_CART_FAILED: {
            return Object.assign({}, state, {
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.DECREMENT_CART_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.DECREMENT_CART_SUCCESS: {
            return Object.assign({}, state, {
                userLoading: false,
                error: null,
            });
        }
        case Types.DECREMENT_CART_FAILED: {
            return Object.assign({}, state, {
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.CHECKOUT_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.CHECKOUT_SUCCESS: {
            return Object.assign({}, state, {
                checkoutData: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.CHECKOUT_FAILED: {
            return Object.assign({}, state, {
                checkoutData: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.CREATE_ORDER_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.CREATE_ORDER_SUCCESS: {
            return Object.assign({}, state, {
                newOrder: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.CREATE_ORDER_FAILED: {
            return Object.assign({}, state, {
                newOrder: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.USER_ORDERS_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.USER_ORDERS_SUCCESS: {
            return Object.assign({}, state, {
                userOrders: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.USER_ORDERS_FAILED: {
            return Object.assign({}, state, {
                userOrders: null,
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.USER_UDPATE_HEADER_SEARCH_VALUE: {
            return Object.assign({}, state, {
                headerSearchValue: action.payload,
            });
        }
        case Types.ORDER_DETAIL_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.ORDER_DETAIL_SUCCESS: {
            return Object.assign({}, state, {
                orderDetail: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.ORDER_DETAIL_FAILED: {
            return Object.assign({}, state, {
                orderDetail: null,
                error: action.payload,
                userLoading: false,
            });
        }
        // Wishlist Starts here
        case Types.VIEW_WISHLIST_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.VIEW_WISHLIST_SUCCESS: {
            // let firstPayload = action.payload;
            let updatedPayload = action.payload;
            // let ammountCart = 0;
            // if (firstPayload && firstPayload.length > 0) {
            // 		updatedPayload = Object.values(
            // 				firstPayload.reduce((acc, obj) => {
            // 						const curr = acc[obj.sno];
            // 						acc[obj.sno] = curr
            // 								? curr.product_quantity < obj.product_quantity
            // 										? obj
            // 										: curr
            // 								: obj;
            // 						return acc;
            // 				}, {})
            // 		);
            // }
            // updatedPayload.forEach((item) => {
            // 		if (item.product_quantity && item.product_price) {
            // 				ammountCart =
            // 						ammountCart + item.product_quantity * item.product_price;
            // 		}
            // });
            return Object.assign({}, state, {
                userWishlist: updatedPayload,
                userLoading: false,
                error: null,
            });
        }
        case Types.VIEW_WISHLIST_FAILED: {
            return Object.assign({}, state, {
                userWishlist: [],
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.ADD_WISHLIST_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.ADD_WISHLIST_SUCCESS: {
            return Object.assign({}, state, {
                newWishlistItem: action.payload,
                userLoading: false,
                error: null,
            });
        }
        case Types.ADD_WISHLIST_FAILED: {
            return Object.assign({}, state, {
                newWishlistItem: {},
                error: action.payload,
                userLoading: false,
            });
        }
        case Types.DELETE_WISHLIST_REQUEST: {
            return Object.assign({}, state, { error: null, userLoading: true });
        }
        case Types.DELETE_WISHLIST_SUCCESS: {
            return Object.assign({}, state, {
                userLoading: false,
                error: null,
            });
        }
        case Types.DELETE_WISHLIST_FAILED: {
            return Object.assign({}, state, {
                error: action.payload,
                userLoading: false,
            });
        }
        // Wishlist Ends here
        default: {
            return state;
        }
    }
};
export default userReducer;
