import { OPEN_MODAL, CLOSE_MODAL } from "../Types";
//import rootModal from '../commons/modals/rootModal';

export function openModal(title, content, okText, cancelText, modalType, ...args) {
  return {
    type: OPEN_MODAL,
    modalType: modalType || "basic_modal",
    modalProps: {
      title: title,
      visible: true,
      content: content,
      okText: okText || null,
      cancelText: cancelText || null,
      ...args
    },
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}
