import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSingleProductActionRequest,
  paypalPaymentActionRequest,
} from "./../../../store/actions/product";
import jsCookie from "js-cookie";
import { Space, Tooltip } from "antd";
import truncate from "truncate";
import Paypal from "../../../appcomponents/paypal";
// import product_lg_img from "./../../../assets/images/product-lg-img.png";
import favorite_ico from "./../../../assets/images/favorite-ico.svg";
import arrow_tail_ico from "./../../../assets/images/arrow-tail-ico.svg";
// import vertical_more_ico from "./../../../assets/images/vertical-more-ico.svg";
import star_yellow_ico from "./../../../assets/images/star-yellow-ico.svg";
import truck_ico from "./../../../assets/images/truck-ico.svg";
import share_ico from "./../../../assets/images/share-ico.svg";
import cart_ico from "./../../../assets/images/cart-ico.svg";
import ProductCard from "./ProductCard";
import {
  addCartActionRequest,
  viewCartActionRequest,
  addWishlistActionRequest,
  // viewWishlistActionRequest,
} from "../../../store/actions/user";
import { viewCartApi, viewWishlistApi } from "../../../store/apis/index";
import Notify from "cogo-toast";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { ShareThis, Slideshow } from "../../../component/generic";
import dummyimage from "./dummyimage.png";
import api from "../../../apis";
import Layout from "../Layouts/PageLayouts";
import { SecBtn } from "../../../component/button/Button";
import { PrimaryBtn } from "../../../component/button/Button";

import { GiCargoShip } from "react-icons/gi";
import { FiTruck } from "react-icons/fi";
import { IoAirplaneOutline } from "react-icons/io5";

const ProductDetail = (props) => {
  const [target, setTarget] = useState(null);
  const containerRef = useRef(null);
  const [showShare, setShowShare] = useState(false);
  const [isWishlistItem, setIsWishlistItem] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isBuyer, setIsBuyer] = useState(false);

  const [checkout, setCheckOut] = useState(false);

  const [currentImage, setCurrentImage] = useState(
    props.productDetail && props.productDetail.photo1
      ? props.productDetail.photo1
      : dummyimage
  );
  const [imageCount, setImageCount] = useState(1);

  const fetchData = () => {
    const id = props.location.pathname.split("/")[2];
    // console.log("PROPS", props);
    api
      .fetchRelatedProducts({ id })
      .then((res) => {
        setRelatedProducts(res.result);
      })
      .catch((err) => {
        Notify.error("Error fetching product similar items");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect starts here
  useEffect(() => {
    // console.log("omoo => ", JSON.parse(jsCookie.get("userinfo")))
    let requestBody = {
      product_id: props.match.params.id,
      profile_id: jsCookie.get("userinfo")
        ? JSON.parse(jsCookie.get("userinfo")).profile_id
        : "aribakande@gmail.com",
      // JSON.parse(jsCookie.get("userinfo")).sno
    };
    props.getProductDetails(requestBody);
    const profile_id = jsCookie.get("userinfo")
      ? JSON.parse(jsCookie.get("userinfo")).profile_id
      : null;
    // console.log("Detailsss", props.location.pathname.split("/"));
    api
      .fetchbuyers({ product_id: props.match.params.id })
      .then((res) => {
        const buyers = res.buyers;
        setIsBuyer(buyers.some((buyer) => buyer.profile_id === profile_id));
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log("Productt>>>>", props);
  }, []);

  useEffect(() => {
    // console.log("useEffect imageCount >>", imageCount);
    if (imageCount === 3) {
      let setImagePath =
        props.productDetail && props.productDetail.photo3
          ? process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
            ? props.productDetail.photo3.replace(
                `http://eamr.life/exhibition/uploads/`,
                `${process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
              )
            : props.productDetail.photo3
          : dummyimage;

      setCurrentImage(setImagePath);
    } else if (imageCount === 2) {
      let setImagePath =
        props.productDetail && props.productDetail.photo2
          ? process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
            ? props.productDetail.photo2.replace(
                `http://eamr.life/exhibition/uploads/`,
                `${process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
              )
            : props.productDetail.photo2
          : dummyimage;

      setCurrentImage(setImagePath);
    } else {
      let setImagePath =
        props.productDetail && props.productDetail.photo1
          ? process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
            ? props.productDetail.photo1.replace(
                `http://eamr.life/exhibition/uploads/`,
                `${process?.env?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
              )
            : props.productDetail.photo1
          : dummyimage;

      setCurrentImage(setImagePath);
      // console.log("prevImage >>", imageCount, currentImage);
    }
  }, [currentImage, imageCount, props.productDetail]);

  // useEffect ends here

  const handleShare = (e) => {
    setShowShare(!showShare);
    setTarget(e.target);
  };

  /// Adding Product to Cart Methods
  const updateUserCart = async () => {
    if (props.userAuth && props.user.email) {
      try {
        let res = await viewCartApi({ profile_id: props.user.email });
        return res && res.data && res.data.data && res.data.data[0]
          ? res.data.data
          : [];
      } catch (error) {
        console.log(error);
      }
    }
  };
  const addtoCartMethod = async () => {
    if (props.userAuth && props.match.params.id && props.productDetail.name) {
      let oldCart = await updateUserCart();
      let oldFilteredCart = await oldCart.filter((i) => {
        return i.sno == props.match.params.id;
      });
      // console.log("oldFilteredCart length >>", oldFilteredCart.length);
      console.log(
        "Image basepath",
        process?.env?.REACT_APP_PRODUCTIMAGE_BASEPATH
      );
      let tempProductPhoto =
        props.productDetail.photo1 && props.productDetail.photo1.length > 0
          ? //   &&
            // process.env.REACT_APP_PRODUCTIMAGE_BASEPATH
            props.productDetail.photo1
          : // .replace(
            //   `${process.env.REACT_APP_PRODUCTIMAGE_BASEPATH}`,
            //   ""
            // )
            "1600579810_hello_kitty.jpg";
      let requestBody = {
        sno:
          props.match.params.id && props.productDetail.name
            ? props.match.params.id
            : "",
        profile_id: props.user.email,
        product_quantity: 1 + oldFilteredCart.length,
        product_price: props.productDetail.price,
        product_name: props.productDetail.name,
        product_photo: tempProductPhoto,
        shipping_address: props.user.address ? props.user.address : "N/A",
      };

      props.addProductToCart(requestBody);
    } else if (!(props.match.params.id && props.productDetail.name)) {
      Notify.error("This product can not be added to cart");
    } else {
      Notify.error("Please login/signup to add this item to cart.");
    }
  };
  /// Adding Product to Wishlist Methods
  const updateUserWishlist = async () => {
    if (props.userAuth && props.user.email) {
      try {
        let res = await viewWishlistApi({
          profile_id: props.user.email,
        });
        // console.log("Response updateUserCart >>", res);
        return res && res.data && res.data.data && res.data.data[0]
          ? res.data.data
          : [];
      } catch (error) {
        console.log(error);
      }
    }
  };
  const addtoWishListMethod = async () => {
    if (props.userAuth && props.match.params.id && props.productDetail.name) {
      let oldWishlist = await updateUserWishlist();
      let oldFilteredWishlist = await oldWishlist.filter((i) => {
        return i.sno == props.match.params.id;
      });
      // console.log("oldFilteredWishlist length >>", oldFilteredWishlist.length);
      let tempProductPhoto =
        props.productDetail.photo1 &&
        props.productDetail.photo1.length > 0 &&
        process?.env?.REACT_APP_PRODUCTIMAGE_BASEPATH
          ? props.productDetail.photo1.replace(
              `${process?.env?.REACT_APP_PRODUCTIMAGE_BASEPATH}`,
              ""
            )
          : "1600579810_hello_kitty.jpg";
      let requestBody = {
        sno:
          props.match.params.id && props.productDetail.name
            ? props.match.params.id
            : "",
        profile_id: props.user.email,
        // product_quantity: 1 + oldFilteredWishlist.length,
        product_price: props.productDetail.price,
        product_name: props.productDetail.name,
        product_photo: tempProductPhoto,
        product_stock_status: "0",
      };
      if (oldFilteredWishlist.length > 0) {
        Notify.error("Product Already in your wishlist");
      } else {
        // console.log("addProductToWishlist requestBody >>", requestBody);
        props.addProductToWishlist(requestBody);
      }
    } else if (!(props.match.params.id && props.productDetail.name)) {
      Notify.error("This product can not be added to wishlist");
    } else {
      Notify.error("Please login/signup to add this item to wishlist.");
    }
  };

  const chatMerchant = () => {
    // const productid = props.match.params.id ? props.match.params.id : null;
    // sessionStorage.setItem("chatProduct", productid);
    // window.location.href = "/chat";
  };

  return props.productDetail != null ? (
    <Layout style={{ marginTop: "200px" }}>
      <div className="container large">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-5">
                <div className="img-encl">
                  <div className="img-wrap __pdt_imgwrap">
                    <img
                      className="img-fluid border rounded"
                      src={currentImage}
                      alt="img"
                    />
                    <div className="count-favorite">
                      <span className="paginate">{`${imageCount}`}/3</span>
                      {/* <span
                        onClick={() => {
                          addtoWishListMethod();
                        }}
                        className="favorite-ico __livedeal_opacity"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-fluid"
                          src={favorite_ico}
                          alt="ico"
                        />
                      </span> */}
                    </div>
                  </div>
                  <div className="slide-nav">
                    <span
                      onClick={() => {
                        if (imageCount === 1) {
                          setImageCount(3);
                        } else {
                          setImageCount(imageCount - 1);
                        }
                      }}
                      className="prev"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="img-fluid"
                        src={arrow_tail_ico}
                        alt="icon"
                      />
                    </span>
                    <span
                      onClick={() => {
                        if (imageCount === 3) {
                          setImageCount(1);
                        } else {
                          setImageCount(imageCount + 1);
                        }
                      }}
                      className="next"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="img-fluid"
                        src={arrow_tail_ico}
                        alt="icon"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-7 product-cont">
                <div className="p-title">
                  <Tooltip
                    title={
                      props.productDetail.name
                        ? props.productDetail.name
                        : "N/A"
                    }
                    color="#275DAD"
                    placement="bottom"
                  >
                    <h4 style={{ lineHeight: "1.4" }}>
                      {props.productDetail.name
                        ? truncate(props.productDetail.name, 100)
                        : "N/A"}
                    </h4>
                  </Tooltip>
                  {/* <div className="dropdown more-dd">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="moremenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        className="img-fluid"
                        src={vertical_more_ico}
                        alt="icon"
                      />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="moremenu"
                    >
                      <a className="dropdown-item text-danger" href="/#">
                        Cancel Order
                      </a>
                      <Link className="dropdown-item" to="/chat">
                        Chat
                      </Link>
                      <a className="dropdown-item" href="/#">
                        Contact Support
                      </a>
                    </div>
                  </div> */}
                </div>
                {/* <div className="rating-sec">
                  <div className="star-ico">
                    {}
                    <img src={star_yellow_ico} alt="ico" />
                    <img src={star_yellow_ico} alt="ico" />
                    <img src={star_yellow_ico} alt="ico" />
                    <img src={star_yellow_ico} alt="ico" />
                    <img src={star_yellow_ico} alt="ico" />
                    <img src={star_yellow_ico} alt="ico" />
                  </div>
                  <p className="mb-0 text-uppercase">
                    {props.productDetail.overall_rating
                      ? `${props.productDetail.overall_rating}/5`
                      : "N/A"}{" "}
                    (
                    {props.productDetail.reviews
                      ? props.productDetail.reviews.length
                      : "N/A"}
                    )
                  </p>
                </div> */}
                <h4 className="price">
                  {"$ "}
                  {props.productDetail.price
                    ? props.productDetail.price
                    : "N/A"}
                </h4>
                <div className="row mt-5">
                  <div className="col-6">
                    <Link to={"/product-review/" + props.match.params.id}>
                      <SecBtn text="See Reviews" />
                    </Link>
                  </div>
                  {isBuyer ? (
                    <div className="col-6">
                      <Link to={"/add-review/" + props.match.params.id}>
                        <PrimaryBtn block text="Write Review" />
                      </Link>
                    </div>
                  ) : null}
                </div>
                {/* <p className="short-desc">This Item matches your Live Deal</p>
                  <Link to="/update-live">
                    <button className="btn btn-outline">
                      <p>
                        {props.productDetail.category
                          ? props.productDetail.category
                          : "N/A"}{" "}
                        <small>
                          Price Range ${" "}
                          {props.productDetail.min_price
                            ? props.productDetail.min_price
                            : "N/A"}{" "}
                          - $
                          {props.productDetail.max_price
                            ? props.productDetail.max_price
                            : "N/A"}
                        </small>
                      </p>
                      <img src={arrow_small_ico} alt="ico" />
                    </button>
                  </Link>
                 */}
              </div>
            </div>
            <div className="row mt-5">
              <div className="prod-detail col-12">
                <p className="__product-desc-container">
                  <span>Shipping Option:</span>
                  <span>
                    {/* If shipping option is by air, shop the 'IoAirplanOutline icon' */}
                    {
                    props.productDetail.delivery_type  && 
                    props.productDetail.delivery_type.toLowerCase() === "by air" &&
                    <IoAirplaneOutline size={"25"} style={{ opacity: "0.9" }} />
                     }
                    {/* If shipping option is by land, shop the 'FiTruck icon' */}
                    {
                    props.productDetail.delivery_type  && 
                    props.productDetail.delivery_type.toLowerCase() === "by land" &&
                    <FiTruck size={"25"} style={{ opacity: "0.8" }} />
                     }
                    {/* If shipping option is by sea, shop the 'GiCargoShip icon' */}
                    {
                    props.productDetail.delivery_type  && 
                    props.productDetail.delivery_type.toLowerCase() === "by sea" &&
                    <GiCargoShip size={"25"} style={{ opacity: "0.9" }} />
                     }
                  </span>
                </p>
                <p className="__product-desc-container">
                  <span>Serial Number:</span>
                  <span>
                    {props.match.params.id ? props.match.params.id : "N/A"}
                  </span>
                </p>
                <p className="__product-desc-container">
                  <span>Size:</span>
                  <span>
                    {" "}
                    {props.productDetail.dimension &&
                    props.productDetail.dimension.toString().length > 0
                      ? props.productDetail.dimension
                      : "N/A"}
                  </span>
                </p>
                <p className="__product-desc-container">
                  <span>Stock:</span>
                  <span>
                    {" "}
                    {props.productDetail.stock
                      ? props.productDetail.stock
                      : "N/A"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="product-right">
              <Link
                to={
                  "/chat?prod=" +
                  (props.match.params.id ? props.match.params.id : "")
                }
              >
                <button
                  className="btn btn-outline mb-3"
                  // onClick={chatMerchant}
                  disabled
                >
                  Chat Merchant
                </button>
              </Link>
              {/* <Link to="/cart"> */}
              <button
                className="btn btn-light-blue mb-3"
                onClick={() => {
                  // console.log("Add to Cart clicked>>", true);
                  addtoCartMethod();
                }}
              >
                Add to Cart
              </button>
              {/* </Link> */}

              {checkout ? (
                <Paypal />
              ) : (
                <button
                  onClick={() => {
                    // setCheckOut(true)
                    addtoCartMethod().then(() => {
                      window.location.href = "/cart";
                    });
                  }}
                  className="btn btn-dark-blue"
                >
                  Buy Now
                </button>
              )}
              <div
                ref={containerRef}
                className="btn-group d-flex justify-content-around align-items-center mt-3 flex-wrap"
              >
                <Tooltip title="Share" color="#275DAD" placement="bottom">
                  <button
                    onClick={handleShare}
                    className="__share d-flex pb-md-0 align-items-center"
                  >
                    <img src={share_ico} alt="icon" className="pr-2" />
                    <Overlay
                      show={showShare}
                      target={target}
                      placement="bottom"
                      container={containerRef.current}
                    >
                      <Popover id="popover-contained">
                        <Popover.Content>
                          <ShareThis
                            id={
                              props.match.params.id ? props.match.params.id : ""
                            }
                            title={
                              props.productDetail.name
                                ? props.productDetail.name
                                : "N/A"
                            }
                          />
                        </Popover.Content>
                      </Popover>
                    </Overlay>
                  </button>
                </Tooltip>
                <Tooltip title="See Cart" color="#275DAD" placement="bottom">
                  <Link to="/cart" className="__pdt_link">
                    <button className="btn" title="See cart">
                      <img src={cart_ico} alt="icon" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip
                  title="Add to live"
                  color="#275DAD"
                  placement="bottom"
                >
                  <Link to={{ pathname:"/add-live",  search: `?${new URLSearchParams({title: props.productDetail.name})}`  }}  className="__pdt_link">
                    <button className="btn" title="add live">
                      <img src={favorite_ico} alt="icon" />
                    </button>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <p className="__product-section-title mb-2">Product Description</p>
            <p className="opacity-4">{props.productDetail.description}</p>
          </div>
        </div>
        <div className="row mt-4 mb-2">
          <div className="col-12 product-encl-title">
            <p className="__product-section-title">Related Products</p>
            <Link to="/" className="text-link">
              See All
            </Link>
          </div>
        </div>
        <div className="row product-encl row-mx-half single-row">
          {
            /* {productsData && productsData.length
              ? productsData.map((item) => {
                  return <ProductCard data={item} />;
                })
              : null} */
            relatedProducts && relatedProducts.length > 0
              ? relatedProducts.slice(0, 6).map((product) => {
                  return <ProductCard key={product.sno} data={product} />;
                })
              : null
          }
        </div>
      </div>
    </Layout>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  productList:
    state.product &&
    state.product.productList &&
    state.product.productList.length > 0
      ? state.product.productList
      : [],
  productDetail:
    state.product && state.product.productDetail != null
      ? state.product.productDetail
      : null,
  user: state.user.userData,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
  userCart: state.user.userCart,
});

const mapDispatchToProps = (dispatch) => {
  return {
    payPalPayment: (...args) => {
      dispatch(paypalPaymentActionRequest(...args));
    },

    addProductToCart: (...args) => {
      dispatch(addCartActionRequest(...args));
    },
    addProductToWishlist: (...args) => {
      dispatch(addWishlistActionRequest(...args));
    },
    getProductDetails: (...args) => {
      dispatch(getSingleProductActionRequest(...args));
    },
    loadUserCart: (...args) => {
      dispatch(viewCartActionRequest(...args));
    },
  };
};

const productDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetail);

export default productDetail;
