import React from 'react';
import { Zoom } from 'react-slideshow-image';


const Slideshow = ({images}) => {
  // const images = [
  //   'images/slide_2.jpg',
  //   'images/slide_3.jpg',
  //   'images/slide_4.jpg'
  // ];
console.log("images >>", images)
  const zoomOutProperties = {
    indicators: true,
    scale: 0.4,
    indicators: i => (<div className="indicator">{i + 1}</div>)
  }
  return (
    <div>
      <Zoom {...zoomOutProperties}>
        { images.map((each, index) => <img key={index} style={{width: "100%"}} src={each} />) }
      </Zoom>
    </div>
  )
}

export default Slideshow;
