import { useContext } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { CgAsterisk } from "react-icons/cg";
import get from "lodash/get";
import { handleInputChange } from "./services";
import { FormContext } from "./hooks";

const PhoneNumberInput = ({ label, name, placeholder, require }) => {
    const { state, action } = useContext(FormContext);
    const errMsg = get(state, `${name}.errMsg`);

    return (
        <div className="col-lg-6">
            <div className="form-group">
                <label className="d-flex flex-column">
                    <span htmlFor={name} className="__labe_l mb-2">
                        {label}
                        {require ? (
                            <sup>
                                <CgAsterisk className="text-danger small" />
                            </sup>
                        ) : (
                            <></>
                        )}
                    </span>
                    <div className="form-group mb-0">
                        <PhoneInput
                            inputStyle={{
                                width: "100%",
                                backgroundColor: "rgb(255 255 255)",
                                color: `black`,
                                outline: errMsg ? "1px solid #dc3545" : "",
                            }}
                            buttonStyle={{
                                backgroundColor: "rgb(255 255 255)",
                            }}
                            enableAreaCodeStretch
                            placeholder={placeholder}
                            country={"sg"}
                            value={get(state, `${name}.value`) ?? ""}
                            onChange={(phone, country) => {
                                handleInputChange(state, action, name, {
                                    propToValidate: `${name}.value`,
                                    update: {
                                        [name]: {
                                            value: phone,
                                            phoneData: country,
                                        },
                                    },
                                });
                            }}
                        />
                        {errMsg ? (
                            <span className="form-error-span">{errMsg}</span>
                        ) : null}
                    </div>
                </label>
            </div>
        </div>
    );
};

export default PhoneNumberInput;
