import { OPEN_MODAL, CLOSE_MODAL } from "../Types"

const INITIAL_STATE = {
  modals: []
};

let ModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      const { type, ...data } = action;
      let newModals = [...state.modals];
      newModals.push(data);
      return Object.assign({}, state, {modals: newModals});
    }
    case CLOSE_MODAL: {
      // console.log("Close Modal Reducer Called...")
      let newModals = [...state.modals];
      newModals.pop();
      return Object.assign({}, state, {modals: newModals});
    }
    default: {
      return INITIAL_STATE;
    }
  }
}

export default ModalReducer;