import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  toggleOpen,
  logoutUser,
  userOrdersActionRequest,
} from "../../../store/actions/user";
import { openModal, closeModal } from "../../../store/actions/Modals";
import { DateFormatter } from "../../../component/generic";
import Api from "../../../apis";

import next_arrow from "./../../../assets/images/next-arrow.svg";
// import el from "date-fns/esm/locale/el/index.js";
import { PrimaryBtn } from "../../../component/button/Button";
import Layout from "../Layouts/PageLayouts";
import OrderDropdown from "./OrderDropdown";
import FilterMenu from "./FilterMenu";
import Pagination from "./Pagination";
import { filterOrders, truncateNum, getFromOffsetToLimit } from "./service";

const pageLimit = 20;
const OrderList = (props) => {
  const [userOrders, setUserOrders] = useState([]);
  const [order, setOrder] = useState([]);
  const [filterType, setFilterType] = useState("All");
  const [pageOffset, setPageOffset] = useState(0);
  //   const history = useHistory();

  useEffect(() => {
    let requestBody = {
      profile_id: props.user.email,
    };
    (async () => {
      await Api.getUserOrders(requestBody).then((data) => {
        setUserOrders(data.data);
        setOrder(data.data);
      });
    })().catch((err) => {
      console.log(err);
    });
  }, []);

  console.log("userOrders", userOrders);
  console.log("order", order);

  return (
    <>
      <Layout style={{ marginTop: "100px" }}>
        <div className="container large mt-4 orderlist-container">
          <div className="row">
            <div className="col-12 review-title-wrap mb-4">
              <h1>
                <Link to="/profile" className="back-ico">
                  <img src={next_arrow} alt="icon" />
                </Link>
                Order Summary
              </h1>
            </div>
            <div
              style={{
                width: "fit-content",
                margin: "0 auto",
                marginTop: "8px",
              }}
            >
              {/* <div className="__order-table-setting-container">
                <div className="__order-table-setting">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Filter by:</span>
                    <OrderDropdown
                      menu={
                        <FilterMenu
                          handleClick={(value) => {
                            setFilterType(value);
                          }}
                        />
                      }
                      btnText={filterType}
                    />
                  </div>
                </div>
                <Pagination
                  currentFirstNo={truncateNum(
                    order.length ? pageOffset + 1 : 0,
                    0,
                    order.length
                  )}
                  currentLastNo={truncateNum(
                    pageOffset + pageLimit,
                    0,
                    order.length
                  )}
                  totalNo={order.length}
                  onPreviousClick={(e) => {
                    e.stopPropagation();
                    setPageOffset((o) => truncateNum(o - pageLimit, 0, o));
                  }}
                  onNextClick={(e) => {
                    e.stopPropagation();
                    setPageOffset((o) => {
                      const newOffset = truncateNum(
                        o + pageLimit,
                        0,
                        order.length
                      );
                      console.log(
                        "New oF",
                        newOffset === order.length ? o : newOffset
                      );
                      return newOffset === order.length ? o : newOffset;
                    });
                  }}
                />
              </div> */}
              <table className="__table __h-50">
                <thead className="__sticky">
                  <tr>
                    <th scope="col">S/N</th>
                    <th scope="col">ID</th>
                    <th scope="col">Ordered On</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {state.orders.length > 0 ? ( */}
                  {order.length > 0 ? (
                    <>
                      {getFromOffsetToLimit(
                        filterOrders(order, filterType),
                        pageOffset,
                        pageLimit
                      ).reverse().map((o, index) => {
                        return (
                          <tr>
                            <th data-title="S/N" scope="row">
                              <span className="__line-clamp-3">
                                {" "}
                                {index + 1}
                              </span>
                            </th>
                            <td data-title="ID">{o.order_id}</td>
                            <td data-title="Ordered On">
                              <DateFormatter
                                date={
                                  o && o.update_time !== "N/A"
                                    ? o.update_time
                                    : new Date()
                                }
                                pattern="dd/MM/yy"
                              />
                            </td>
                            <td data-title="Action">
                              <Link to={"/view-order/" + o?.order_id}>
                                <PrimaryBtn text="View Details" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr className="text-center container mx-auto">
                        <td colSpan={8}>
                          <div className="mt-4">
                            {/* <EmptyResult desc="No order." height={100} /> */}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
  userCart: state.user.userCart,
  orderAmount: state.user.orderAmount,
  userOrders: state.user.userOrders,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    userOrdersActionRequest: (...args) => {
      dispatch(userOrdersActionRequest(...args));
    },
    toggleOpen: (status) => dispatch(toggleOpen(status)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
