import React from "react";
import { Tooltip, Image, Popconfirm, Tag } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { BiPlus, BiMinus } from "react-icons/bi";
import { GiCargoShip } from "react-icons/gi";
import { FiTruck } from "react-icons/fi";
import { IoAirplaneOutline } from "react-icons/io5";
import { BsTrash } from "react-icons/bs";
import truncate from "truncate";
import defaultImg from "./../../../assets/images/default.png";
// import truck_ico from "./../../../assets/images/truck-ico.svg";

const CartItem = (props) => {
  console.log("props", props);
  return (
    <>
      <tr>
        <td className="align-middle">
          <div className="single-product">
            <div className="img-wrap">
              <Image
                src={
                  props.item.product_photo
                    ? `${props.item.product_photo}`
                    : defaultImg
                }
                preview={false}
                alt="product"
                width={70}
                style={{ borderRadius: "2px", border: "1px solid #eee" }}
              />
            </div>
            <div className="sp-cont ml-2">
              <Tooltip
                title={props.item.product_name}
                color="#275DAD"
                placement="bottom"
              >
                <h6 className="text-capitalize">
                  {props.item.product_name
                    ? truncate(props.item.product_name, 40)
                    : "N/A"}
                </h6>
              </Tooltip>
              <span className="price mt-2">
                {props.item.product_price
                  ? `$${props.item.product_price}`
                  : "N/A"}
              </span>
              <div className="mt-1">
                <Popconfirm
                  title="Are you sure to remove this item?"
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  onConfirm={() => {
                    if (props.incrementItem) {
                      props.deleteItem();
                    }
                  }}
                >
                  <Tooltip title="Remove" color="#a90000" placement="bottom">
                    <button className="__btn_action d-block">
                      <Tag color="error">
                        <BsTrash />
                      </Tag>
                    </button>
                  </Tooltip>
                </Popconfirm>
              </div>
            </div>
          </div>
        </td>
        <td className="align-middle">
          <div className="qty-sec">
           <div className="form-group d-flex align-items-center mb-0 __qty_cart">
              <Tooltip title="Decrease" color="#a90000" placement="bottom">
                {!props.isOrderSummary && (
                  <button
                    className="__qty_btn __qty_de"
                    onClick={() => {
                      console.log("cartDecrement called...");
                      if (props.incrementItem) {
                        props.decrementItem();
                      }
                    }}
                  >
                    <BiMinus />
                  </button>
                )}
              </Tooltip>
              <input
                type="text"
                name=""
                className="form-control h-full __qty_input"
                value={`${
                  props.item.product_quantity ? props.item.product_quantity : 0
                }`}
              />
              <Tooltip title="Increase" color="#275DAD" placement="bottom">
                {!props.isOrderSummary && (
                  <button
                    className="__qty_btn __qty_in"
                    onClick={() => {
                      console.log("cartIncrement called...");
                      if (props.incrementItem) {
                        props.incrementItem();
                      }
                    }}
                  >
                    <BiPlus />
                  </button>
                )}
              </Tooltip>
            </div>
          </div>
        </td>
        <td className="align-middle">
          <div className="d-flex align-items-center justify-content-center">
            {props.shipping_option === "land" ? (
              <Tooltip title="By Land" color="#275DAD" placement="bottom">
                <button className="__bt_n">
                <FiTruck size={"35"} style={{ opacity: "0.8" }} />
                </button>
              </Tooltip>
            ) : props.shipping_option === "air" ? (
              <Tooltip title="By Air" color="#275DAD" placement="bottom">
                <button className="__bt_n">
                <IoAirplaneOutline size={"35"} style={{ opacity: "0.9" }} />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="By Water" color="#275DAD" placement="bottom">
                <button className="__bt_n">
                <GiCargoShip size={"35"} style={{ opacity: "0.9" }} />
                </button>
              </Tooltip>
            )}
          </div>
        </td>
        <td></td>
        <td className="align-middle">
          <h6>{`$${props.item.product_price * props.item.product_quantity}`}</h6>
        </td>
      </tr>
    </>
  );
};

export default CartItem;
