import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import defaultImg from '../../../assets/images/default.png';
import { getAllProductsActionRequest } from '../../../store/actions/product';
import Layout from '../Layouts/PageLayouts';
// import { Tooltip } from "antd";
// import { FiLogIn, FiUserPlus } from "react-icons/fi";
import axios from 'axios';
import { serverurl } from '../../../helper';
import InstructionModal from '../Layouts/Modals';
import { RenderList } from './RenderList';

const ProductList = (props) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    try {
      const requestBody = {
        profile_id: 'imkhaaan@gmail.com',
        pageno: 1,
      };

      axios
        .post(serverurl + '/api/product/homepage', requestBody)
        .then((response) => {
          console.log(response, 'response');
          setItems([...response.data.data]);
          setPage(page + 1);
          setLoading(false);
        });
      // props.getAllProducts(requestBody)
      // console.log(props.product.productsList,  'things')
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const fetchMoreData = async() => {
    console.log('fetch');
    try {
      const requestBody = {
        profile_id: 'imkhaaan@gmail.com',
        pageno: page,
      };

     await axios
        .post(serverurl + '/api/product/homepage', requestBody)
        .then((response) => {
          console.log(response, 'new');
          setItems([...items,...response.data.data]);
          setPage(page + 1);
          if(items.length===response.data.data.totalpages){
            setHasMore(false)
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(items,'ppp')
  const filteredProduct = (items ?? []).filter(({ name }) =>
    new RegExp(
      (props.searchValue ?? '')
        .replace(/\s*/g, '')
        .replace(/[\\[.+*?(){|^$]/g, '\\$&'),
      'i'
    ).test(name.replace(/\s*/g, ''))
  );



  if(loading){
    return <div className="dots-page"><div className="dots"></div></div>
  }

  if(filteredProduct.length<1){
    return <div>
      <h5>No product found</h5>
    </div>
  }

  return (
    <Layout className='mt-5'>
      {/* Instruction Modals */}
      <InstructionModal user={props.user} />

      <div className='container large' style={{ overflowY: 'hidden' }}>
        <RenderList
          dataLength={items?.length ?? 0}
          hasMore={hasMore}
          fetchMoreData={fetchMoreData}
        >
          <div className='row product-encl'>
            {/* {filteredProduct && filteredProduct.length > 0 ? ( */}
             {filteredProduct.map((data, i) => {
                // console.log("Env ==>", process.env);
                // REACT_APP_API_BASE_URL
                let newImagePath = process?.env
                  ?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
                  ? data.photo1.replace(
                      `http://eamr.life/exhibition/uploads/`,
                      `${process.env.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
                    )
                  : data.photo1;

                return (
                  <>
                    <div
                      className='col-lg-2 col-md-3 col-6 px-2 mb-md-4 mb-3 mt-2'
                      key={i}
                    >
                      <Link
                        to={`/product-detail/${data.sno}`}
                        className='product-snippet'
                      >
                        <div className='p-img'>
                          <img
                            className='img-fluid'
                            src={newImagePath || defaultImg}
                            alt='Product Name'
                          />
                        </div>
                        <div className='p-cont'>
                          <h5>{data.name}</h5>
                          <p>{data.description}</p>
                          <span className='price' data-title='$'>
                            {data.price}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })
            // ) : (
            //   <>
            //     <div>
            //       <h5>Loading products....</h5>
            //     </div>
            //   </>
            // )}
            }</div>
        </RenderList>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  searchValue: state.user.headerSearchValue,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProducts: (...args) => {
      dispatch(getAllProductsActionRequest(...args));
    },
  };
};

const productList = connect(mapStateToProps, mapDispatchToProps)(ProductList);

export default productList;
