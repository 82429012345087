import React, { Component } from "react";
import { Link , withRouter} from "react-router-dom";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import api from "../../../apis/index";
import jsCookie from "js-cookie";
import Notify from "cogo-toast";
// import { useHistory } from "react-router";
import { PrimaryBtn } from "../../../component/button/Button";
import io from "socket.io-client";
import Layout from "../Layouts/PageLayouts";
import { serverurl } from "../../../helper";
import InstructionModal from "../Layouts/Modals";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

let socket;

class AddLive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: "",
            from: 0,
            to: 0,
            userid: JSON.parse(jsCookie.get("userinfo")).sno,
           
        };
    }

    componentDidMount() {
        socket = io(serverurl);
        // console.log(jsCookie.get("userinfo"));
        const searchParams = new URLSearchParams(this.props.location.search);
        const title = searchParams.get('title');
        this.setState({productName: title });
        console.log(title,'ellup', this.props.productName);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
        const data = {
            productName: this.state.productName,
            from: this.state.from,
            to: this.state.to,
            profile_id: this.state.userid,
        };
        console.log(data);
        api.addUserLiveDeal(data)
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    Notify.success("Deal added successfully!");
                    setTimeout(() => {
                        jsCookie.set("livedeal_prod", res.data);
                        socket.emit("addproduct", {
                            // TODO: Arib Hehehe
                            message: "New product!",
                        });
                        this.props.history.replace("/live");
                    }, 1000);
                } else {
                    e.preventDefault();
                    Notify.error("Error adding live deal");
                }
            })
            .catch(() => {
                Notify.error("Error adding live deal");
            });
    };
  
    render() {
           
        return (
            <Layout style={{ marginTop: "200px" }}>

            {/* Instruction Modals */}
            <InstructionModal user={{userData: this.state.userid}} livedeal={true} />


                <div className="container mt-md-4 large cart-container">
                    <div className="row flex flex-column justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-12 review-title-wrap mb-4">
                                    <h1>
                                        <Link to="/live" className="back-ico">
                                            <img src={next_arrow} alt="icon" />
                                        </Link>
                                        Create Live Deal
                                    </h1>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="custom-form">
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="productName"
                                                value={this.state.productName}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-0">
                                                Price Range
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 mb-2 mb-md-0">
                                                    <label>
                                                        <small>From</small>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="from"
                                                         
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <span
                                                                className="input-group-text"
                                                                id="basic-addon2"
                                                            >
                                                                $
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>
                                                        <small>To</small>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="to"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <span
                                                                className="input-group-text"
                                                                id="basic-addon2"
                                                            >
                                                                $
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group text-center mt-5">
                                            <PrimaryBtn
                                                block
                                                text="Add"
                                                onClick={this.handleSubmit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default AddLive;
