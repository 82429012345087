import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { FaRegBell, FaSearch } from "react-icons/fa";
import { BsCart4, BsHeart, BsChatLeftDots } from "react-icons/bs";
import { Tooltip, Badge, Avatar, Space } from "antd";
import { userUpdateProductsSearch } from "../../../store/actions/user";
import Logo from "../../../component/logo/Logo";
import search_white_ico from "../../../assets/images/search-white-ico.svg";
import { NavvLink } from "./NavLink";
import "./styles.css";
import MobileNav, { SearchModal } from "./MobileNav";
import jsCookie from "js-cookie";
import api from "../../../apis";
import io from "socket.io-client";
import { SecBtn, PrimaryBtn } from "../../../component/button/Button";
import { serverurl } from "../../../helper";

const endpoint = serverurl;

let socket;

const Header = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const searchValue = useSelector((state) => state.user.headerSearchValue);
  const dispatch = useDispatch();

  const getNotifications = (user) => {
    api
      .getUnreadNotifications({ id: user.sno, email: user.profile_id  })
      .then((res) => {
        setNotifications(res.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socket = io(endpoint);
    socket.on("updatenotificaton", (message) => {
      console.log(message);
      if (jsCookie.get("userinfo")) {
        getNotifications(JSON.parse(jsCookie.get("userinfo")));
      }
    });

    console.log("From Noititiif", jsCookie.get("userinfo"));
    if (jsCookie.get("userinfo")) {
      getNotifications(JSON.parse(jsCookie.get("userinfo")));
    }
  }, []);

  useEffect(() => {
    dispatch(userUpdateProductsSearch(""));
  }, [location, dispatch]);

  return (
    <>
      <div className="container-fluid">
        <header className="row">
          <div className="col-xl-6 col-lg-5 col-md-12 col-6 hdr-left">
            <Logo />
            <form className="search-form d-md-inline-block ">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  value={searchValue}
                  onChange={(e) =>
                    dispatch(userUpdateProductsSearch(e.target.value))
                  }
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img src={search_white_ico} alt="Icon" />
                  </span>
                </div>
              </div>
              <a href="/#" className="btn btn-close">
                Close
              </a>
            </form>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-12 col-6 hdr-right">
            <button
              className="d-md-none d-block __search_btn"
              onClick={() => setIsModalVisible(true)}
            >
              <FaSearch />
            </button>
            <nav className="navbar navbar-expand-md px-0">
              <div
                className="collapse navbar-collapse justify-content-md-start justify-content-lg-end"
                id="collapsibleNavbar"
              >
                <ul className="navbar-nav">
                  {/* Home */}
                  <li className="nav-item">
                    <NavvLink to="/" className="nav-link">
                      <Tooltip title="Home" color="#a90000" placement="bottom">
                        <IoHomeOutline />
                      </Tooltip>
                    </NavvLink>
                  </li>
                  {/* Cart */}
                  <li className="nav-item">
                    <NavvLink to="/cart" className="nav-link">
                      <Tooltip title="Cart" color="#a90000" placement="bottom">
                        <BsCart4 />
                      </Tooltip>
                    </NavvLink>
                  </li>
                  {/* Live Deal */}
                  <li className="nav-item">
                    <NavvLink to="/live" className="nav-link">
                      <Tooltip
                        title="Live Deal"
                        color="#a90000"
                        placement="bottom"
                      >
                        <BsHeart />
                      </Tooltip>
                    </NavvLink>
                  </li>
                  {/* Chat */}
                  <li className="nav-item">
                    
                    <NavvLink
                          to="/chat"
                          // className="notify-link d-none d-md-inline-block ___navlink"
                          className="nav-link ___navlink"
                      >
                          <Badge
                              count={notifications ? notifications.length : 0}
                          >
                              <BsChatLeftDots size={23} />
                          </Badge>
                    </NavvLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavvLink to="/chat" className="nav-link">
                      <Tooltip title="Chat" color="#a90000" placement="bottom">
                      <Badge count={ 2}  style={{maxWidth: "50px", textAlign:"center"}}>
                        <BsChatLeftDots size={20}  style={{maxWidth: "50px", textAlign:"center"}}/>
                      </Badge>
                      </Tooltip>
                    </NavvLink>
                  </li> */}
                  {props?.userAuth && (
                    // profile
                    <li className="nav-item">
                      <NavvLink to="/profile" className="nav-link">
                        <Tooltip
                          title="Profile"
                          color="#a90000"
                          placement="bottom"
                        >
                          <CgProfile />
                        </Tooltip>
                      </NavvLink>
                    </li>
                  )}
                </ul>
              </div>
              {props?.user?.userData?.email &&
              props?.user?.userData?.otp_verification_status &&
              props?.user?.userData?.otp_verification_status === 1 ? (
                <>
                  {/* <Link
                          to="/notification"
                          className="notify-link d-none d-md-inline-block ___navlink"
                      >
                          <Badge
                              count={2
                              }
                          >
                              <FaRegBell size={23} />
                          </Badge>
                      </Link> */}
                  <div className="dropdown profile-dd d-none d-md-inline-block">
                    <button
                      className="btn  dropdown-toggle"
                      onClick={() => {
                        props.toggleOpen(!props.user.dropdown);
                      }}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Space size={10}>
                        <Avatar
                          size={30}
                          style={{
                            backgroundColor: "#a90000",
                            verticalAlign: "middle",
                          }}
                        >
                          {props?.user?.userData?.name?.slice(0, 1) &&
                            props?.user?.userData?.name?.slice(0, 1)}
                        </Avatar>
                        {/* <div className="btn-cont text-left px-2">
                          <span>E-Wallet</span>
                          <p>$ 0</p>
                        </div> */}
                      </Space>
                    </button>
                    <div
                      className={`dropdown-menu ${
                        props?.user?.dropdown
                          ? "show dropdown-menu-right"
                          : "dropdown-menu-right"
                      }`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link to="/profile" className="dropdown-item">
                        Settings
                      </Link>
                      <button
                        onClick={props?.logoutUser}
                        className="dropdown-item text-danger"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="login-sinnupcustom-div d-none d-md-flex">
                    {/* <button
                      className="btn btn-outline mr-3"
                      // onClick={props.modalShow}
                      onClick={() => {
                        props.closeModals();
                        props.getModal(
                          "",
                          "Text",
                          null,
                          null,
                          "send_otp_modal",
                          props.getModal,
                          props.closeModals
                        );
                      }}
                    >
                      Login
                      </button> */}
                    <SecBtn
                      text="Log in"
                      onClick={() => {
                        // props.closeModals();
                        // props.getModal(
                        //     "",
                        //     "Text",
                        //     null,
                        //     null,
                        //     "send_otp_modal",
                        //     props.getModal,
                        //     props.closeModals
                        // );
                        history.push("/login");
                      }}
                    />

                    <PrimaryBtn
                      text="Sign up"
                      onClick={() => {
                        // console.log(
                        //     "clicked Login / Signup",
                        //     true
                        // );
                        // props.getModal(
                        //     "",
                        //     "Text",
                        //     null,
                        //     null,
                        //     "register_modal"
                        // );
                        history.push("/signup");
                      }}
                    />
                  </div>
                </>
              )}
            </nav>
          </div>
        </header>

        <MobileNav menuProps={props} logoutUser={props.logoutUser} />
      </div>
      <SearchModal
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default Header;
