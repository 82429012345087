import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  TOKEN_EXPIRED,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_STATUS,
  MODAL_CLOSE,
  UPDATE_SYNC_ERRORS,
  // TOGGLE_OPEN,
  MESSAGE_FROM_BACKEND,
} from "../actions/actions";
const initialstate = {
  authstatus: 0,
  authmessage: "",
  token: "",
  loading: false,
  is_show: 0,
  is_reg_show: 0,
  dropdown: 0,
};

let AuthReducer = (state = initialstate, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return Object.assign({}, state, action.payload);

    case AUTH_START:
      return Object.assign({}, state, action.payload);

    case AUTH_FAILURE:
      return Object.assign({}, state, action.payload);

    case AUTH_ERROR:
      return { ...state, authmessage: action.payload };

    case TOKEN_EXPIRED:
      return { ...state, authmessage: action.payload };

    case AUTH_STATUS:
      return { ...state, authstatus: action.payload };

    case AUTH_LOGOUT:
      return { ...state, authmessage: action.payload };

    case MODAL_CLOSE:
      return { ...state, is_show: action.payload };

    // case TOGGLE_OPEN:
    //   return { ...state, dropdown: action.payload };

    case MESSAGE_FROM_BACKEND:
      return { ...state, authmessage: action.payload };

    case UPDATE_SYNC_ERRORS:
      return { ...state, action };

    default:
      return { ...state };
  }
};
export default AuthReducer;
