import { put, takeLatest } from 'redux-saga/effects';
// import cogoToast from "cogo-toast";
import Notify from 'cogo-toast';
import * as Types from '../Types';
import * as actions from '../actions/product';

import { checkoutApi, productsListApi, singleProductApi } from '../apis/index';

// const token = getImTokenFromBrowserCookie();
//Api Calls

// Generators

function* productsList(action) {
  const loader = Notify.loading('Please wait!');
  let res;
  try {
    res = yield productsListApi(action.body);
    let filteredProducts = res.data.data.filter((item) => {
      return item.sno;
    });
    yield put(
      actions.getAllProductsActionSuccess(
        res.data && res.data.data && res.data.data.length ? res.data.data : []
      )
    );
    loader.hide();
    // Notify.success("Products Loading Success");
    if (action.cb) {
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(actions.getAllProductsActionFailed(error.response.data.error));
  }
}

function* singleProductDetails(action) {
  console.log('singleProductApi generator action >> ', action);
  const loader = Notify.loading('Please wait!');
  let res;
  try {
    res = yield singleProductApi(action.body);
    console.log('Response singleProductApi >>', res);
    yield put(actions.getSingleProductActionSuccess(res.data));
    loader.hide();
    // Notify.success("Product Loading Success");
    if (action.cb) {
      console.log('callback singleProductApi works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(actions.getSingleProductActionFailed(error.response.data.error));
  }
}

function* productPaypalPayment(action) {
  console.log('paypal payment generator action >> ', action);
  const loader = Notify.loading('Please wait!');

  let res;
  try {
    res = yield checkoutApi(action.body);
    console.log('Response paypal payment Api >>', res);
    yield put(actions.paypalPaymentActionRequest(res.data));
    loader.hide();
    // Notify.success("Product Loading Success");
    if (action.cb) {
      console.log('callback paypal payment Api works!');
      action.cb(res);
    }
  } catch (error) {
    loader.hide();
    Notify.error(error.response.data.error);
    yield put(actions.paypalPaymentActionFailed(error.response.data.error));
  }
}

function* watchProduct() {
  yield takeLatest(Types.GET_ALL_PRODUCTS_REQUEST, productsList);
  yield takeLatest(Types.GET_SINGLE_PRODUCT_REQUEST, singleProductDetails);
  yield takeLatest(Types.GET_SINGLE_PRODUCT_REQUEST, singleProductDetails);
  // yield takeLatest(PAYPAL_PAYMENT, productPaypalPayment);
}

export default watchProduct;
