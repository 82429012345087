
import { Button } from "antd";
import product_img1 from "./../../../assets/images/product-img1.png";
import { Link } from "react-router-dom";

export default function SingleReview({avatar, product, orderdate, comment, star_icon, review}) {
  
  const formatDate = (num) => {
    return num < 10 ? "0" + num : num;
  };
  
  const formatHours = (num) => {
    return num < 10 ? "0" + num : num < 12 ? num : num - 12;
  };

  return (
    <div className="col-md-5 mb-4">
      <div className="review-snippet myreviews">
        <h5>Ordered on {
          formatDate(new Date(orderdate).getDate()) + "/" +
          formatDate(new Date(orderdate).getMonth() + 1) + "/" +
          formatDate(new Date(orderdate).getFullYear())
        } </h5>
        <div className="review-snip-title">
          <div className="avatar-wrap">
            <div className="prod-img">
              <img className="img-fluid" src={avatar || product_img1} alt="product" />
            </div>
            <div>
              <p className="pname">{product.name}</p>
              {/* <p className="pdesc">Some description goes here on a fiathful!</p> */}
              <p className="date">x{product.quantity}</p>
            </div>
          </div>
          <span>${product.price}</span>
        </div>

        <div className="review-details">
          <div className="d-flex">
            <p className="recieved_date_p">Item received on {
          formatDate(new Date(orderdate).getDate()) + "/" +
          formatDate(new Date(orderdate).getMonth() + 1) + "/" +
          formatDate(new Date(orderdate).getFullYear())
        } 
            </p>
            <p>{
          formatDate(new Date(orderdate).getDate()) + "-" +
          formatDate(new Date(orderdate).getMonth() + 1) + "-" +
          formatDate(new Date(orderdate).getFullYear()) +" " +

          formatHours(new Date(orderdate).getHours()) + ":" + formatDate(new Date(orderdate).getMinutes()) + " " + (new Date(orderdate).getHours() > 12 ? "PM" : "AM")
        } </p>
          </div>
          <div className="d-flex">
            <p>
              {
                [...Array(review.rating)].map(r => <img src={star_icon} alt="ico" />)
              }
              
              {/* <img src={star_icon} alt="ico" />
              <img src={star_icon} alt="ico" />
              <img src={star_icon} alt="ico" /> */}
            </p>
            <p>{
          formatDate(new Date(review.date).getDate()) + "/" +
          formatDate(new Date(review.date).getMonth() + 1) + "/" +
          formatDate(new Date(review.date).getFullYear())
          }</p>
          </div>
          <div>
            <p className="review_comment">
            {review.comment}
            </p>
          </div>
          <p className="text-center">
            <Link to={"/product-detail/"+ product.id} className="review_btn">View Product</Link>
          </p>
        </div>

      </div>
    </div>
  );
}





{/* <div className="col-md-6 mb-4">
<div className="review-snippet">
  <div className="review-snip-title">
    <div className="avatar-wrap">
      <div className="avatar-img">
        <img className="img-fluid" src={avatar} alt="ico" />
      </div>
      <div>
        <p>{customer_name}</p>
        <div className="star-wrap">
          {
            [...Array(rating)].map(r => {
              return <img src={star_icon} alt="ico" />
            })
          }
        </div>
      </div>
    </div>
    <span className="date">{date}</span>
  </div>
  <p className="comment">
    {comment}
  </p>
</div>
</div> */}
