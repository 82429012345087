import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Notify from 'cogo-toast';
// import {
//   getAllProductsActionRequest,
//   // getSingleProductActionRequest,
// } from '../../../store/actions/product';
import defaultImg from '../../../assets/images/default.png';
import Layout from '../Layouts/PageLayouts';
// import { Tooltip } from 'antd';
import { Button, Modal } from 'antd';
import InstructionModal from '../Layouts/Modals';
import axios from 'axios';
import Web_Apis from '../../../apis';
import { useSelector } from 'react-redux';
import { serverurl } from '../../../helper';

const ProductsList = (props) => {
  const [updateProducts, setUpdateProducts] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [swapping, setSwapping] = useState(false);
  const [details, setDetails] = useState({});
  const [cartProduct, setCartProduct] = useState([]);
  const [triggerUpdateModalOpen, setTriggerUpdateModalOpen] = useState(false);
  const { userData, userCart } = useSelector((state) => state?.user);
  const history = useHistory();


  useEffect(() => {
    let requestBody = {
      profile_id: userData?.email,
    };
    const fetch = async () => {
      await axios
        .post(`${serverurl}/api/cart/cartlookup`,
          requestBody
        )
        .then((response) => {
          setUpdateProducts(response?.data?.data);
          setLoading(false);
        })
        .catch((error) => setError(error));
    };
    fetch();
    // console.log(localStorage.session, 'update');
  }, []);

  console.log(details, 'update', cartProduct);

  const swapProduct = async (id) => {
    setSwapping(true)
    // setDetails({ ...details, data });
    // const productFromCart = userCart?.filter(
    //   (item) => item?.product_name === details?.product_name
    // );
    // setCartProduct(productFromCart);
    console.log('productFromCart', cartProduct);

    const body = {
      sno: id,
      profile_id: userData?.email,
      product_quantity: 1,
      product_price: details?.product_price,
      product_name: details?.product_name,
      product_photo: details?.product_photo,
      shipping_address: userData?.address,
      old_sno: cartProduct[0]?.sno,
      old_price: cartProduct[0]?.product_price,
    };

    // console.log(body, 'oooooo');
    await axios
      .post(`${serverurl}/api/cart/replacecartitem`,
        body
      )
      .then((response) => {
        Notify.success('Success');
        setTriggerUpdateModalOpen(false);
        setSwapping(false);
        // window.location.reload();
        history.push("/cart");
      })
      .catch((error) => {Notify.error('Sorry, an error occured.');   setSwapping(false);});
  };

  // handle card swap button
  const handleCardClick = (data) => {
    setDetails(data);
    setTriggerUpdateModalOpen(true);
    const productFromCart = userCart?.filter(
      (item) => item?.product_name === data?.product_name
    );
    setCartProduct(productFromCart);
  };

  // console.log(details, 'update');
  const filteredProduct = (updateProducts ?? [])?.filter(({ product_name }) =>
    new RegExp(
      (props.searchValue ?? '')
        .replace(/\s*/g, '')
        .replace(/[\\[.+*?(){|^$]/g, '\\$&'),
      'i'
    ).test(product_name?.replace(/\s*/g, ''))
  );

  if (error) {
    return (
      <div className='my-4'>
        <h5>Sorry!!! An error occured. {error}</h5>
      </div>
    );
  }
  if (loading) {
    return (
      <>
        <div className='my-4 mx-2'>
          <h5>Loading products....</h5>
        </div>
      </>
    );
  }

  return (
    <Layout className='mt-5'>
      {/* Instruction Modals */}
      <InstructionModal user={props.user} />

      {/* are sure modal */}
      <Modal
        // className={props.className}
        title={'Product Update'}
        visible={triggerUpdateModalOpen}
        open={triggerUpdateModalOpen}
        okText={props.okText}
        onCancel={() => setTriggerUpdateModalOpen(false)}
        width={'50%'}
        footer={null}
      >
        <div className='productsList-modal'>
          <h4>
            {'Are you sure you want to swap'}
            {/* <span className='check'  onClick={() => swapProduct()}>Check product</span> */}
          </h4>

          <div>
            {cartProduct?.map((data) => {
              // console.log("Env ==>", process.env);
              // REACT_APP_API_BASE_URL
              let newImagePath = process?.env
                ?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
                ? data?.product_photo.replace(
                    `http://eamr.life/exhibition/uploads/`,
                    `${process.env.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
                  )
                : data?.product_photo;

              return (
                <>
                  <div className='px-2 mb-md-4 mb-3 mt-2' key={data?.sno}>
                    <div className='product-snippe modaldisplay'>
                      <div className=' pimg'>
                        <img
                          className='img-fluid'
                          src={newImagePath || defaultImg}
                          alt='Product Name'
                        />
                      </div>

                      <div className='swap_pricee'>
                        <h5>{data?.product_name}</h5>
                        <button className='swap' onClick={() => swapProduct(data?.sno)}>
                          {/* {swapping? "loading":  "swap"} */}swap
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className='buttons-list'>
            {!triggerUpdateModalOpen && (
              <Button
                type='primary'
                size={'medium'}
                onClick={() => swapProduct()}
              >
                Swap
              </Button>
            )}

            <Button
              type='primary'
              danger
              onClick={() => setTriggerUpdateModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <div className='container large'>
        <h5
          className='col-12 mb-0 mt-md-4 text-center'
          style={{
            fontSize: '1.3rem',
            borderBottom: '1px solid #eee',
            padding: '1rem',
            textTransform: 'uppercase',
          }}
        >
          <h5 className='pr-3 uppercase'>Products with similar prices</h5>
          {/* <span className='pr-3 uppercase block'>From cart</span> */}
        </h5>
        {updateProducts < 1 && (
          <div className='my-4'>
            <h5>No product found</h5>
          </div>
        )}

        <div className='row product-encl'>
          {filteredProduct &&
            filteredProduct?.length > 0 &&
            filteredProduct?.map((data) => {
              // console.log("Env ==>", process.env);
              // REACT_APP_API_BASE_URL
              let newImagePath = process?.env
                ?.REACT_APP_PRODUCTIMAGE_NEWBASEPATH
                ? data.product_photo.replace(
                    `http://eamr.life/exhibition/uploads/`,
                    `${process.env.REACT_APP_PRODUCTIMAGE_NEWBASEPATH}`
                  )
                : data.product_photo;

              return (
                <>
                  <div
                    className='col-lg-2 col-md-3 col-6 px-2 mb-md-4 mb-3 mt-2'
                    key={data?.sno}
                  >
                    <div className='product-snippet'>
                      <Link to={`/product-detail/${data?.sno}`}>
                        <div className='p-img'>
                          <img
                            className='img-fluid'
                            src={newImagePath || defaultImg}
                            alt='Product Name'
                          />
                        </div>
                      </Link>
                      <div className='p-cont'>
                        <Link
                          to={`/product-detail/${data?.sno}`}
                          className='linkRemove'
                        >
                          <h5>{data?.product_name}</h5>
                          <p>{data?.description}</p>
                        </Link>
                        <div className='swap_price'>
                          <span className='price ' data-title='$'>
                            {data?.product_price}
                          </span>
                          <button
                            className='swap'
                            onClick={() => handleCardClick(data)}
                          >
                            swap
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  searchValue: state.user.headerSearchValue,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProducts: (id) => {
      dispatch(Web_Apis.productsUpdate(id));
    },
  };
};

const productsList = connect(mapStateToProps, mapDispatchToProps)(ProductsList);

export default productsList;
