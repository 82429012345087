import product_img1 from "../assets/images/product-img1.png";
import product_img2 from "../assets/images/product-img2.png";
import product_img3 from "../assets/images/product-img3.png";
import product_img4 from "../assets/images/product-img4.png";
import product_img5 from "../assets/images/product-img5.png";
import product_img6 from "../assets/images/product-img6.png";

const ordersData = [
  {
    orderId: "2435453454534",
    title: "My Protein Impact Whey Protein",
    date:"20/04/2021",
    customer:"Customer 1",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img1,
  },
  {
    orderId: "2435453454534",
    title: "Sony WF-1000XM3 Wireless Bluetooth",
    date:"28/04/2021",
    customer:"Customer 2",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img2,
  },
  {
    orderId: "2435453454534",
    title: "Under Armour UA Men's Seamless Wave..",
    date:"03/05/2021",
    customer:"Customer 3",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img3,
  },
  {
    orderId: "2435453454543",
    title: "Sapporo Premium Can Beer 330ml x 24cans",
    date:"16/06/2021",
    customer:"Customer 4",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img4,
  },
  {
    orderId: "24354534545342",
    title: "Pillowcase Special Embroidered Geometric..",
    date:"10/07/2021",
    customer:"Customer 5",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img5,
  },
  {
    orderId: "24354534544535",
    title: "Fresh Tangerine Blueberry",
    date:"03/08/2021",
    customer:"Customer 6",
    orderStatus:"Pending",
    description: "Item description. Lorem ipsum dolor sit amet consectetur",
    price: "$ 0.00",
    imageUrl: product_img6,
  },
];

export default ordersData;
