export const TOGGLE_MODE = "TOGGLE_MODE";
export const TOGGLE_MODE_APP = "TOGGLE_MODE_APP";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILED = "GET_ALL_PRODUCTS_FAILED";
export const GET_SINGLE_PRODUCT_REQUEST = "GET_SINGLE_PRODUCT_REQUEST";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_FAILED = "GET_SINGLE_PRODUCT_FAILED";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const TOGGLE_OPEN = "TOGGLE_OPEN";
export const LOGOUT = "LOGOUT";
export const VIEW_CART_REQUEST = "VIEW_CART_REQUEST";
export const VIEW_CART_SUCCESS = "VIEW_CART_SUCCESS";
export const VIEW_CART_FAILED = "VIEW_CART_FAILED";
export const ADD_CART_REQUEST = "ADD_CART_REQUEST";
export const PAYPAL_PAYMENT = "PAYPAL_PAYMENT";
export const PAYPAL_PAYMENT_FAILED = "PAYPAL_PAYMENT_FAILED";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAILED = "ADD_CART_FAILED";
export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILED = "DELETE_CART_FAILED";
export const DELETE_CART_ITEM_REQUEST = "DELETE_CART_ITEM_REQUEST";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAILED = "DELETE_CART_ITEM_FAILED";
export const INCREMENT_CART_REQUEST = "INCREMENT_CART_REQUEST";
export const INCREMENT_CART_SUCCESS = "INCREMENT_CART_SUCCESS";
export const INCREMENT_CART_FAILED = "INCREMENT_CART_FAILED";
export const DECREMENT_CART_REQUEST = "DECREMENT_CART_REQUEST";
export const DECREMENT_CART_SUCCESS = "DECREMENT_CART_SUCCESS";
export const DECREMENT_CART_FAILED = "DECREMENT_CART_FAILED";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILED = "USER_PROFILE_FAILED";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const ADD_REVIEW_REQUEST = "ADD_REVIEW_REQUEST";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAILED = "ADD_REVIEW_FAILED";
export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAILED = "GET_REVIEW_FAILED";
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILED = "CHECKOUT_FAILED";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED";
export const USER_ORDERS_REQUEST = "USER_ORDERS_REQUEST";
export const USER_ORDERS_SUCCESS = "USER_ORDERS_SUCCESS";
export const USER_ORDERS_FAILED = "USER_ORDERS_FAILED";
export const USER_UDPATE_HEADER_SEARCH_VALUE =
    "USER_UDPATE_HEADER_SEARCH_VALUE";
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAILED = "ORDER_DETAIL_FAILED";
//Deals
export const ADD_DEAL_REQUEST = "ADD_DEAL_REQUEST";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_FAILED = "ADD_DEAL_FAILED";
export const VIEW_DEAL_REQUEST = "VIEW_DEAL_REQUEST";
export const VIEW_DEAL_SUCCESS = "VIEW_DEAL_SUCCESS";
export const VIEW_DEAL_FAILED = "VIEW_DEAL_FAILED";
export const DELETE_DEAL_REQUEST = "DELETE_DEAL_REQUEST";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_FAILED = "DELETE_DEAL_FAILED";
// Wishlist
export const ADD_WISHLIST_REQUEST = "ADD_WISHLIST_REQUEST";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_FAILED = "ADD_WISHLIST_FAILED";
export const VIEW_WISHLIST_REQUEST = "VIEW_WISHLIST_REQUEST";
export const VIEW_WISHLIST_SUCCESS = "VIEW_WISHLIST_SUCCESS";
export const VIEW_WISHLIST_FAILED = "VIEW_WISHLIST_FAILED";
export const DELETE_WISHLIST_REQUEST = "DELETE_WISHLIST_REQUEST";
export const DELETE_WISHLIST_SUCCESS = "DELETE_WISHLIST_SUCCESS";
export const DELETE_WISHLIST_FAILED = "DELETE_WISHLIST_FAILED";
