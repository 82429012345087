import jsCookie from "js-cookie";

export const setImTokenInBrowserCookie = (token, expire) => {
  if (expire) {
    jsCookie.set("ImToken", token, {
      expires: expire,
    });
  } else {
    jsCookie.set("ImToken", token);
  }
};

export const getImTokenFromBrowserCookie = () => {
  return jsCookie.get("ImToken");
};

export const removeImTokenFromBrowserCookie = () => {
  jsCookie.remove("ImToken");
};

