import { Link } from "react-router-dom";
import { Layout } from "antd";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const { Footer } = Layout;

export default function PageFooter({ className, style }) {
    return (
        <Footer className={`px-4 mx-0 ${className}`} style={style}>
            <div className=" d-flex flex-column justify-center align-items-center mt-3 pb-5 pb-md-0">
                <div className="__ftwrap text-center">
                    <Link to="/" className="__ftlink">
                        Home
                    </Link>
                    <Link to="/terms" className="__ftlink">
                        Terms of use
                    </Link>
                    {/* <Link to="/privacy-policy" className="__ftlink">
                        Privacy policy
                    </Link> */}
                    {/* <Link to="/cookie-policy" className="__ftlink">
                        Cookie policy
                    </Link> */}
                </div>
                <div className="__ftsocials">
                    <a
                        href="https://"
                        className="__ftsocial __facebook_icon"
                        title="Follow us on Facebook"
                    >
                        <FaFacebookF />
                    </a>
                    <a
                        href="https://"
                        className="__ftsocial __twitter_icon"
                        title="Follow us on Twitter"
                    >
                        <FaTwitter />
                    </a>
                    <a
                        href="https://"
                        className="__ftsocial __instagram_icon"
                        title="Follow us on Instagram"
                    >
                        <FaInstagram />
                    </a>
                </div>
                <p className="__ftcopy mt-md-3 text-center">
                    Copyright © {new Date().getFullYear()} - All right reserved.
                </p>
            </div>
        </Footer>
    );
}
