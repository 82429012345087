import { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import cogoToast from "cogo-toast";
import { sendOtpActionRequest } from "../../store/actions/user";
import { openModal, closeModal } from "../../store/actions/Modals";
import { sendOtpValidation } from "../../configurations/Schemas";
import close_dark from "./../../assets/images/close-dark-ico.svg";
import next_arrow from "./../../assets/images/next-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import jsCookie from "js-cookie";
import { PhoneNumberInput } from "../../component/generic";

const SendOtpForm = (props) => {
    // console.log("SendOtpForm Component Props >>", props);

    const [phone, setPhone] = useState("");
    const [phoneData, setPhoneData] = useState({});

    const callApi = (formValues) => {
        console.log("LOGIN FORM CALL");
        // console.log("call api formValues >>", formValues);
        let deviceToken = uuidv4();
        if (!jsCookie.get("ImToken")) {
            jsCookie.set("ImToken", deviceToken);
        }
        let tempphonenumber = formValues.phone.replace(
            `${phoneData.dialCode}`,
            ""
        );
        let requestBody = {
            dialing_code: phoneData.dialCode ? `+${phoneData.dialCode}` : "",
            phonenumber: tempphonenumber,
            // device_type: 0,
            // device_token: deviceToken,
        };
        console.log("sendOtpApiCall requestBody >>", requestBody);
        props.sendOtpApiCall(requestBody, () => {
            jsCookie.set("OTPNumber", phone);
            jsCookie.set(
                "OTPDialCode",
                phoneData.dialCode ? phoneData.dialCode : ""
            );
            jsCookie.set("OTPTempNumber", tempphonenumber);
            props.closeModals();
            props.getModal(
                "",
                "Text",
                null,
                null,
                "verify_otp_modal",
                props.getModal,
                props.closeModals
            );
        });
    };
    return (
        <>
            <Formik
                initialValues={{
                    phone,
                }}
                validateOnBlur
                validateOnChange
                validationSchema={sendOtpValidation}
                enableReinitialize={true}
                onSubmit={(values) => {
                    // console.log("Form Values", values);
                    callApi(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    validateForm,
                    setFieldTouched,
                }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            {console.log("form errors", errors)}

                            <div className="row justify-content-between">
                                <div className="col">
                                    <Form.Group
                                        controlId="exampleForm.ControlInput1"
                                        className={`m-0 mb-1 phone_input`}
                                    >
                                        {/* <Form.Label>Phone</Form.Label> */}
                                        <PhoneNumberInput
                                            inputStyle={{
                                                backgroundColor:
                                                    "rgb(255 255 255)",
                                                color: `black`,
                                            }}
                                            buttonStyle={{
                                                backgroundColor:
                                                    "rgb(255 255 255)",
                                            }}
                                            // disabled={inputsDisabled()}
                                            value={phone}
                                            changeHandler={(
                                                changeValue,
                                                country,
                                                e,
                                                formattedValue
                                            ) => {
                                                // console.log(
                                                //   "PhoneNumberInput changeValue >>",
                                                //   changeValue
                                                // );
                                                // console.log("PhoneNumberInput country >>", country);
                                                // console.log(
                                                //   "PhoneNumberInput formattedValue >>",
                                                //   formattedValue
                                                // );
                                                setPhone(changeValue);
                                                setPhoneData(country);
                                                // setFieldValue("phone", changeValue);
                                            }}
                                            placeholder="Enter your telephone number*"
                                        />
                                        {errors.phone && touched.phone ? (
                                            <span className="form-error-span">
                                                {errors.phone}
                                            </span>
                                        ) : null}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="form-group mt-5 mb-0">
                                <button
                                    className="btn btn-circle-next"
                                    onClick={() => {
                                        // console.log("clicked Login / Signup", true);
                                        props.closeModals();
                                        props.getModal(
                                            "",
                                            "Text",
                                            null,
                                            null,
                                            "register_modal",
                                            props.getModal,
                                            props.closeModals
                                        );
                                    }}
                                >
                                    Don't have an account!
                                </button>
                            </div>
                            <div className="form-group mt-5 mb-0">
                                {/* <Button
                  onClick={() => {
                    props.closeModals();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Cancel
                </Button> */}
                                <Button
                                    disabled={props.modalBtnLoader}
                                    type="submit"
                                    className="btn btn-circle-next"
                                >
                                    {props.modalBtnLoader ? (
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                        />
                                    ) : (
                                        <img src={next_arrow} alt="icon" />
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getModal: (...args) => {
            dispatch(openModal(...args));
        },
        closeModals: () => {
            dispatch(closeModal());
        },
        sendOtpApiCall: (...args) => {
            dispatch(sendOtpActionRequest(...args));
        },
    };
};

export default connect(null, mapDispatchToProps)(SendOtpForm);
