import React, { Suspense } from "react";
import Router from "./router";

const AppRoute = (props) => {
    return (
        <div>
            <Suspense fallback={<p>Loading</p>}>
                <Router />
            </Suspense>
        </div>
    );
};

export default AppRoute;
