import React from "react";
import { connect } from "react-redux";
import Modal from "../../../store/actions/modal";
import Header from "../Layouts/Header";
import { toggleOpen, logoutUser } from "../../../store/actions/user";
import { openModal, closeModal } from "../../../store/actions/Modals";

const Home = (props) => {
  
		console.log("Home Props >>", props);

  return (
    <>
      <Header
        userAuth={props.userAuth}
        user={props.store.user}
        toggleOpen={props.toggleOpen}
        dropdown={props.store.auth.dropdown}
        authmessage={props.authmessage}
        getModal={props.getModal}
        closeModals={props.closeModals}
        logoutUser={props.logoutUser}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  store: state,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    modalClose: () => dispatch(Modal.ModalAction(0)),
    modalShow: () => dispatch(Modal.ModalAction(1)),
    toggleOpen: (status) => dispatch(toggleOpen(status)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

const home = connect(mapStateToProps, mapDispatchToProps)(Home);
export default home;
