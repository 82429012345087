import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import api from "../../../apis";
import jsCookie from "js-cookie";
import Notify from "cogo-toast";
import { DangerBtn, PrimaryBtn } from "../../../component/button/Button";
import Layout from "../Layouts/PageLayouts";

class UpdateLive extends Component {
  constructor(props) {
    super(props);
    console.log("UPDATE LOCATION STATE", props.location.state);
    this.state = {
      id: props?.location?.state?.id ?? "",
      name: props?.location?.state?.deal_name ?? "",
      max_value: props?.location?.state?.max_value ?? "",
      min_value: props?.location?.state?.min_value ?? "",
      userid: null,
    };
  }

  componentDidMount() {
    const userid = jsCookie.get("userinfo")
      ? JSON.parse(jsCookie.get("userinfo")).sno
      : null;
    if (!userid) {
      this.state.history.replace("/");
    } else {
      this.setState({ userid });
    }

    // const id = this.props.location.state
    //   ? this.props.location.state.deal_id
    //   : null;
    // if (id) {
    //   api
    //     .liveDealDetails({ userid: userid, deal_id: id })
    //     .then((res) => {
    //       const deal = res.data[0];
    //       console.log("DEAL ID", id);
    //       this.setState({
    //         name: deal.deal_name,
    //         max_value: deal.max_value,
    //         min_value: deal.min_value,
    //         id,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
    // console.log(id);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { id, name, max_value, min_value, userid } = this.state;
    api
      .updateDeal({
        userid,
        deal_name: name,
        max_value,
        min_value,
        deal_id: id,
      })
      .then((res) => {
        if (res.success) {
          Notify.success("Live deal updated!");
          setTimeout(() => {
            this.props.history.goBack();
          }, 1000);
        }
      })
      .catch((err) => {
        Notify.error(err);
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <Layout style={{ marginTop: "150px" }}>
          <div className="container large cart-container">
            <div className="row flex flex-column justify-content-center align-items-center">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-12 review-title-wrap mb-4">
                    <h1>
                      <div
                        onClick={() => this.props.history.goBack()}
                        className="back-ico"
                      >
                        <img src={next_arrow} alt="icon" />
                      </div>
                      Update Live Deal
                    </h1>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="custom-form">
                      <div className="form-group">
                        <label>Product Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={this.state.name ? this.state.name : ""}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-0">Price Range</label>
                        <div className="row">
                          <div className="col-md-6 mb-2 mb-md-0">
                            <label>
                              <small>From</small>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="min_value"
                                value={this.state.min_value}
                                onChange={this.handleChange}
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>
                              <small>To</small>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="max_value"
                                value={this.state.max_value}
                                onChange={this.handleChange}
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mt-5">
                        <div className="col-md-12">
                          <PrimaryBtn
                            text="Update"
                            block
                            onClick={(e) => {
                              console.log("CLICKED HERE");
                              this.handleSubmit(e);
                            }}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withRouter(UpdateLive);
