import deepCopy from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import { v4 as uuidv4 } from "uuid";
import jsCookie from "js-cookie";
import { signupValidations } from "../../../configurations/Schemas";

export const fieldsName = {
    name: "name",
    email: "email",
    phone: "phone",
    dateOfBirth: "dateOfBirth",
    address: "address",
    gender: "gender",
    refCode: "refCode"
};

export const handleInputChange = (
    state,
    handler,
    field,
    { propToValidate, update }
) => {
    const newState = deepCopy(merge(state, update));
    validateChange(newState, propToValidate, field, (addons) => {
        handler(merge(newState, addons));
    });
};

export const handleSubmit = (state, fn, handler) => {
    const reqBody = createReqBody(state, fn);
    console.log("registerApiCall requestBody >>", reqBody);
    if (!jsCookie.get("ImToken")) {
        jsCookie.set("ImToken", reqBody.device_token);
    }
    handler(reqBody);
};

const validateChange = (state, propToValidate, field, cb) => {
    const addons = createAddons();
    console.log("TO VALIDATE", field, get(state, propToValidate));
    signupValidations[field]
        ?.validate({ [field]: get(state, propToValidate) })
        .catch(({ errors }) => (addons.errMsg = errors[0]))
        .then(() => cb({ [field]: addons })) ?? cb({ [field]: addons });
};

const createAddons = () => ({
    errMsg: null,
});

const createReqBody = (state, fn) => {
    console.log("STATE TO BE CREATED", state);
    const dialCode = getStrOrDefault(state, `${fn.phone}.phoneData.dialCode`);
    return {
        name: getStrOrDefault(state, `${fn.name}.value`),
        dialing_code: dialCode,
        phonenumber: getStrOrDefault(state, `${fn.phone}.value`).replace(
            dialCode,
            ""
        ),
        email: getStrOrDefault(state, `${fn.email}.value`),
        dob: getStrOrDefault(state, `${fn.dateOfBirth}.value`),
        gender: getStrOrDefault(state, `${fn.gender}.value`),
        address: getStrOrDefault(state, `${fn.address}.value`),
        refCode: getStrOrDefault(state, `${fn.refCode}.value`),
        type: 3,
        device_type: 0,
        device_token: uuidv4(),
    };
};

const getStrOrDefault = (state, key) => get(state, key) ?? "";
