
export default function SingleReview({avatar, customer_name, date, comment, star_icon, rating}) {
  return (
    <div className="col-md-6 mb-4">
      <div className="review-snippet">
        <div className="review-snip-title">
          <div className="avatar-wrap">
            <div className="avatar-img">
              <img className="img-fluid" src={avatar} alt="ico" />
            </div>
            <div>
              <p>{customer_name}</p>
              <div className="star-wrap">
                {
                  [...Array(rating)].map(r => {
                    return <img src={star_icon} alt="ico" />
                  })
                }
                
                {/* <img src={star_icon} alt="ico" />
                <img src={star_icon} alt="ico" />
                <img src={star_icon} alt="ico" />
                <img src={star_icon} alt="ico" /> */}
              </div>
            </div>
          </div>
          <span className="date">{date}</span>
        </div>
        <p className="comment">
          {comment}
        </p>
      </div>
    </div>
  );
}
