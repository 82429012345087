import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import cogoToast from "cogo-toast";
import {
    verifyOtpActionRequest,
    sendOtpActionRequest,
} from "../../store/actions/user";
import { openModal, closeModal } from "../../store/actions/Modals";
import { verifyOtpValidation } from "../../configurations/Schemas";
import close_dark from "./../../assets/images/close-dark-ico.svg";
import next_arrow from "./../../assets/images/next-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import jsCookie from "js-cookie";

const VerifyOtpForm = (props) => {
    console.log("VerifyOtpForm Component Props >>", props);

    const [code, setCode] = useState("");
    const history = useHistory();

    const resendCodeMethod = () => {
        if (jsCookie.get("OTPDialCode") && jsCookie.get("OTPTempNumber")) {
            let requestBody = {
                dialing_code: jsCookie.get("OTPDialCode"),
                phonenumber: jsCookie.get("OTPTempNumber"),
                // device_type: 0,
                // device_token: deviceToken,
            };
            console.log("resendCodeMethod requestBody >>", requestBody);
            props.sendOtpApiCall(requestBody, () => {
                console.log("resendCodeMethod callback >>", true);
                jsCookie.remove("OTPTempNumber");
                jsCookie.remove("OTPDialCode");
            });
        } else {
            cogoToast.error("Number not Valid!");
        }
    };
    const callApi = (formValues) => {
        console.log("call api VerifyOtpForm ==>>", formValues);
        let deviceToken = uuidv4();
        if (!jsCookie.get("ImToken")) {
            jsCookie.set("ImToken", deviceToken);
        }

        let requestBody = {
            otp: formValues.code ? formValues.code : "",
            dialing_code: "+" + jsCookie.get("OTPDialCode"),
            phonenumber: jsCookie.get("OTPTempNumber"),
            // device_type: 0,
            // device_token: deviceToken,
        };
        console.log("verifyOtpApiCall requestBody >>", requestBody);
        props.verifyOtpApiCall(requestBody, () => {
            props.closeModals();
            jsCookie.remove("OTPNumber");
            history.push("/");
            console.log(
                "success verify otp call back from VerifyOptForm",
                true
            );
        });
    };
    return (
        <>
            <Formik
                initialValues={{
                    code,
                }}
                validateOnBlur
                validateOnChange
                validationSchema={verifyOtpValidation}
                enableReinitialize={true}
                onSubmit={(values) => {
                    console.log("Form Values", values);
                    callApi(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    validateForm,
                    setFieldTouched,
                }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            {console.log("form errors", errors)}

                            <div className="row justify-content-between">
                                <div className="col">
                                    <Form.Group>
                                        <Form.Label>
                                            Enter 4-digit code sent to you at +
                                            {jsCookie.get("OTPNumber")
                                                ? jsCookie.get("OTPNumber")
                                                : "N/A"}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            value={values.code}
                                            isInvalid={
                                                errors.code && touched.code
                                            }
                                            placeholder="OTP Code"
                                            onChange={(e) => {
                                                handleChange(e);
                                                // setFieldValue("name", e.value);
                                                setCode(e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                        />
                                        {errors.code && touched.code ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.code}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="form-group mt-5 mb-0">
                                <button
                                    className="btn btn-circle-next"
                                    onClick={() => {
                                        console.log("Resent OTP Clicked", true);
                                        resendCodeMethod();
                                    }}
                                >
                                    Resend Code
                                </button>
                            </div>
                            <div className="form-group mt-5 mb-0">
                                {/* <Button
                  onClick={() => {
                    props.closeModals();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Cancel
                </Button> */}
                                <Button
                                    disabled={props.modalBtnLoader}
                                    type="submit"
                                    className="btn btn-circle-next"
                                >
                                    {props.modalBtnLoader ? (
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                        />
                                    ) : (
                                        <img src={next_arrow} alt="icon" />
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getModal: (...args) => {
            dispatch(openModal(...args));
        },
        closeModals: () => {
            dispatch(closeModal());
        },
        verifyOtpApiCall: (...args) => {
            dispatch(verifyOtpActionRequest(...args));
        },
        sendOtpApiCall: (...args) => {
            dispatch(sendOtpActionRequest(...args));
        },
    };
};

export default connect(null, mapDispatchToProps)(VerifyOtpForm);
