import React from "react";
import "./style.css";

const CancelOrder = (props) => {
  return (
    <div className="co__modal-container">
      <div className="co__modal">
        <p className="co__modal-title">Cancel Order</p>
        <p className="co__modal-content">
          Do you want to cancel this order? <br />
          (Order can only be cancelled if merchant has not sent the product)
        </p>
        <div className="co__modal-footer">
          <button className="co__modal-btn-cancel" onClick={props.cancelOrder}>Cancel</button>
          <button className="co__modal-btn-back" onClick={props.closeModal}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default CancelOrder;
