import React from 'react';
import { Menu } from 'antd';

const FilterMenu = ({handleClick}) => {
  return (
    <Menu style={{padding: "0 8px"}}>
    <Menu.Item key="1" onClick={() => handleClick('All')}>
      All
    </Menu.Item>
    <Menu.Item key="2" onClick={() => handleClick('To Receive')}>
      To Receive
    </Menu.Item>
    <Menu.Item key="3" onClick={() => handleClick('Completed')}>
      Completed
    </Menu.Item>
    <Menu.Item key="4" onClick={() => handleClick('Rejected')}>
      Rejected
    </Menu.Item>
    <Menu.Item key="5" onClick={() => handleClick('Cancelled')}>
      Cancelled
    </Menu.Item>
  </Menu>
)
}

export default FilterMenu