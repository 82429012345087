import { Empty } from "antd";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import "../Layouts/styles.css";
import { PrimaryBtn } from "../../../component/button/Button";
import empty from "../../../assets/images/empty.svg";

const { Title } = Typography;

export default function NoResult({
    className,
    desc,
    text,
    children,
    link,
    _chat,
}) {
    return (
        <div className={`${className} container mx-auto`}>
            <div className="py-md-5 pb-5 pb-4 text-center">
                {_chat ? (
                    <Empty
                        imageStyle={{
                            height: 250,
                        }}
                        description={<Title level={4}>{desc}</Title>}
                    ></Empty>
                ) : (
                    <Empty
                        image={empty}
                        imageStyle={{
                            height: 200,
                        }}
                        description={<Title level={4}>{desc}</Title>}
                    ></Empty>
                )}
                {link ? (
                    <div className="mt-4">
                        <Link to={link}>
                            <PrimaryBtn text={text ? text : children} />
                        </Link>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
