import React from 'react';
import Input from '../component/From/Input';

const commonfunction = {
    renderInput(props) {
        return <Input {...props} />;
    },
    renderInputPlain(props) {
        return <Input {...props} />;
    }
}
export default commonfunction