import InfiniteScroll from 'react-infinite-scroll-component';


export function RenderList({
  dataLength,
  height,
  hasMore,
  fetchMoreData,
  children,
}) {
  return (
    <>
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<p className='loading-scroll'>
        loading...
        </p>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>.</b>
          </p>
        }
        height={1500}
        className='infinite-scroll'
        // below props only if you need pull down functionality
        refreshFunction={fetchMoreData}
        pullDownToRefresh={false}
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        }
      >
        {children}
      </InfiniteScroll>
    </>
  );
}
