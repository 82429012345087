// Input field
export const InputField = ({ type, name, placeholder, ...rest }) => (
  <input
    className="form-control __custom_input"
    type={type || "text"}
    name={name}
    id={name}
    placeholder={placeholder}
    {...rest}
  />
);

// Input with label field
export const InputLabel = ({ label, type, name, placeholder, ...rest }) => (
  <div className="d-flex flex-column">
    <label htmlFor={name} className="__labe_l mb-2">
      {label}
    </label>
    <InputField
      type={type || "text"}
      name={name}
      placeholder={placeholder}
      {...rest}
    />
  </div>
);

// Select
export const SelectLabel = ({ name, label, choices, defaultValue, ...rest }) => (
  <div className="d-flex flex-column">
    <label htmlFor={name} className="__labe_l mb-2">
      {label}
    </label>
    <select
      name={name}
      id={name}
      className="__custom_input form-control"
      {...rest}
    >
      <option className="__default_op" value={defaultValue} disabled>
        {defaultValue}
      </option>
      {choices.map((choice, index) => (
        <option key={index} value={choice}>
          {choice}
        </option>
      ))}
    </select>
  </div>
);
