import React from 'react'
import {AiOutlineStar, AiFillStar} from 'react-icons/ai';
import "./style.css";

const Star = ({floorRate, rate}) => {
  return (floorRate > rate) ? <AiOutlineStar size={18} className='prl__star-rating-no' /> : <AiFillStar size={18} className='prl__star-rating-yes' />
}

const StarRating = ({rate}) => {
  return (
    <div className='prl__star-rating__container'>
      <Star floorRate={1} rate={rate}/>
      <Star floorRate={2} rate={rate}/>
      <Star floorRate={3} rate={rate}/>
      <Star floorRate={4} rate={rate}/>
      <Star floorRate={5} rate={rate}/>
    </div>
  )
}

export default StarRating