import React from 'react'
import { Popconfirm, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const LiveDetailActions = ({ onEditClick, onDeleteClick }) => {
  return (
    <div className="__live__btn_container">
      <Button className="__order-btn-primary" onClick={onEditClick}>
        Update
      </Button>
      <Popconfirm
        title="Are you sure you want to delete this product?"
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        // onConfirm={() => this.delete(product.sno)}
        onConfirm={onDeleteClick}
        okText="Yes"
        cancelText="No"
      >
        <Button className="__order-btn-danger" onClick={e=> e.stopPropagation()}>Delete</Button>
      </Popconfirm>
    </div>
  );
}

export default LiveDetailActions