import { all, takeLatest } from "redux-saga/effects";

import { watchIncrementAsync } from "./Test";
import watchUser from "./user";
import watchProduct from "./product";


export default function* rootSaga() {
  yield all([
    watchIncrementAsync(),
				watchUser(),
				watchProduct()
  ]);
}
