import { Avatar, Card, Image, Space, Typography, Rate } from "antd";
import { ImageWithFallback } from "../../../constants/fallback";
import { UserOutlined } from "@ant-design/icons";


const { Text, Title } = Typography;

// Product review card
export const ProductReviewCard = ({ productImg, productName, desc,price }) => (
  <Card>
    <Space align="center" size={[20, 10]}>
      {productImg ? (
        <Image width={100} height={100} src={productImg} />
      ) : (
        <ImageWithFallback width={100} height={100} />
      )}
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <Title level={5}>{productName}</Title>
          <Text type="secondary" strong>{`$${price}`}</Text>
        </div>
        <Text type="secondary">{desc}</Text>
      </div>
    </Space>
  </Card>
);


// User Review content card

export const UserReviewCard = ({ avatar, customerName, date, review, customerStarRate }) => (
  <Card>
    <div>
      <div className="d-sm-flex justify-content-between">
        <Space align="center" size={16}>
          <Avatar size={50} icon={<UserOutlined />} src={avatar} />
          <Space direction="vertical">
            <Title level={5}>{customerName}</Title>
            <Rate
              disabled
              value={customerStarRate}
              style={{ transform: "translateY(-15px)", fontSize: "12px" }}
            />
          </Space>
        </Space>
        <Text type="secondary" strong>{date}</Text>
      </div>
      <div className="mt-3">
        <Text type="secondary">{ review}</Text>
      </div>
    </div>
  </Card>
);