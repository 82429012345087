import axios from "axios";
import { serverurl } from "../helper";
// import cogoToast from "cogo-toast";
import { getImTokenFromBrowserCookie } from "./cookies/cookies";

const isDev = true;
//Setting user token on app load could be done elsewhere,
//maybe on shell mount or routes isLogin check
const jwtToken = getImTokenFromBrowserCookie();
const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, PATCH, DELETE",
  "Content-Type": "application/json",
};

function resetHeaders(agent) {
  agent.defaults.headers = headers;
}

const publicAgent = axios.create({
  // baseURL: isDev
  //   ? `http://ec2-175-41-178-95.ap-southeast-1.compute.amazonaws.com:6002`
  //   : process?.env?.BACKEND_API_BASE ||
  //     `http://ec2-175-41-178-95.ap-southeast-1.compute.amazonaws.com:6002`,
  headers: headers,
  baseURL: serverurl
});

const privateAgent = axios.create({
  baseURL:
    process?.env?.BACKEND_API_BASE ||
    `http://eamr.life:6002`,
  headers: { ...headers, authorization: jwtToken ? jwtToken : "" },
});
privateAgent.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = getImTokenFromBrowserCookie();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
privateAgent.interceptors.response.use(null, (error) => {
  // console.log("INTERCEPTOR Error", error);
  // if (error.response.status === 400) {
  //   console.log("Not athorized call logout functions here... right now missing ",error.response.error)
  // }
  return Promise.reject(error);
});

const CancelToken = axios.CancelToken;

export { headers, resetHeaders, publicAgent, privateAgent, CancelToken };
