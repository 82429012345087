import Footer from "../../../component/footer/Footer";

export default function Layout({ children, className, style, styl }) {
    return (
        <>
            <main className="row main-section" style={styl}>
                {children}
            </main>
            <Footer className={className} style={style} />
        </>
    );
}
