import {
  InlineReactionButtons,
  InlineShareButtons,
  StickyShareButtons,
  InlineFollowButtons
} from "sharethis-reactjs";

const ShareThis = ({ image, description, title, message, subject, id }) => {
  // const url = window.location.href;
		const url = `${process?.env?.REACT_APP_FRONTEND}#/product-detail/${id}`;
		console.log("ShareThis url >>", url);
  return (
    <div>
      <div id="inline-social-share">
        <InlineShareButtons
          config={{
            alignment: "center", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 12, // font size for the buttons
            labels: "cta", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "facebook",
              "linkedin",
              "twitter",
														'instagram'              
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: false,
            size: 36, // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: url, // (defaults to current url)
            image: "", // (defaults to og:image or twitter:image)
            description: "description", // (defaults to og:description or twitter:description)
            title: `${title}`, // (defaults to og:title or twitter:title)
            message: `${url}`, // (only for email sharing)
            subject: `${title}` // (only for email sharing)
            // username: "username" // (only for twitter sharing)
          }}
        />
      </div>
    </div>
  );
};

export default ShareThis;
