import { v4 as uuidv4 } from "uuid";
import jsCookie from "js-cookie";
import { openModal, closeModal } from "../../../store/actions/Modals";
import { sendOtpActionRequest } from "../../../store/actions/user";
import { sendOtpValidation } from "../../../configurations/Schemas";

export const handleSubmit = ({ phone, phoneData }, dispatch) => {
    // console.log("Form Values", values);
    const phoneDetails = { phone, phoneData };
    const requestBody = createRequestBody(phoneDetails);
    console.log("sendOtpApiCall requestBody >>", requestBody);
    dispatch(
        sendOtpActionRequest(requestBody, () => {
            setPhoneCookies(phoneDetails, requestBody.tempphonenumber);
            openModalAction(dispatch);
        })
    );
};

export const validatePhone = (phone, cb) => {
    sendOtpValidation
        .validate({ phone })
        .then(() => cb(null))
        .catch(({ errors }) => {
            cb(errors[0]);
        });
};

const createRequestBody = ({ phone, phoneData }) => {
    // console.log("call api formValues >>", formValues);
    const deviceToken = uuidv4();
    if (!jsCookie.get("ImToken")) {
        jsCookie.set("ImToken", deviceToken);
    }
    const tempphonenumber = phone.replace(`${phoneData.dialCode}`, "");
    const requestBody = {
        dialing_code: phoneData.dialCode ? `+${phoneData.dialCode}` : "",
        phonenumber: tempphonenumber,
        // device_type: 0,
        // device_token: deviceToken,
    };
    return requestBody;
};

const setPhoneCookies = ({ phone, phoneData }, tempphonenumber) => {
    jsCookie.set("OTPNumber", phone);
    jsCookie.set("OTPDialCode", phoneData.dialCode ? phoneData.dialCode : "");
    jsCookie.set("OTPTempNumber", tempphonenumber);
};

const openModalAction = (dispatch) => {
    dispatch(
        openModal(
            "",
            "Text",
            null,
            null,
            "verify_otp_modal",
            (...args) => dispatch(openModal(...args)),
            () => dispatch(closeModal())
        )
    );
};
