import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import React from 'react'

const SortOrder = ({isAscend, handleClick}) => {
  const Icon = isAscend ? ArrowUpOutlined : ArrowDownOutlined;
  return (
    <Icon onClick={handleClick} />
  )
}

export default SortOrder