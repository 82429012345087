import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import pin_ico from "./../../../assets/images/pin-ico.svg";
import ewallet_ico from "./../../../assets/images/ewallet-ico.svg";
import product_img1 from "./../../../assets/images/product-img1.png";
import vertical_more_ico from "./../../../assets/images/vertical-more-ico.svg";
import truck_ico from "./../../../assets/images/truck-ico.svg";
import Layout from "../Layouts/PageLayouts";
import { SecBtn, PrimaryBtn } from "../../../component/button/Button";
import api from "../../../apis";
import jsCookie from "js-cookie";
import CancelOrder from "./CancelOrder";
import EmptyResult from "../../../component/empty-result";
import { filterViewOrder, sortViewOrder } from "./viewOrderService";
import { getFromOffsetToLimit, truncateNum } from "./service";
import OrderDropdown from "./OrderDropdown";
import ViewOrderSortingMenu from "./ViewOrderSoertingMenu";
import SortOrder from "./SortOrder";
import Pagination from "./Pagination";
import Notify from "cogo-toast";

const pageLimit = 20;
class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      orderID: "",
      openModal: false,
      sortType: "Name",
      isAscend: true,
      pageOffset: 0,
      filterType: "All",
    };
  }

  componentDidMount() {
    const id = this.props.match?.params?.id;
    const user = jsCookie.get("userinfo");
    if (!user || !JSON.parse(user)) {
      return (window.location.href = "/");
    } else if (!id) {
      return (window.location.href = "/order-list");
    }
    this.setState({ orderID: id });
    api
      .vieworderdetails({ id })
      .then((res) => {
        this.setState({ orders: res.orders });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  formatDate = (dt) => (dt > 9 ? dt : "0" + dt);

  cancOrder = () => {
    const user = jsCookie.get("userinfo");
    const userid = JSON.parse(user).userid;
    console.log(user);
    const awaitingOrders = this.state.orders.filter(order => order.order_status == 0);
    api.updateOrderStatus({userid: userid, orderid: this.state.orderID, status: -2, id: awaitingOrders[0]?awaitingOrders[0].id : 0 }).then(res => {
      if(res.success) {
        Notify.success("Order has been cancelled!");
        api
        .vieworderdetails({ id: this.state.orderID })
        .then((res) => {
          this.setState({ orders: res.orders });
        })
      } else {
        Notify.error("Error cancelling order");
      }
      this.setState({openModal: false});
    }).catch(err => {
      Notify.error("Error cancelling order");
    })
  }

  closeModal = () => this.setState({openModal: false});

  render() {
    return (
      <>
        <Layout style={{ marginTop: "200px" }}>
          <div className="container large summary-container vo-container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-12 review-title-wrap mb-4">
                    <div className="p-title">
                      <p className="mb-0 opacity-4">
                        <Link to="/order-list" className="back-ico">
                          <img src={next_arrow} alt="icon" />
                        </Link>
                        Order ID : {this.state.orderID}
                      </p>
                    </div>
                    <div className="__dropdown">
                      <button
                        className=""
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          className="img-fluid"
                          src={vertical_more_ico}
                          alt="icon"
                        />
                      </button>
                      <div className="__dropdown-content">
                        <a
                          className="text-danger dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#cancel-modal"
                          onClick={() => this.setState({openModal:true})}
                        >
                          Cancel Order
                        </a>
                        {/* <Link className="dropdown-item" to="/chat">
                          Chat
                        </Link> */}
                        <Link className="dropdown-item" to="/#">
                          Contact Support
                        </Link>
                      </div>

                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="moremenu"
                      >
                        <a
                          className="text-danger dropdown-item"
                          href="/#"
                          data-toggle="modal"
                          data-target="#cancel-modal"
                        >
                          Cancel Order
                        </a>
                        <Link className="dropdown-item" to="/chat">
                          Chat
                        </Link>
                        <Link className="dropdown-item" to="/#">
                          Contact Support
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="opacity-4">
                      <strong>Order Status</strong>
                    </p>
                    <div
                      style={{
                        width: "fit-content",
                        margin: "0 auto",
                        marginTop: "8px",
                      }}
                    >
                      <div className="__order-table-setting-container">
                        <div className="__order-table-setting">
                          <div className="__sorting-container">
                            <div className="d-flex align-items-center">
                              <span className="mr-2">Sort by:</span>
                              <OrderDropdown
                                menu={
                                  <ViewOrderSortingMenu
                                    handleClick={(value) => {
                                      this.setState({ sortType: value });
                                    }}
                                  />
                                }
                                btnText={this.state.sortType}
                              />
                            </div>
                            <SortOrder
                              isAscend={this.state.isAscend}
                              handleClick={() => {
                                this.setState({
                                  isAscend: !this.state.isAscend,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <Pagination
                          currentFirstNo={truncateNum(
                            this.state.orders.length
                              ? this.state.pageOffset + 1
                              : 0,
                            0,
                            this.state.orders.length
                          )}
                          currentLastNo={truncateNum(
                            this.state.pageOffset + pageLimit,
                            0,
                            this.state.orders.length
                          )}
                          totalNo={this.state.orders.length}
                          onPreviousClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              pageOffset: truncateNum(
                                this.state.pageOffset - pageLimit,
                                0,
                                this.state.pageOffset
                              ),
                            });
                          }}
                          onNextClick={(e) => {
                            e.stopPropagation();
                            const newOffset = truncateNum(
                              this.state.pageOffset + pageLimit,
                              0,
                              this.state.orders.length
                            );

                            this.setState({
                              pageOffset:
                                newOffset === this.state.orders.length
                                  ? this.state.pageOffset
                                  : newOffset,
                            });
                          }}
                        />
                      </div>
                      <table className="__table __h-50 __product">
                        <thead className="__sticky">
                          <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Image</th>
                            <th scope="col">Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">{"Price ($)"}</th>
                            <th scope="col">Order Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {state.orders.length > 0 ? ( */}
                          {this.state.orders.length > 0 ? (
                            <>
                              {/* {state.orders.map((order, index) => { */}
                              {filterViewOrder(
                                getFromOffsetToLimit(
                                  sortViewOrder(
                                    this.state,
                                    this.state.sortType,
                                    this.state.isAscend
                                  ),
                                  this.state.pageOffset,
                                  pageLimit
                                ),
                                this.state.filterType
                              ).map((order, index) => (
                                <tr
                                // key={product.sno}
                                >
                                  <th data-title="S/N" scope="row">
                                    <span className="__line-clamp-3">
                                      {" "}
                                      {this.state.pageOffset + index + 1}
                                    </span>
                                  </th>
                                  <td data-title="Image">
                                    <Image
                                      src={order.product_photo || product_img1}
                                      preview={false}
                                      alt="product"
                                      width={"90px"}
                                      style={{
                                        borderRadius: "2px",
                                        border: "1px solid #eee",
                                      }}
                                    />
                                  </td>
                                  <td data-title="Name">
                                    {order.product_name}
                                  </td>
                                  <td
                                    data-title="Quantity"
                                    className="text-right"
                                  >
                                    {order.product_quantity}
                                  </td>
                                  <td
                                    data-title="Price ($)"
                                    className="text-right"
                                  >
                                    {order.product_price}
                                  </td>
                                  <td data-title="Order Status">
                                    {/* TODO: Arib order_status should be either 'Waiting', 'Accepted', 'Rejected', 'Waiting for Delivery', 'Delivery in Progress' or 'Delivered'just as in the merchant side. Check Sumi's message on 1st March, 06:27am */}
                                    {
                                    Number(order.order_status) >= 1 ?
                                    ( parseInt(order.order_status > 1) ? "Completed"  : "Accepted" )
                                    :
                                    parseInt(order.order_status) < 0 ? (parseInt(order.order_status) == -1 ? "Rejected" : "Cancelled") : "Awaiting"
                                    }
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr className="text-center container mx-auto">
                              <td colSpan={6}>
                                <div className="mt-4">
                                  <EmptyResult
                                    to="/order-list"
                                    desc="You have a product in your order list yet."
                                    height={100}
                                    action="Order List"
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className="col-12">
                  <div className="address-blk">
                    <img className="img-fluid mr-2" src={pin_ico} alt="icon" />
                    <div className="addr-sec">
                      <p>Address</p>
                      <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec quis rutrum purus. Sed vestibulum iaculis dolor in
                        bibendum. Vivamus mattis erat risus
                      </span>
                    </div>
                    <Link to="/profile" className="text-link">
                      Change
                    </Link>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="order-lft-snip">
                  <div className="row mt-1">
                    <p className="col-8 mb-0">Starting Subtotal</p>
                    <p className="col-4 text-right mb-0">
                      ${" "}
                      {this.state.orders && this.state.orders[0]?.total_amount}
                    </p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Subtotal</p>
                    <p className="col-4 text-right mb-0">
                      ${" "}
                      {this.state.orders && this.state.orders[0]?.total_amount}
                    </p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Shipping Fee</p>
                    <p className="col-4 text-right mb-0">$ 0.00</p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Estimated Tax</p>
                    <p className="col-4 text-right mb-0">7% (inclusive)</p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">
                      <strong>Order Total</strong>
                    </p>
                    <p className="col-4 text-right mb-0">
                      <strong>
                        $
                        {this.state.orders &&
                          this.state.orders[0]?.total_amount}
                      </strong>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-12 p-method">
                      <p>
                        Payment Method{" "}
                        <img
                          className="img-fluid"
                          src={ewallet_ico}
                          alt="icon"
                        />{" "}
                        <span>
                          {this.state.orders &&
                            this.state.orders[0]?.payment_method.toUpperCase()}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <p className="col-7 mb-0">Order Number:</p>
                    <p className="col-5 text-right mb-0">
                      {this.state.orders && this.state.orders[0]?.order_id}
                    </p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Order Date:</p>
                    <p className="col-4 text-right mb-0">
                    {
                      this.state.orders[0] && (
                      this.formatDate(new Date(this.state.orders[0].order_date).getDate()) + "/" +
                      this.formatDate(new Date(this.state.orders[0].order_date).getMonth()  +1) + "/" +
                      this.formatDate(new Date(this.state.orders[0].order_date).getFullYear())
                      )
                    }
                    </p>
                  </div>
                  <div className="row mt-2">
                    <p className="col-8 mb-0">Order Payment:</p>
                    <p className="col-4 text-right mb-0">
                    {
                      this.state.orders[0] && (
                      this.formatDate(new Date(this.state.orders[0].order_date).getDate()) + "/" +
                      this.formatDate(new Date(this.state.orders[0].order_date).getMonth()  +1) + "/" +
                      this.formatDate(new Date(this.state.orders[0].order_date).getFullYear())
                      )
                    }
                    </p>
                  </div>
                  {/* <div className="row mt-2">
                  <p className="col-8 mb-0">Shipping Date:</p>
                  <p className="col-4 text-right mb-0">28/04/2021</p>
                </div> */}
                  <div className="row mt-2">
                    <div className="col-12 mt-4">
                      <Link to={"/chat?prod="} className="">
                        <PrimaryBtn
                          block
                          text="Chat Merchant"
                          // onClick={chatMerchant}
                          // disabled
                        >
                          Chat Merchant
                        </PrimaryBtn>
                      </Link>
                      {/* <Link to="add-review">
                      <button className="btn btn-dark-blue mb-2">
                        Write a Review
                      </button>
                    </Link> */}
                      <div className="mt-3">
                        <Link
                          to={
                            "/add-review/" +
                            (this.state.orders && this.state.orders[0]?.sno)
                          }
                          className=""
                        >
                          <SecBtn block text="Write a Review">
                            Write a Review
                          </SecBtn>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
        {this.state.openModal ? <CancelOrder closeModal={this.closeModal} cancelOrder={this.cancOrder} /> : null}
      </>
    );
  }
}

export default ViewOrder;
