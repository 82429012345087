import React from "react";
import { Menu } from "antd";
import styled, { css } from "styled-components";

const StyledMenu = styled(Menu)`
  && a,
  && li {
    color: #ffffff;
    width: 100%;
    z-index: 100;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
				height: 60px !important;
  }
  .ant-menu-item span a {
    padding-left: 16px;
				font-size: 18px;
  }
  .ant-menu-item span svg {
    color: #ffffff !important;
    font-size: 20px;
    font-weight: 200;
  }

  .ant-menu-item:not(:last-child) {
    margin-right: 10px;
  }

  // HOVER EFFECT

  .ant-menu-item:hover {
    background-color: #0761c1 !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected) a:hover {
    color: #ffffff !important;
  }

  // AFTER SELECT

  .ant-menu-item-selected a {
    color: #ffffff;
  }
		.ant-menu-item-selected a:hover {
			color: #ffffff;
	}
  && .ant-menu-item-selected {
    background-color: #0761c1 !important;
  }
  .ant-menu-item-selected span svg {
    color: #ffffff !important;
  }
`;

const MenuWrapper = (props) => {
  return <StyledMenu {...props}></StyledMenu>;
};

export default MenuWrapper;
