import { Result } from "antd";
import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <div className="container pb-4 mx-auto">
      <div className="py-md-5 text-center">
        <Result
          status="404"
          title="404"
          subTitle="Oops! Page not found."
          extra={
            <Link to="/" className="bg-primary px-4 py-2 text-white __back_link">
              Back Home
            </Link>
          }
        />
      </div>
    </div>
  );
}
