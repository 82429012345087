import React, { Component } from "react";
import { Link } from "react-router-dom";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import calendar_ico from "./../../../assets/images/calendar-ico.svg";
import jsCookie from "js-cookie";
import Notify from "cogo-toast";
import api from "../../../apis";
import NoResult from "../Error/NoResult";
import Layout from "../Layouts/PageLayouts";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    const userid = jsCookie.get("userinfo")
      ? JSON.parse(jsCookie.get("userinfo")).sno
      : null;
    if (!userid || userid === null) {
      Notify.error("You need to be logged in");
      window.location.href = "/";
    } else {
      api
        .getNoifications({ id: userid })
        .then((res) => {
          console.log(res);
          this.setState({ notifications: res.notifications });
        })
        .catch((err) => {
          Notify.error("An error occured while fetching notifications");
        });
    }
  }

  render() {
    return (
      <Layout style={{ marginTop: "200px" }}>
        <div className="container large cart-container">
          <div className="row d-flex flex-column justify-content-between align-items-center">
            <div className="col-12 review-title-wrap mb-4">
              <h1>
                <Link to="/" className="back-ico">
                  <img src={next_arrow} alt="icon" />
                </Link>
                Notifications
              </h1>
              <button className="btn choose-date">
                <img className="img-fluid" src={calendar_ico} alt="ico" />
              </button>
            </div>

            {this.state.notifications && this.state.notifications.length > 0 ? (
              <>
                <div className="col-12">
                  <div className=" notify-list p-0 mb-4">
                    <p className="fs-11 opacity-3">Today</p>
                  </div>
                </div>
                {this.state.notifications.reverse().map((notification, idx) => {
                  return (
                    <div className="col-12" key={idx}>
                      <Link to={"/product-detail/" + notification.product_id}>
                        <div className="notify-list unread">
                          <p className="fs-12 mb-0">
                            <strong>{notification.live_deal_product}</strong>{" "}
                            has been added for{" "}
                            <strong>{notification.live_deal_price}USD</strong>{" "}
                            that matches your Live Deal
                          </p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
                <div className="col-12 ">
                  <div className="notify-list p-0">
                    <p className="fs-11 opacity-3 mt-3">Yesterday</p>
                  </div>
                </div>
              </>
            ) : (
              <NoResult desc="No notifications" _chat={true} />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Notification;
