import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import cogoToast from "cogo-toast";
import { registerActionRequest } from "../../store/actions/user";
import { openModal, closeModal } from "../../store/actions/Modals";
import { signupValidations } from "../../configurations/Schemas";
import close_dark from "./../../assets/images/close-dark-ico.svg";
import next_arrow from "./../../assets/images/next-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import jsCookie from "js-cookie";
import { PhoneNumberInput, DatePicker } from "../../component/generic";
// import DatePicker from "react-datepicker";
import {
  getYear,
  addMonths,
  isAfter,
  isValid,
  addDays,
  addYears,
  parseISO,
  parseJSON,
} from "date-fns";
import "../../assets/react-datepicker.css";

const userGenderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  ];

const RegisterForm = (props) => {
  // console.log("RegisterForm Component Props", props);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneData, setPhoneData] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState({ label: "Male", value: "Male" });
  const [address, setAddress] = useState("");

  const callApi = (formValues) => {
    // console.log("call api formValues >>", formValues);
    let deviceToken = uuidv4();
    if (!jsCookie.get("ImToken")) {
      jsCookie.set("ImToken", deviceToken);
    }
				let tempphonenumber = formValues.phone.replace(`${phoneData.dialCode}`,'');
    let requestBody = {
      name: formValues.name ? formValues.name : "",
      dialing_code: phoneData.dialCode ? phoneData.dialCode : "",
      phonenumber: tempphonenumber,
      email: formValues.email ? formValues.email : "",
      dob: formValues.dateOfBirth ? formValues.dateOfBirth : "",
      gender:
        formValues.gender && formValues.gender.value
          ? formValues.gender.value
          : "",
      address: formValues.address ? formValues.address : "",
      type: 3,
      device_type: 0,
      device_token: deviceToken,
    };
    console.log("registerApiCall requestBody >>", requestBody);
    props.registerApiCall(requestBody);
  };
  return (
    <>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          dateOfBirth,
          gender,
          address,
        }}
        validateOnBlur
        validateOnChange
        validationSchema={signupValidations}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log("Form Values", values);
          callApi(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          validateForm,
          setFieldTouched,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              {console.log("form errors", errors)}
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      isInvalid={errors.name && touched.name}
                      placeholder="Full Name"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setName(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.name && touched.name ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled={props.inputState}
                      type="email"
                      name="email"
                      value={values.email}
                      isInvalid={errors.email && touched.email}
                      placeholder="test@example.com"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setEmail(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.email && touched.email ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    {/* <Form.Label>Investor Type</Form.Label> */}
                    <div
                      style={{
                        display: "inline-flex",
                        width: "max-content",
                        position: "inherit",
                      }}
                    >
                      {userGenderOptions.map((item) => (
                        <Form.Check
                          name="gender"
                          style={{ marginLeft: "15px" }}
                          id={item.label}
                          key={item.label}
                          value={item.value}
                          checked={values.gender.value == item.value}
                          onChange={(e) => {
                            setGender(item);
                            setFieldValue("gender", item);
                          }}
                          type="radio"
                          label={item.label}
                        />
                      ))}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className={`m-0 mb-1 phone_input`}
                  >
                    <Form.Label>Phone</Form.Label>
                    <PhoneNumberInput
                      inputStyle={{
                        backgroundColor: "rgb(255 255 255)",
                        color: `black`,
                      }}
                      buttonStyle={{ backgroundColor: "rgb(255 255 255)" }}
                      // disabled={inputsDisabled()}
                      value={phone}
                      changeHandler={(
                        changeValue,
                        country,
                        e,
                        formattedValue
                      ) => {
                        // console.log(
                        //   "PhoneNumberInput changeValue >>",
                        //   changeValue
                        // );
                        // console.log("PhoneNumberInput country >>", country);
                        // console.log(
                        //   "PhoneNumberInput formattedValue >>",
                        //   formattedValue
                        // );
                        setPhone(changeValue);
                        setPhoneData(country);
                        // setFieldValue("phone", changeValue);
                      }}
                      placeholder="Enter your telephone number*"
                    />
																				{errors.phone && touched.phone ? (
                      <span className="form-error-span">
                        {errors.phone}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <div className="col">
                      <DatePicker
                        updateDateComp={0}
                        initialDate={addYears(Date.now(), -13)}
                        showTime={false}
                        minDate={addYears(Date.now(), -100)}
                        maxDate={addYears(Date.now(), -13)}
                        showDisabledMonthNavigation
                        changeHandler={(date) => {
                          // console.log("changeHandler DatePicker >>", date);
                          setDateOfBirth(date.toISOString());
                          setFieldValue("dateOfBirth", date.toISOString());
                        }}
                      />
                    </div>
                    {errors.dateOfBirth && touched.dateOfBirth ? (
                      <span className="form-error-span">
                        {errors.dateOfBirth}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      isInvalid={errors.address && touched.address}
                      placeholder="Shipping Address"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setAddress(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.address && touched.address ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="form-group mt-5 mb-0">
                <button
                  className="btn btn-circle-next"
                  onClick={() => {
                    // console.log("clicked Login / Signup", true);
                    props.closeModals();
                    props.getModal(
                      "",
                      "Text",
                      null,
                      null,
                      "send_otp_modal",
                      props.getModal,
                      props.closeModals
                    );
                  }}
                >
                  Already have an account!
                </button>
              </div>
              <div className="form-group mt-5 mb-0">
                {/* <Button
                  onClick={() => {
                    props.closeModals();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Cancel
                </Button> */}
                <Button
                  disabled={props.modalBtnLoader}
                  type="submit"
                  className="btn btn-circle-next"
                >
                  {props.modalBtnLoader ? (
                    <Spinner animation="border" variant="dark" />
                  ) : (
                    <img src={next_arrow} alt="icon" />
                  )}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    registerApiCall: (...args) => {
      dispatch(registerActionRequest(...args));
    },
  };
};

export default connect(null, mapDispatchToProps)(RegisterForm);
