import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ProductCard = (data) => {
    return (
        <div
            className="col-lg-2 col-md-3 col-6 px-2 mb-md-4 mb-3 mt-2"
            onClick={() => {
                window.location = "/product-detail/" + data.data.sno;
                window.location.reload();
            }}
        >
            <a
                href={"/#/product-detail/" + data.data.sno}
                className="product-snippet"
            >
                {/* <Link to={"/product-detail/" + data.data.sno}> */}
                <div className="p-img">
                    <img
                        className="img-fluid"
                        src={data.data.photo1 || data.data.photo2 || null}
                        alt={data.data.name || "testing product"}
                    />
                </div>
                <div className="p-cont">
                    <h5>{data.data.name}</h5>
                    <p>{data.data.description.slice(0, 100)}</p>
                    <span className="price">{data.data.price}</span>
                </div>
                {/* </Link> */}
            </a>
        </div>
    );
};

export default ProductCard;
