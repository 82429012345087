import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsCart4 } from 'react-icons/bs';
import { FiLogIn, FiUserPlus } from 'react-icons/fi';
import { GrAppsRounded } from 'react-icons/gr';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PrimaryBtn } from '../../../component/button/Button';
import './styles.css';

export default function InstructionModal(props) {
  const modalState =
    localStorage.getItem('instruction') === null
      ? true
      : localStorage.getItem('instruction');
  const history = useHistory();
  const [modal, setModal] = useState(true);
  const [modalStage, setModalStage] = useState(1);

  useEffect(() => {
    setModal(
      modalState === null ? true : modalState === 'false' ? false : modalState === 'true' ? true : true
    );

    if (!localStorage.getItem('instruction') && modalStage>=2) {
      localStorage.setItem('instruction', false);
      return;
    }
  }, [modalStage]);

  console.log(
    !localStorage.getItem('instruction'),
    localStorage.getItem('instruction'),
    modal
  );

  return (
    <>
      {!props.user.userData && !props.livedeal && modal && modalStage == 1 && (
        <div
          className='instruction-modal login'
          onClick={() => setModalStage(2)}
        >
          <div className='content'>
            <p>Click here to login, tap anywhere to dismiss</p>
          </div>
          <div className='line-border'></div>
          <button type='button' className='__mobile_authbtn'>
            <span className='d-flex flex-column justify-content-center align-items-center'>
              <Tooltip title='Login' color='#275DAD' placement='top'>
                <FiLogIn />
              </Tooltip>
              <span className='__text_h'>Log in</span>
            </span>
          </button>
        </div>
      )}

      {!props.user.userData && modal && modalStage == 2 && (
        <div
          className='instruction-modal signup'
          onClick={() => {
            setModalStage(3);
          }}
        >
          <div className='content'>
            <p>Click here to register, tap anywhere to dismiss</p>
          </div>
          <div className='line-border'></div>
          <button type='button' className='__mobile_authbtn'>
            <span className='d-flex flex-column justify-content-center align-items-center'>
              <Tooltip title='Sign up' color='#275DAD' placement='top'>
                <FiUserPlus />
              </Tooltip>
              <span className='__text_h'>Sign up</span>
            </span>
          </button>
        </div>
      )}

      {props.user.userData && !props.livedeal && modal && modalStage == 1 && (
        <div
          className='instruction-modal more'
          onClick={() => setModalStage(2)}
        >
          <div className='content'>
            <p>
              Click here to see livedeal and settings, tap anywhere to dismiss
            </p>
          </div>
          <div className='line-border'></div>
          <button type='button' className='__mobile_navbtn'>
            <span className='d-flex flex-column justify-content-center align-items-center'>
              <Tooltip title='More' color='#275DAD' placement='top'>
                <GrAppsRounded style={{ fontSize: '1.4rem' }} />
              </Tooltip>
              <span className='__text_h font-weight-bold'>More</span>
            </span>
          </button>
        </div>
      )}

      {props.user.userData && !props.livedeal && modalStage == 2 && (
        <div
          className='instruction-modal cart'
          onClick={() => setModalStage(3)}
        >
          <div className='content'>
            <p>Click here to see Cart, tap anywhere to dismiss</p>
          </div>
          <div className='line-border'></div>
          <button type='button' className='__mobile_navbtn'>
            <span className='d-flex flex-column justify-content-center align-items-center'>
              <Tooltip title='Cart' color='#275DAD' placement='top'>
                <BsCart4 />
              </Tooltip>
              <span className='__text_h'>Cart</span>
            </span>
          </button>
        </div>
      )}

      {props.user.userData && props.livedeal && modal && modalStage == 1 && (
        <div
          className='instruction-modal livedeal productname'
          onClick={() => setModalStage(2)}
        >
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              name='productName'
              placeholder='Product Name'
            />
          </div>
          <div className='line-border'></div>
          <div className='content'>
            <p>Enter product name here, tap anywhere to dismiss</p>
          </div>
        </div>
      )}

      {props.user.userData && props.livedeal && modal && modalStage == 2 && (
        <div
          className='instruction-modal livedeal minprice'
          onClick={() => setModalStage(3)}
        >
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              name='productName'
              placeholder='Minimum price'
            />
          </div>
          <div className='line-border'></div>
          <div className='content'>
            <p>Enter minimum price here, tap anywhere to dismiss</p>
          </div>
        </div>
      )}

      {props.user.userData && props.livedeal && modal && modalStage == 3 && (
        <div
          className='instruction-modal livedeal maxprice'
          onClick={() => setModalStage(4)}
        >
          <div className='form-group'>
            <input
              className='form-control'
              type='text'
              name='productName'
              placeholder='Maximum price'
            />
          </div>
          <div className='line-border'></div>
          <div className='content'>
            <p>Enter maximum price here, tap anywhere to dismiss</p>
          </div>
        </div>
      )}

      {props.user.userData && props.livedeal && modal && modalStage == 4 && (
        <div
          className='instruction-modal livedeal addbtn'
          onClick={() => setModalStage(5)}
        >
          <div className='form-group text-center mt-5'>
            <PrimaryBtn
              block
              text='Add'
              // className={"btn btn-primary"}
            />
          </div>
          <div className='line-border'></div>
          <div className='content'>
            <p>Click here to add, tap anywhere to dismiss</p>
          </div>
        </div>
      )}
    </>
  );
}

//
