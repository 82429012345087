import { Result } from "antd";
import { Link } from "react-router-dom";

export default function Error500() {
  return (
    <div className="container mx-auto">
      <div className="mp-md-5 mp-5 pb-4 text-center">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Link
              to="/"
              className="bg-primary px-4 py-2 text-white __back_link"
            >
              Back Home
            </Link>
          }
        />
      </div>
    </div>
  );
}
