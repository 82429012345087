import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import arrow_small_ico from "../../assets/images/arrow-small-ico.svg";
// import avatar from "./../../../assets/images/avatar.png";
import { connect } from "react-redux";
import { DateFormatter } from "../../component/generic";
import { logoutUser, verifyOtpActionSuccess } from "../../store/actions/user";
import { openModal, closeModal } from "../../store/actions/Modals";
import { userprofileApi } from "../../store/apis";
import Avatar from "react-avatar";
import { StarFilled } from "@ant-design/icons";
import styled from "styled-components";
import {
    TableButton,
    StyledButton,
} from "../../component/styled-component/styledButton";
import "./shell.css";

const StyledRating = styled.div`
    text-align: right;
    svg {
        color: #f7c667;
    }
`;

const Profile = (props) => {
    console.log("Profile props >>", props);
    const [userProfileData, setUserProfileData] = useState(null);

    // useEffect(() => {
    //   if (!props.userAuth) {
    //     window.location.href = "/";
    //   } else {
    //     getUserProfile(props);
    //   }
    // }, [props.userAuth]);

    // useEffect(() => {
    //   console.log("useEffect userProfileData >>", userProfileData);
    //   if (userProfileData && userProfileData != null && userProfileData.name) {
    //     props.updateStoreWithUserData(userProfileData);
    //   }
    // }, [userProfileData]);

    const getUserProfile = async () => {
        try {
            let requestBody = {
                profile_id: props.userData.profile_id,
            };
            // console.log("userprofileApi requestBody >>", requestBody);
            let res = await userprofileApi(requestBody);
            console.log("res >>", res);
            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data.status &&
                res.data.status === 200 &&
                res.data.data
            ) {
                setUserProfileData(res.data.data);
                return res.data.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log("error >", error);
        }
    };
    return (
        <>
            <section className="row main-section">
                <div className="container large cart-container profile-container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-12 review-title-wrap mb-4">
                                    <div className="avatar-img">
                                        {/* <img className="img-fluid" src={avatar} alt="icon" /> */}
                                        <Avatar
                                            name={
                                                props.user.userData &&
                                                props.user.userData.name
                                            }
                                            size="80"
                                            round="60px"
                                            textSizeRatio={1}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Email</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData
                                                ? props.user.userData.email
                                                : "merchant@email.com"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Phone Number</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.dialing_code &&
                                            props.user.userData.phonenumber
                                                ? `+${props.user.userData.dialing_code}${props.user.userData.phonenumber}`
                                                : "+923454920938"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Date of Birth</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {
                                                <DateFormatter
                                                    date={
                                                        props.user.userData &&
                                                        props.user.userData.dob
                                                            ? props.user
                                                                  .userData.dob
                                                            : new Date()
                                                    }
                                                    pattern="dd/MM/yyyy"
                                                />
                                            }
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Gender</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.gender
                                                ? `${props.user.userData.gender}`
                                                : "Male"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Password</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.password
                                                ? `${props.user.userData.password}`
                                                : "**************"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Company Reg No</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.crno
                                                ? `${props.user.userData.crno}`
                                                : "123"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Company Reg No</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.desc
                                                ? `${props.user.userData.desc}`
                                                : "It is a long established fact that a reader will be distracted by the readable content"}
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Star Rating</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            <StyledRating>
                                                <div className="star-icon-container">
                                                    <StarFilled />
                                                    <StarFilled />
                                                    <StarFilled />
                                                    <StarFilled />
                                                    <StarFilled />
                                                </div>
                                                <div
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    5/5 (100)
                                                </div>
                                            </StyledRating>
                                        </p>
                                    </div>
                                    <div className="row opacity-4 mb-2">
                                        <p className="col-md-6 mb-0">
                                            <strong>Mailing Address</strong>
                                        </p>
                                        <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                                            {props.user.userData &&
                                            props.user.userData.address
                                                ? `${props.user.userData.address}`
                                                : "Merchant Mailing Address Goes here"}
                                        </p>
                                    </div>
                                    {/* <div className="row opacity-4 mb-2">
                      <p className="col-md-6 mb-0">
                        <strong>Password</strong>
                      </p>
                      <p className="col-md-6 text-left text-md-right mb-2 mb-md-0">
                        *********
                      </p>
                    </div> */}
                                    <hr className="my-4" />
                                    <div className="row mb-3">
                                        <p className="col-6 mb-0">
                                            <strong>Ongoing Order</strong>
                                            <small>Ongoing 3 orders</small>
                                        </p>
                                        <div className="col-6 text-right">
                                            <Link
                                                to="/dashboard/orders-list"
                                                className="next mt-1"
                                            >
                                                <img src={arrow_small_ico} />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <p className="col-6 mb-0">
                                            <strong>Chat</strong>
                                            <small>7 unread messages</small>
                                        </p>
                                        <div className="col-6 text-right">
                                            <Link
                                                to="/dashboard/chat"
                                                className="next mt-1"
                                            >
                                                <img src={arrow_small_ico} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <p className="col-6 mb-0">
                                            <strong>Settings</strong>
                                            <small>
                                                Notification, Password
                                            </small>
                                        </p>
                                        <div className="col-6 text-right">
                                            <Link
                                                to="/dashboard/notifications"
                                                className="next mt-1"
                                            >
                                                <img src={arrow_small_ico} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="setting-opt">
                                        <div className="row mb-3">
                                            <p className="col-12 mb-0">
                                                How do you want to know about
                                                latest new and updates
                                            </p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-6 mb-0 mt-1">
                                                Push Notification
                                            </p>
                                            <div className="col-6 text-right">
                                                <label className="switch mb-0">
                                                    <input
                                                        type="checkbox"
                                                        checked="checked"
                                                    />
                                                    <span className="drag-ball">
                                                        <span className="off"></span>
                                                        <span className="on"></span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-6 mb-0 mt-1">
                                                Email
                                            </p>
                                            <div className="col-6 text-right">
                                                <label className="switch mb-0">
                                                    <input
                                                        type="checkbox"
                                                        checked="checked"
                                                    />
                                                    <span className="drag-ball">
                                                        <span className="off"></span>
                                                        <span className="on"></span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-6 mb-0 mt-1">
                                                SMS
                                            </p>
                                            <div className="col-6 text-right">
                                                <label className="switch mb-0">
                                                    <input type="checkbox" />
                                                    <span className="drag-ball">
                                                        <span className="off"></span>
                                                        <span className="on"></span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <p className="col-6 mb-0">
                                            <strong>Terms & Condition</strong>
                                            <small>
                                                Look at our Terms & Condition &
                                                Privacy Policy
                                            </small>
                                        </p>

                                        <div className="col-6 text-right">
                                            <Link
                                                to="/terms"
                                                className="next mt-1"
                                            >
                                                <img src={arrow_small_ico} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <p className="col-6 mb-0">
                                            <strong>Rate Us</strong>
                                            <small>Apps ver. 0.01.20</small>
                                        </p>
                                        <div className="col-6 text-right">
                                            <a href="#" className="next mt-1">
                                                <img src={arrow_small_ico} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-6">
                                            <StyledButton
                                                style={{
                                                    color: "#275DAD !important",
                                                    backgroundColor:
                                                        "#fff !important",
                                                    height: "100%",
                                                }}
                                                onClick={() => {
                                                    // props.logoutUser();
                                                    console.log(
                                                        "Logout Merchant",
                                                        true
                                                    );
                                                }}
                                                className="btn btn-outline-red w-100 py-2"
                                            >
                                                Logout
                                            </StyledButton>
                                        </div>
                                        <div className="col-6">
                                            <StyledButton
                                                style={{
                                                    height: "100%",
                                                }}
                                                onClick={() => {
                                                    // props.getModal(
                                                    // 		"",
                                                    // 		"Profile Details",
                                                    // 		null,
                                                    // 		null,
                                                    // 		"user_profile",
                                                    // 		props.getModal,
                                                    // 		props.closeModals
                                                    // );
                                                    console.log(
                                                        "Edit Company Profile"
                                                    );
                                                }}
                                            >
                                                Update
                                            </StyledButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    store: state,
    user: state.user,
    userData:
        state.user.userData && state.user.userData ? state.user.userData : [],
    userAuth:
        state.user.userData && state.user.userData.otp_verification_status == 1
            ? true
            : false,
});
const mapDispatchToProps = (dispatch) => {
    return {
        getModal: (...args) => {
            dispatch(openModal(...args));
        },
        closeModals: () => {
            dispatch(closeModal());
        },
        updateStoreWithUserData: (...args) => {
            dispatch(verifyOtpActionSuccess(...args));
        },
        logoutUser: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
