import {
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCT_REQUEST,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILED,
  PAYPAL_PAYMENT,
  PAYPAL_PAYMENT_FAILED,
} from "../Types";

const INITIAL_STATE = {
  productsList: [],
  productDetail: null,
  productsLoading: false,
  productsPayment: null,
  error: null,
};

let ProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST: {
      return Object.assign({}, state, { error: null, productsLoading: true });
    }

    case GET_ALL_PRODUCTS_SUCCESS: {
      return Object.assign({}, state, {
        productsList: action.payload,
        productsLoading: false,
        error: null,
      });
    }
    case PAYPAL_PAYMENT: {
      return Object.assign({}, state, {
        productsPayment: action.payload,
        productsLoading: false,
        error: null,
      });
    }

    case PAYPAL_PAYMENT_FAILED: {
      return Object.assign({}, state, {
        productsLoading: false,
        error: action.payload,
      });
    }
    case GET_ALL_PRODUCTS_FAILED: {
      return Object.assign({}, state, {
        error: action.payload,
        productsLoading: false,
      });
    }
    case GET_SINGLE_PRODUCT_REQUEST: {
      return Object.assign({}, state, { error: null, productsLoading: true });
    }
    case GET_SINGLE_PRODUCT_SUCCESS: {
      return Object.assign({}, state, {
        productDetail: action.payload,
        productsLoading: false,
        error: null,
      });
    }
    case GET_SINGLE_PRODUCT_FAILED: {
      return Object.assign({}, state, {
        productDetail: null,
        error: action.payload,
        productsLoading: false,
      });
    }

    default:
      return state;
  }
};
export default ProductReducer;
