import React, {useState} from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";



const ContactUs = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    phoneData: "",
    message: ""
  })

  const handleSumbit = () => {}

  return (
    <div className='c__container'>
       <div className="c__header">
        <ArrowLeftOutlined />
        <p>My Reviews</p>
      </div>
      <div className='c__form'>
      
      <div className='c__input-group'>
        <label>Name</label>
        <input value={formState['name']} name='name' onChange={(e) => setFormState(s => ({...s, name: e.target.value}))} placeholder="Your Name"/>
      </div>
      <div className='c__input-group'>
        <label>Email Address</label>
        <input value={formState['email']} name='email' onChange={(e) => setFormState(s => ({...s, email: e.target.value}))} type="email" placeholder="Your Email Address"/>
      </div>
      <div className='c__input-group'>
      <label>Phone Number</label>
      <PhoneInput
            inputStyle={{
                // backgroundColor: "rgb(255 255 255)",
                color: `black`,
                width: '100%'
            }}
            buttonStyle={{ backgroundColor: "rgb(255 255 255)" }}
            enableAreaCodeStretch
            placeholder={"Your Phone Number"}
            country={"us"}
            value={formState['phone']}
            onChange={(changeValue, country, e, formattedValue) => {
                setFormState(s => ({...s, phone: changeValue, phoneData: country}))
            }}
        />
      </div>
      <div className='c__input-group'>
        <label>Message</label>
        <textarea  value={formState['message']} onChange={(e) => setFormState(s => ({...s, message: e.target.value}))} placeholder="Your Message..."/>
      </div>
      <button
              className="c__sumbit-btn"
              onClick={handleSumbit}
            >
              Sumbit
            </button>
    </div>
    </div>
  )
}

export default ContactUs