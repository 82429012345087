import { Image, Tooltip } from "antd";
import truncate from "truncate";
import defaultIimg from "../../../../assets/images/default.png";

export default function SingleOrderProduct(props) {
  return (
    <>
      <div className="single-product __chek_pdt px-3">
        <div className="img-wrap">
          <Image
            src={
              props.item.product_photo
              ||
              defaultIimg
            }
            preview={false}
            alt="product"
            width={80}
            style={{
              borderRadius: "2px",
              border: "1px solid #eee",
            }}
          />
        </div>
        <div className="sp-cont ml-md-4 ml-3">
          <Tooltip
            title={props.item.product_name}
            color="#275DAD"
            placement="bottom"
          >
            <h6 className="__chek_h6">
              {truncate(props.item.product_name, 35) || "N/A"}
            </h6>
          </Tooltip>
          <p className="mb-1 font-weight-bold ">
            {`$${props.item.product_price}` || "N/A"}
          </p>
          <span className="price small">
            {`Qty: ${props.item.product_quantity}` || 0}
          </span>
        </div>
      </div>
    </>
  );
}
