import {
  privateAgent,
  publicAgent,
  CancelToken,
} from "../../configurations/AxiosAgent";

// const addSiteAdminCommentApi = (body) => {
//   return privateAgent.post(`/system/v1/addSystemAdminComment`, {
//     ...body,
//   });
// };

/// Public Apis
const productsListApi = (body) => {
  console.log("productsListApi body >>", body);
  // return publicAgent.post(`/product/homepage`, {
  // 		...body,
  // });
  return publicAgent.post(`/api/product/homepage`, {
    ...body,
  });
};

const singleProductApi = (body) => {
  console.log("singleProductApi body >>", body);
  // return publicAgent.post(`/product/singleproduct`, {
  // 		...body,
  // });
  return publicAgent.post(`/api/product/singleproduct`, {
    ...body,
  });
};
const registerApi = (body) => {
  console.log("registerApi body >>", body);
  //   return publicAgent.post(`/auth/normalregister`, {
  //     ...body,
  //   });
  return publicAgent.post(`/api/auth/normalregister`, {
    ...body,
  });
};

const loginApi = (body) => {
  return publicAgent.post(`/api/auth/normallogin`, {
    ...body,
  });
};
const sendOtpApi = (body) => {
  return publicAgent.post(`/api/auth/sendotp`, {
    ...body,
  });
};
const verifyOtpApi = (body) => {
  return publicAgent.post(`/api/auth/verifyotp`, {
    ...body,
  });
};
const addCartApi = (body) => {
  return publicAgent.post(`/api/cart/addcart`, {
    ...body,
  });
};
const viewCartApi = (body) => {
  return publicAgent.post(`/api/cart/viewcart`, {
    ...body,
  });
};
const deleteCartApi = (body) => {
  return publicAgent.post(`/api/cart/deleteCart`, {
    ...body,
  });
};
const deleteCartItemApi = (body) => {
  return publicAgent.post(`/api/cart/deleteCartItem`, {
    ...body,
  });
};
const incrementInCartApi = (body) => {
  return publicAgent.post(`/api/cart/cartIncrement`, {
    ...body,
  });
};
const decrementInCartApi = (body) => {
  return publicAgent.post(`/api/cart/cartDecrement`, {
    ...body,
  });
};
const userprofileApi = (body) => {
  return publicAgent.post(`/api/user/userprofile`, {
    ...body,
  });
};
const updateprofileApi = (body) => {
  return publicAgent.post(`api/user/updateprofile`, {
    ...body,
  });
};
const createWalletApi = (body) => {
  return publicAgent.post(`/wallet/createWallet`, {
    ...body,
  });
};
const updatewalletApi = (body) => {
  return publicAgent.post(`/wallet/updatewallet`, {
    ...body,
  });
};
const getWalletApi = (body) => {
  return publicAgent.post(`/wallet/getWallet`, {
    ...body,
  });
};
const addReviewApi = (body) => {
  return publicAgent.post(`/product/addReview`, {
    ...body,
  });
};
const getReviewApi = (body) => {
  return publicAgent.post(`/product/getReview`, {
    ...body,
  });
};
const checkoutApi = (body) => {
  return publicAgent.post(
    `https://api.eamr.life/product/payment/pay`,
    {
      ...body,
    }
  );
};
const createOrderApi = (body) => {
  return publicAgent.post(`/api/orders/addorder`, {
    ...body,
  });
};
const viewOrdersApi = (body) => {
  return publicAgent.post(`/api/orders/vieworders`, {
    ...body,
  });
};
const orderDetailsApi = (body) => {
  return publicAgent.post(`/orders/orderdetails`, {
    ...body,
  });
};
/// Wishlist Apis
const addWishlistApi = (body) => {
  console.log("Wishlist Body ==> ", body);
  // return publicAgent.post(`/orders/addwishlist`, {
  // 		...body,
  // });
  return publicAgent.post(`/api/list/addwishlist`, {
    ...body,
  });
};
const viewWishlistApi = (body) => {
  return publicAgent.post(`/api/list/viewlist`, {
    ...body,
  });
};
const deleteWishlistApi = (body) => {
  return publicAgent.post(`/list/deletewishlist`, {
    ...body,
  });
};

const addDealApi = (body) => {
  return publicAgent.post(`/api/deals/adddeal`, {
    ...body,
  });
};

const paypalPaymentApi = (body) => {
  return privateAgent.post(`/api/payment/pay`, {
    ...body,
  });
};
/// Private Apis

export {
  registerApi,
  loginApi,
  sendOtpApi,
  verifyOtpApi,
  addCartApi,
  viewCartApi,
  deleteCartApi,
  deleteCartItemApi,
  incrementInCartApi,
  decrementInCartApi,
  userprofileApi,
  updateprofileApi,
  createWalletApi,
  updatewalletApi,
  getWalletApi,
  addReviewApi,
  getReviewApi,
  productsListApi,
  singleProductApi,
  checkoutApi,
  createOrderApi,
  viewOrdersApi,
  orderDetailsApi,
  addWishlistApi,
  viewWishlistApi,
  deleteWishlistApi,
  addDealApi,
  paypalPaymentApi,
};
