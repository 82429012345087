import React from "react";

import LoginForm from "./form";
import Auth from "../../Auth";

const Login = () => {
    return (
        <Auth title="Welcome Back!" subTitle="Please enter your phone number">
            <LoginForm />
        </Auth>
    );
};

export default Login;
