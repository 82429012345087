import * as Yup from 'yup';
import { parse, isAfter, addDays, isValid, isDate, addYears } from 'date-fns';

// minDate={addYears(Date.now(), -100)}
// maxDate={addYears(Date.now(), -13)}

const today = new Date();
// const minAgeLimit = addYears(Date.now(), -13);
// const maxAgeLimit = addYears(Date.now(), -100);

const signupValidations = {
  name: Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name too short! it must be between 3 and 20 characters')
      .max(20, 'Name Too long! it must be between 3 and 20 characters')
      .required('Name Required'),
  }),
  email: Yup.object().shape({
    email: Yup.string().email('Invalid email!').required('Email Required!'),
  }),
  phone: Yup.object().shape({
    phone: Yup.string()
      .min(9, 'Phone number is too short!')
      .max(18, 'Phone number is Too long!'),
    // .required("Phone number is Required"),
  }),
  dateOfBirth: Yup.object().shape({
    dateOfBirth: Yup.string().test({
      name: 'dateOfBirth',
      message: 'Invalid Date of Birth Error',
      exclusive: false,
      test: function (value) {
        const { path, createError } = this;
        let minAgeLimit = addYears(Date.now(), -13);
        let isOldEnough = isAfter(Date.parse(minAgeLimit), Date.parse(value));
        let isValidDate = isValid(Date.parse(value));
        if (!(isValidDate && isOldEnough)) {
          console.log('validate min age >>', isValidDate, isOldEnough);
          return createError({
            path,
            message: 'Your age must be older then 13 years.',
          });
        } else {
          return true;
        }
      },
    }),
    // .required("Required!"),
  }),
  address: Yup.object().shape({
    address: Yup.string()
      .min(6, 'Address too short!')
      .max(45, 'Address Too long!')
      .required('Address Required'),
  }),
  refCode: Yup.object().shape({
    refCode: Yup.string().min(6, 'Referral code is not valid!'),
  }),
};

const editProfileValidations = {
  name: Yup.string()
    .min(3, 'Name too short! it must be between 3 and 20 characters')
    .max(20, 'Name Too long! it must be between 3 and 20 characters'),
    // .required('Name Required'),

  email: Yup.string().email('Invalid email!'),
//   .required('Email Required!'),

  phone: Yup.string()
    .min(9, 'Phone number is too short!')
    .max(18, 'Phone number is Too long!'),
  // .required("Phone number is Required"),

  address: Yup.string()
    .min(6, 'Address too short!')
    .max(45, 'Address Too long!'),
  // .required("Address Required"),

  refCode: Yup.string().min(6, 'Referral code is not valid!'),
};

const sendOtpValidation = Yup.object().shape({
  phone: Yup.string()
    .min(9, 'Phone number is too short!')
    .max(18, 'Phone number is Too long!')
    .required('Phone number is Required'),
});
const verifyOtpValidation = Yup.object().shape({
  code: Yup.string()
    .min(5, 'OTP Code is too short!')
    .max(7, 'OTP Code is Too long!')
    .required('OTP Code is Required'),
});

const loginValidations = Yup.object().shape({
  username: Yup.string()
    .min(5, 'Username Too short!')
    .max(16, 'Username Too long!')
    .required('Username Required'),

  password: Yup.string()
    .min(5, 'Password is Too Short!')
    .max(16, 'Password is Too Long!')
    .required('Password Required'),
});
export {
  signupValidations,
  sendOtpValidation,
  verifyOtpValidation,
  loginValidations,
  editProfileValidations,
};
