import { serverurl } from "../helper";
const base_url = serverurl;
const axios = require("axios").default;

let Web_Apis = {
  productList() {
    // console.log('productList',)
    return axios
      .post(
        base_url + "/product/homepage",
        JSON.stringify({ pageno: "1", limit: "10" }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then((response) => {
        return response.data;
      });
  },

  c(product_id) {
    // console.log('productList',)
    return axios
      .post(base_url + "/api/product/singleproduct", { product_id })
      .then((response) => {
        return response.data;
      });
  },
  productsUpdate(id) {
    // console.log('productList',)
    return axios
      .get(base_url + "/api/cart/cartlookup", {    profile_id: id })
      .then((response) => {
        return response.data;
      });
  },

  signUp(data) {
    // console.log('productList',)
    return axios
      .post(base_url + "/auth/normalregister", data)
      .then((response) => {
        return response.data;
      });
  },
  sendOtp(data) {
    // console.log('productList',)
    return axios.post(base_url + "/auth/sendotp", data).then((response) => {
      return response.data;
    });
  },

  //Deals API
  fetchUserDeals(data) {
    return axios
      .post(base_url + "/api/deals/fetchuserdeals", data)
      .then((res) => res.data);
  },
  addUserLiveDeal(data) {
    return axios
      .post(base_url + "/api/deals/adddeal", data)
      .then((res) => res.data);
  },
  liveDealDetails(data) {
    return axios
      .post(base_url + "/api/deals/fetchdeal", data)
      .then((res) => res.data);
  },
  liveDealResults(data) {
    return axios
      .post(base_url + "/api/deals/fetchdealresults", data)
      .then((res) => res.data);
  },
  updateDeal(data) {
    return axios
      .post(base_url + "/api/deals/updatedeal", data)
      .then((res) => res.data);
  },
  deleteDeal(data) {
    return axios
      .post(base_url + "/api/deals/deletedeal", data)
      .then((res) => res.data);
  },
  deleteDealNotification(data) {
    return axios
      .post(base_url + "/api/deals/deletedealnotification", data)
      .then((res) => res.data);
  },
  dealAlert(data) {
    return axios
      .post(base_url + "/api/deals/dealalert", data)
      .then((res) => res.data);
  },

  //Products API
  fetchProducts(data) {
    return axios
      .post(base_url + "/api/product/homepage", data)
      .then((res) => res.data);
  },
  //Products for Live deal results API
  fetchProductsForLiveResults(data) {
    return axios
      .get(base_url + "/api/product/liveresults", data)
      .then((res) => res.data);
  },
  //Ftech related Products
  fetchRelatedProducts(data) {
    return axios
      .post(base_url + "/api/product/relatedproducts", data)
      .then((res) => {
        return res.data;
      });
  },
  //fetch product buyers
  fetchbuyers(data) {
    return axios
      .post(base_url + "/api/product/fetchbuyers", data)
      .then((res) => {
        return res.data;
      });
  },
  //add product review
  addProductReview(data) {
    return axios.post(base_url + "/api/product/addReview", data).then((res) => {
      return res.data;
    });
  },
  //get product review
  getProductReview(data) {
    return axios.post(base_url + "/api/product/getReview", data).then((res) => {
      return res.data;
    });
  },
  getMyReviews(data) {
    return axios.post(base_url + "/api/user/reviews", data).then((res) => {
      return res.data;
    });
  },

  //Chats
  getUserGroups(data) {
    return axios
      .post(base_url + "/api/chats/getusergroups", data)
      .then((res) => {
        return res.data;
      });
  },
  //Create User Group
  createUserGroup(data) {
    return axios.post(base_url + "/api/chats/creategroup", data).then((res) => {
      return res.data;
    });
  },
  getProductChats(data) {
    return axios
      .post(base_url + "/api/chats/getuserproductchats", data)
      .then((res) => {
        return res.data;
      });
  },
  getLastChats(data) {
    return axios
      .post(base_url + "/api/chats/getuserlastchats", data)
      .then((res) => {
        return res.data;
      });
  },

  // Cart APIs
  deleteUserCarts(data) {
    return axios
      .post(base_url + "/api/cart/deleteUserCart", data)
      .then((res) => {
        return res.data;
      });
  },

  //Notifications API
  getNoifications(data) {
    return axios
      .post(base_url + "/api/user/notifications", data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  //Unread Notifications API
  getUnreadNotifications(data) {
    return axios
      .post(base_url + "/api/user/unreadnotifications", data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  //reviews management
  createReview(data) {
    return axios.post(base_url + "/api/reviews/addreview", data).then((res) => {
      return res.data;
    });
  },

  getUserReviews(data) {
    return axios
      .post(base_url + "/api/reviews/getprofilereviews", data)
      .then((res) => {
        return res.data;
      });
  },
  getProductReviews(data) {
    return axios
      .post(base_url + "/api/reviews/getproductreviews", data)
      .then((res) => {
        return res.data;
      });
  },

  addProductOrders(data) {
    return axios.post(base_url + "/api/orders/addorder", data).then((res) => {
      return res.data;
    });
  },

  getUserOrders(data) {
    return axios.post(base_url + "/api/orders/vieworders", data).then((res) => {
      return res.data;
    });
  },

  updateOrderStatus(data) {
    return axios.post(base_url + "/api/orders/updatestatus", data).then((res) => {
      return res.data;
    });
  },

  vieworderdetails(data) {
    return axios
      .post(base_url + "/api/orders/vieworderdetails", data)
      .then((res) => {
        return res.data;
      });
  },
};

export default Web_Apis;
