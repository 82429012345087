import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../../store/actions/Modals";

const BasicModal = (props) => {
  // console.log("Basic modal props", props);
  return (
    <Modal backdrop= {'static'} show={props.visible} onHide={props.exitModal}>
      <Modal.Header closeButton>
        {props.title === null ? null : <Modal.Title>{props.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      {props.cancelText === null || props.okText === null ? null : (
        <>
          <Modal.Footer>
            <button variant="secondary" onClick={props.exitModal}>
              {props.cancelText}
            </button>
            <button variant="primary">{props.okText}</button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    exitModal: () => {
      dispatch(closeModal());
    },
  };
};

export default connect(null, mapDispatchToProps)(BasicModal);
