import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import {
  toggleOpen,
  logoutUser,
  // deleteCartActionRequest,
  incrementCartActionRequest,
  decrementCartActionRequest,
  deleteCartItemActionRequest,
  viewCartActionRequest,
} from '../../../store/actions/user';
import { openModal, closeModal } from '../../../store/actions/Modals';
import CartItem from './singleItem';
import cogoToast from 'cogo-toast';
import { PrimaryBtn, SecBtn } from '../../../component/button/Button';
import NoResult from '../Error/NoResult';
import Layout from '../Layouts/PageLayouts';
import axios from 'axios';
import { serverurl } from '../../../helper';

const Cart = (props) => {
  // console.log("Cart props >>", props.userCart);
  const [loading, setLoading] = useState(true);
  const [cartCount, setCartCount] = useState(0);
  const [triggerUpdateModalOpen, setTriggerUpdateModalOpen] = useState(false);
  const { email } = useSelector((state) => state?.user?.userData);
  // trriger ui to refresh due to price chage
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTriggerUpdateModalOpen(true);
  //   }, 7000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    let requestBody = {
      profile_id: email,
    };
    const fetch = async () => {
      await axios
        .post(`${serverurl}/api/cart/cartlookup`,
         
          requestBody
        )
        .then((response) => {
          if(response?.data?.data?.length ) { return setTriggerUpdateModalOpen(true);}
        })
        .catch((error) => {setTriggerUpdateModalOpen(false);});
    };
    fetch();
    // console.log(localStorage.session, 'update');
  }, [email]);


  // loadUserCart
  useEffect(() => {
 try{

   if (props.user && props.user.email) {
     let requestBody = {
       profile_id: props.user.email,
     };
     props.loadUserCart(requestBody);
   }
   setLoading(false)
 }catch(e){
  console.error(e)
 }
  }, []);

  const incrementCartItem = (id) => {
    console.log('incrementCartItem id/sno', id);
    if (props.user && props.user.email) {
      let requestBody = {
        sno: id,
        profile_id: props.user.email,
      };
      props.incrementCartActionRequest(requestBody, () => {
        props.loadUserCart(requestBody);
      });
    } else {
      cogoToast.error('Please Login to perform this action.');
    }
  };
  const decrementCartItem = (item) => {
    console.log('decrementCartItem id/sno', item.sno);
    if (props.user && props.user.email) {
      let requestBody = {
        sno: item.sno,
        profile_id: props.user.email,
      };
      if (item.product_quantity <= 1) {
        props.deleteCartItemActionRequest({ sno: item.sno }, () => {
          props.loadUserCart(requestBody);
        });
      } else {
        props.decrementCartActionRequest(requestBody, () => {
          props.loadUserCart(requestBody);
        });
      }
      // props.decrementCartActionRequest(requestBody, () => {
      //   props.loadUserCart(requestBody);
      // });
    } else {
      cogoToast.error('Please Login to perform this action.');
    }
  };

  const deleteCartItem = (id) => {
    console.log('deleteCartItem id/sno', id);
    let requestBody = {
      sno: id,
    };
    props.deleteCartItemActionRequest(requestBody, () => {
      if (props.user && props.user.email) {
        let requestBody = {
          profile_id: props.user.email,
        };
        props.loadUserCart(requestBody);
      }
    });
  };


  if (loading) {
    return <div className="dots-page"><div className="dots"></div></div>
  }

  if (props.userCart && props.userCart.length <= 0) {
    return <Layout>
    <div className='container large cart-container'>
      <div className='row'>
        <div className='col-12 review-title-wrap mb-4'>
          <h1>Cart</h1>
          <SecBtn
            text='Refresh'
            onClick={() => {
              setCartCount(cartCount + 1);
            }}
          />
        </div>
      </div>
    </div>
    <NoResult
      desc='Your cart is Empty. Please add products.'
      link='/'
      text='Add products'
    />
  </Layout>
  }

    return (
      <Layout styl={{ background: '#e2eef1', paddingBottom: '150px' }}>
        <Modal
          // className={props.className}
          title={'Product Update'}
          visible={triggerUpdateModalOpen}
          open={triggerUpdateModalOpen}
          okText={props.okText}
          onCancel={() => setTriggerUpdateModalOpen(false)}
          width={'50%'}
          footer={null}
        >
          <div className="productsList-modal">

          <h4>{"There has been a change in price"}</h4>
          <div className='buttons-list'>
            <Link to='/products-update'>

            <Button type='primary' size={'medium'}>
              View change
            </Button>
            </Link>
            <Button
              type='primary'
              danger
              onClick={() => setTriggerUpdateModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
          </div>
        </Modal>
        <div className='container-fluid px-md-5 mx-lg-5 large cart-container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-12 review-title-wrap mb-4'>
                  <h1>Cart</h1>
                  <SecBtn
                    text='Refresh'
                    onClick={() => {
                      setCartCount(cartCount + 1);
                    }}
                  />
                </div>
                <div className='col-12'>
                  <div className='table-responsive bg-white'>
                    <table className='table table-hove cart-table __table_cart w-100 '>
                      <thead>
                        <tr>
                          <th className='py-4'>Item &amp; Description</th>
                          <th className='py-4'>Quantity</th>
                          <th className='py-4 text-center'>Shipping method</th>
                          <th className='py-4'></th>
                          <th className='py-4'>Total price</th>
                        </tr>
                      </thead>
                      {props.userCart && props.userCart.length > 0 ? (
                        <tbody>
                          {props.userCart.map((item) => {
                            return (
                              <CartItem
                                deleteItem={() => {
                                  deleteCartItem(item.sno);
                                }}
                                incrementItem={() => {
                                  incrementCartItem(item.sno);
                                }}
                                decrementItem={() => {
                                  decrementCartItem(item);
                                }}
                                item={item}
                              />
                            );
                          })}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='align-middle'>
                              <h6>Sub Total:</h6>
                              <h6 className='mt-3'>Tax:</h6>
                            </td>
                            <td className='align-middle'>
                              <h6>{`$${props.orderAmount}`}</h6>
                              <h6 className='mt-3'>{`7%(inclusive)`}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='align-middle'>
                              <h6
                                style={{ fontSize: '1.2rem', fontWeight: 700 }}
                              >
                                Total
                              </h6>
                            </td>
                            <td className='align-middle'>
                              <h6
                                style={{ fontSize: '1.2rem', fontWeight: 700 }}
                              >{`$${props.orderAmount.toFixed(2)}`}</h6>
                            </td>
                          </tr>
                        </tbody>
                      ) : null}
                    </table>
                  </div>
                  <div className='row d-flex justify-content-end align-items-end mt-5'>
                    <div className='col-sm-6 order-2 order-sm-1 mt-3 mt-sm-0'>
                      <Link to='/'>
                        <SecBtn block text='CONTINUE SHOPPING' />
                      </Link>
                    </div>
                    <div className='col-sm-6 order-1 order-sm-2'>
                      <Link to='/order-summary'>
                        <PrimaryBtn block text='PROCEED TO CHECKOUT' />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  
};

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status == 1
      ? true
      : false,
  userCart: state.user.userCart,
  orderAmount: state.user.orderAmount,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getModal: (...args) => {
      dispatch(openModal(...args));
    },
    closeModals: () => {
      dispatch(closeModal());
    },
    loadUserCart: (...args) => {
      dispatch(viewCartActionRequest(...args));
    },
    deleteCartItemActionRequest: (...args) => {
      dispatch(deleteCartItemActionRequest(...args));
    },
    incrementCartActionRequest: (...args) => {
      dispatch(incrementCartActionRequest(...args));
    },
    decrementCartActionRequest: (...args) => {
      dispatch(decrementCartActionRequest(...args));
    },
    toggleOpen: (status) => dispatch(toggleOpen(status)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
