import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../component/input";
import SelectWrapper from "../../component/input/Select";
import OptionsWrapper from "../../component/input/SelectOptions";
import Blip from "../../appcomponents/blip";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";
// import {
//   AddButton,
//   StyledButton,
// } from "../../component/styled-component/styledButton";
import "./shell.css";

//Need to make this responsive
let k = 0;
const ContentHeader = (props, { match }) => {
  let history = useHistory();
  console.log("Window >>> ", window.location.pathname);
  const pathname = window.location.pathname;
  return (
    <Row type="flex" align="center" justify="space-between" span={24}>
      <Col style={{ marginBottom: 10 }} span={10}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="heading"
            style={{ fontWeight: "bold", color: "#1D194D" }}
          >
            {props.title}
          </span>
          {props.count ? <Blip color="#49D3CE" /> : null}
          <span
            className="small"
            style={{ fontWeight: "bold", fontSize: "20px", color: "#1D194D" }}
          >
            {props.count}
          </span>
        </div>
      </Col>
      <Col span={14}>
        <Row span={24} justify="end">
          <Col style={{ marginBottom: 10 }} span={8} style={{ marginRight: 8 }}>
            <InputWrapper
              name="textbox"
              prefix={<FiSearch />}
              allowClear={true}
              placeholder="Search"
              onChange={props.handleTextSearch}
            />
          </Col>

          {/* ========Select search====== */}
          {props.options ? (
            <Col>
              <SelectWrapper
                defaultValue={"View All"}
                style={{
                  width: 130,
                  border: "1px solid #464797 !important",
                  textAlign: "left",
                }}
                onChange={props.handleSelectSearch}
              >
                {props.options
                  ? props.options.map((val) => {
                      return (
                        <OptionsWrapper value={val.value} key={k}>
                          {val.label}
                        </OptionsWrapper>
                      );
                      k++;
                    })
                  : null}
              </SelectWrapper>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ContentHeader;
