import { NavLink, Link } from "react-router-dom";

// Navlinks
export const NavvLink = ({ to, item, children, className, ...rest }) => (
    <>
        <NavLink
            exact
            to={to}
            className={({ isActive }) =>
                `${className} ___navlink ${isActive ? "active" : ""}`
            }
            {...rest}
        >
            {item ? item : children}
        </NavLink>
    </>
);

// Tab active links
export const BlueNav = ({ to, item, children, className, ...rest }) => (
    <>
        <Link
            exact
            to={to}
            className={({ isActive }) =>
                `${className} ___navlink ${isActive ? "tab_active" : ""}`
            }
            {...rest}
        >
            {item ? item : children}
        </Link>
    </>
);
