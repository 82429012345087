import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleSubmit, validatePhone } from "./services";

export const usePhone = (
    initialValues = { phone: "", error: "", phoneData: {} }
) => {
    const dispatch = useDispatch();
    const [phone, setPhone] = useState(initialValues.phone);
    // if error is null, phone has been validated and declared valid
    // if error is "", not yet validated
    // then another other string value of error is the error message
    const [error, setError] = useState(initialValues.error);
    const [phoneData, setPhoneData] = useState(initialValues.phoneData);

    const onInputChangeAction = (phoneValue, phoneDataValue) => {
        setPhone(phoneValue);
        setPhoneData(phoneDataValue);
        validatePhone(phone, setError);
    };

    const onSubmitAction = () => handleSubmit({ phone, phoneData }, dispatch);

    return [
        { phone, error, setPhone },
        { onInputChangeAction, onSubmitAction },
    ];
};
