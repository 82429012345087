import React from 'react'
import { Menu } from 'antd'

const SortingMenu = ({ handleClick }) => {
  return (
    <Menu style={{ padding: '0 8px' }}>
      <Menu.Item key="1" onClick={() => handleClick('Search')}>
        {`Search`}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleClick('Min Price')}>
        Min Price
      </Menu.Item>{' '}
      <Menu.Item key="3" onClick={() => handleClick('Max Price')}>
        Max Price
      </Menu.Item>{' '}
      <Menu.Item key="4" onClick={() => handleClick('Result')}>
        Result
      </Menu.Item>
      <Menu.Item key="5" onClick={() => handleClick('Date Added')}>
        {`Date Added`}
      </Menu.Item>
    </Menu>
  )
}

export default SortingMenu
