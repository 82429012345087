import {
  MODAL_CLOSE,
  UPDATE_SYNC_ERRORS,
  TOGGLE_OPEN,
  AUTH_STATUS,
  MESSAGE_FROM_BACKEND,
} from "./actions";
import apis from "./../../apis";
import { toast } from "react-toastify";
let Modal = {
  ModalAction(data) {
    console.log(data, "dtaataaAction");
    return (dispatch) => {
      dispatch({ type: MODAL_CLOSE, payload: data });
      if (data == "1") {
        dispatch({
          type: UPDATE_SYNC_ERRORS,
          error: "",
          meta: { form: "login" },
          payload: {
            syncErrors: { mobile_number: "Enter Your Mobile Number" },
          },
        });
      }
    };
  },
  RegisterValues(data) {
    let req_data = {
      name: data.full_name,
      dialing_code: "+91",
      phonenumber: data.mobile_number,
      email: data.email,
      dob: "07/08/1994",
      gender: "Male",
      address: data.address,
      type: 0,
      device_token: "webToken",
      device_type: "web",
    };
    let message = "";
    return (dispatch) => {
      apis
        .signUp(req_data)
        .then(
          (response) => {
            console.log(response, "sign_up_api");
            if (response.status == "200") {
              message = response.message;
              dispatch({ type: MODAL_CLOSE, payload: 0 });
              dispatch({ type: AUTH_STATUS, payload: 1 });
              dispatch({ type: TOGGLE_OPEN, payload: 0 });
              dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
              toast(message);
            } else {
              message = response.message;
              dispatch({ type: AUTH_STATUS, payload: 0 });
              dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
              toast(message);
            }
          },
          (failuer) => {
            dispatch({ type: AUTH_STATUS, payload: 0 });
            dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
            toast(message);
          }
        )
        .catch((error) => {
          dispatch({ type: AUTH_STATUS, payload: 0 });
          dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
          toast(message);
        });
      // dispatch({ type: MODAL_CLOSE, payload: 0 })
      // dispatch({ type: AUTH_STATUS, payload: 1 })
      //dispatch({ type: TOGGLE_OPEN, payload: 0 })
    };
  },

  sendOTP(data) {
    let req_data = {
      dialing_code: "+91",
      phonenumber: data.mobile_number,
    };
    let message = "";
    return (dispatch) => {
      apis
        .sendOtp(req_data)
        .then(
          (response) => {
            console.log(response, "sign_up_api");
            if (response.status == "200") {
              message = response.message;
              dispatch({ type: MODAL_CLOSE, payload: 0 });
              dispatch({ type: AUTH_STATUS, payload: 1 });
              dispatch({ type: TOGGLE_OPEN, payload: 0 });
              dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
              toast(message);
            } else {
              message = response.message;
              dispatch({ type: AUTH_STATUS, payload: 0 });
              dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
              toast(message);
            }
          },
          (failuer) => {
            dispatch({ type: AUTH_STATUS, payload: 0 });
            dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
            toast(message);
          }
        )
        .catch((error) => {
          dispatch({ type: AUTH_STATUS, payload: 0 });
          dispatch({ type: MESSAGE_FROM_BACKEND, payload: message });
          toast(message);
        });
      // dispatch({ type: MODAL_CLOSE, payload: 0 })
      // dispatch({ type: AUTH_STATUS, payload: 1 })
      //dispatch({ type: TOGGLE_OPEN, payload: 0 })
    };
  },

  logOut(data) {
    return (dispatch) => {
      dispatch({ type: AUTH_STATUS, payload: 0 });
    };
  },
};

export default Modal;
