import React from "react";
import styled, { css } from "styled-components";
import { Input, Select, Icon } from "antd";
import OptionsWrapper from "../../component/input/SelectOptions";

const InputStyles = css`
width: 100%;


.ant-select, .ant-select-selector{
  height: 100px;
  box-shadow: ${(props) =>
      props.style ? props.style.boxShadow : "#54ECE6 !important"};
  border: ${(props) =>
      props.style ? props.style.border : " 1px solid #49D3CE !important"};
  border-radius: 3px !important;
}

.ant-select-selection {
  color: ${(props) => props.theme[props.theme.mode].textColor.primary};
  background-color: ${(props) =>
      props.theme[props.theme.mode].background.primary};
  //border: 1px solid ${(props) => props.theme[props.theme.mode].primary.main};
  border: none !important;
  box-shadow: 0 4px 14px -5px rgba(0,0,0,0.15);
  //${(props) => (props.appStyles ? props.appStyles[0] : null)};
  //height: 38px;
}

//${(props) => (props.appStyles ? props.appStyles[1] : null)};
.ant-select-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
}

.ant-select-arrow {
  color: ${(props) => props.theme[props.theme.mode].primary.main};
}

.ant-select-dropdown-menu {
  background-color: ${(props) =>
      props.theme[props.theme.mode].background.primary};
}

.ant-select-dropdown-menu-item {
  display: flex;
  align-items: center;
  height: 40px;
  color: ${(props) => props.theme[props.theme.mode].textColor.primary};

}

.ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
  background-color: ${(props) => props.theme[props.theme.mode].primary.light};
}
  //   color: palevioletred;
  //   font-weight: bold;
  //   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);

  .ant-select-arrow{
    color: ${(props) => (props.dropdownColor ? props.dropdownColor : "#464797")}
  }
`;

const SelectWrapper = (props) => {
    // console.log("PROPS AGAIN:", props);
    return (
        <Select
            size="large"
            defaultValue={props.defaultValue}
            className={props.className}
            dropdownClassName={props.className}
            {...props}
        ></Select>
    );
};

export default styled(SelectWrapper)`
    ${InputStyles}
`;

// const StyledSelector = styled(Select).attrs(() => ({ size: "large" }))`
//   width: 100% !important;
//   border: 1px solid #49d3ce;
//   border-radius: 6px;
//   outline: none;
//   // height: 40px;
//   padding: 0 8px;

//   .select-items,
//   option {
//     backgorund-color: red;
//     height: 40px;
//     border: none;
//   }

//   &:focus {
//     box-shadow: #54ece6;
//   }
// `;

// const SelectWrapper = (props) => {
//   console.log("SHOW ALL THE PROPS IN SELECT", props);
//   return <StyledSelector {...props}></StyledSelector>;
// };

// export default SelectWrapper;
