import React, { useEffect } from "react";
import { connect } from "react-redux";
import WebRouter from "./router";
import { withRouter } from "react-router-dom";
import RootModal from "./component/Modal";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { theme } from "./configurations/Theme";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
const RouteApp = connect(mapStateToProps, mapDispatchToProps)(WebRouter);
const mobileMenuItems = [
    "#/cart",
    "#/live",
    "#/chat",
    "#/profile",
    "#/terms",
    "#/reviews",
    "#/",
];

const App = (props) => {
    // console.log("DEVELOPER >>", process.env.REACT_APP_DEVELOPER);
    // console.log("API Base URL >>", process.env.REACT_APP_API_DEV_URL);
    // console.log("Images path >>", process.env.REACT_APP_PRODUCTIMAGE_BASEPATH);
    useEffect(() => {
        // console.log("App Refresh router >>", props.location.hash);
        let closeMenuBtn = document.querySelector("#react-burger-cross-btn");
        if (
            closeMenuBtn &&
            mobileMenuItems.indexOf(`${props.location.hash}`) >= 0
        ) {
            let isMenuOpen = closeMenuBtn.getAttribute("tabindex");
            // console.log("isMenuOpen >>", isMenuOpen);
            if (isMenuOpen == 0) {
                closeMenuBtn.click();
            }
        }
    });
    return (
        <ThemeProvider theme={{ ...theme, mode: props.app.mode }}>
            <RouteApp />
            <RootModal />
        </ThemeProvider>
    );
};

export default connect((storeState) => ({ app: storeState.App }))(
    withRouter(App)
);

// 1.3 dots in uct details page
// 2.see Wishlist product detail page
// 3.e wallet and card details
// 4.change address checkout page
// 5.profile page edit
