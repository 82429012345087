import React, { lazy, Suspense, useEffect } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "../container/Pages/Home/Home";
import Login from "../container/Pages/Login";
import SignUp from "../container/Pages/SignUp";
import ProductList from "../container/Pages/Product/ProductList";
import ProductDetail from "../container/Pages/Product/ProductDetail";
import ProductReview from "../container/Pages/Product/ProductReview";
import Cart from "../container/Pages/Cart";
import Wishlist from "../container/Pages/Wishlist/Wishlist";
import OrderPaid from "../container/Pages/Order/OrderPaid";
import OrderSummary from "../container/Pages/Order/orderSummary/index";
import Live from "../container/Pages/Live/Live";
import LiveDetail from "../container/Pages/Live/LiveDetail";
import Chat from "../container/Pages/Chat/Chat";
import UpdateLive from "../container/Pages/Live/UpdateLive";
import Profile from "../container/Pages/MyPages/Profile";
import ViewOrder from "../container/Pages/Order/ViewOrder";
import AddLive from "../container/Pages/Live/AddLive";
import OrderList from "../container/Pages/Order/OrderList";
import AddReview from "../container/Pages/Product/AddReview";
import ComingSoon from "../container/Pages/Wallet/comingSoon";
// import Wallet from "../container/Pages/Wallet/Wallet";
import TopUp from "../container/Pages/Wallet/TopUp";
import Notification from "../container/Pages/Notification/Notification";
import Terms from "../container/Pages/Terms/terms";
import Shell from "../container/shell/Shell";
import Reviews from "../container/Pages/Reviews/index";
import Error404 from "../container/Pages/Error/Error404";
import Error500 from "../container/Pages/Error/Error500";
import ProductReviewList from "../container/Pages/Product/ProductReviewList";
import MyReviews from "../container/Pages/MyPages/MyReviews.js";
import ContactUs from "../container/Pages/ContactUs";
// import ProductsUpdate from "../container/Pages/Product/ProductsUpdate";
import productsList from "../container/Pages/Product/ProductsUpdate";

const Routes = (props) => {
  console.log("ROUTER HOME", props)
    return (
        <>
            {props.location.pathname &&
                !/(signup|login|dashboard)/.test(
                    props.location.pathname
                ) && <Home />}
            <Switch>
                <Route path="/" exact component={ProductList} />
                <Route path="/login" exact component={Login} />
                <Route path="/signup" exact component={SignUp} />
                <Route path="/product-detail/:id" component={ProductDetail} />
                <Route exact path="/order-paid/:id" component={OrderPaid} />
                {/* <Route path="/product-review" exact component={ProductReviewList} /> */}
                <Route path="/product-review/:id" component={ProductReview} />
                <Route path="/add-review/:id" component={AddReview} />
                <Route path="/myreviews" component={MyReviews} />
                <Route path="/reviews" component={Reviews} />

                <Route path="/cart" component={Cart} />
                <Route path="/products-update" component={productsList} />
                <Route path="/wishlist" component={Wishlist} />
                {/* <Route exact path="/order-paid/:id" component={OrderPaid} /> */}
                <Route path="/order-summary" component={OrderSummary} />
                <Route path="/order-list" component={OrderList} />
                <Route path="/view-order/:id" component={ViewOrder} />

                <Route path="/wallet" component={ComingSoon} />
                {/* <Route path="/wallet" component={Wallet} /> */}
                <Route path="/top-up" component={TopUp} />

                <Route path="/live" component={Live} />
                <Route path="/live-detail" component={LiveDetail} />
                <Route path="/update-live" component={UpdateLive} />
                <Route path="/add-live" component={AddLive} />

                <Route path="/chat" component={Chat} />
                <Route path="/profile" component={Profile} />
                <Route path="/notification" component={Notification} />
                <Route path="/terms" component={Terms} />
                <Route path="/dashboard" component={Shell} />
                <Route path="/support" component={ContactUs} />
                

                <Route path="/server-error" component={Error500} />
                <Route path="/not-found" component={Error404} />
                {/* <Redirect to="/not-found" /> */}
            </Switch>
        </>
    );
};

const mapStateToProps = (state) => ({
    store: state,
    userAuth:
        state.user.userData && state.user.userData.otp_verification_status == 1
            ? true
            : false,
});

export default withRouter(connect(mapStateToProps)(Routes));
