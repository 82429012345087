export const sortViewOrder = (state, sortType, isAscend) => {
  isAscend = isAscend ? 1 : -1;
  let sortFn;

  switch (sortType) {
    case "Name":
      sortFn = (a, b) => isAscend * compare(a.product_name, b.product_name);
      break;

    case "Quantity":
      sortFn = (a, b) =>
        isAscend *
        (parseInt(a.product_quantity) - parseInt(b.product_quantity));
      break;

    case "Price":
      sortFn = (a, b) =>
        isAscend * (parseFloat(a.product_price) - parseFloat(b.product_price));
      break;

    case "Order Time":
      sortFn = (a, b) =>
        isAscend *
        (new Date(a.create_time).getTime() - new Date(b.create_time).getTime());
      break;

    default:
      throw new Error("Unsupported Sort Type");
  }

  console.log("sortFn", sortFn);
  console.log(state.orders);
  return [...(state.orders ?? [])].sort(sortFn);
};

export function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export const filterViewOrder = (orders, filterType) => {
  let filterFn;
  // switch (filterType) {
  //   case "All":
  //     filterFn = (a) => true;
  //     break;

  //   case "Waiting":
  //     filterFn = (a) => a.status === "Waiting";
  //     break;

  //   case "Accepted":
  //     filterFn = (a) => a.status === "Accepted";
  //     break;

  //   case "Rejected":
  //     filterFn = (a) => a.status === "Rejected";
  //     break;

  //   case "Waiting for Delivery":
  //     filterFn = (a) => a.status === "Waiting for Delivery";
  //     break;

  //   case "Delivery in Progress":
  //     filterFn = (a) => a.status === "Delivery in Progress";
  //     break;

  //   case "Delivered":
  //     filterFn = (a) => a.status === "Delivered";
  //     break;

  //   default:
  //     throw new Error("Unsupported Filter Type");
  // }
  // return (orders ?? []).filter(filterFn);
  console.log(orders);
  return (orders ?? []).filter(() => true);
};
