import { useState } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
// import cogoToast from "cogo-toast";
import { userUpdateActionRequest } from "../../store/actions/user";
import { editProfileValidations, signupValidations } from "../../configurations/Schemas";
import { PhoneNumberInput, DatePicker } from "../../component/generic";
// import close_dark from "./../../assets/images/close-dark-ico.svg";
// import next_arrow from "./../../assets/images/next-arrow.svg";
// import { v4 as uuidv4 } from "uuid";
// import jsCookie from "js-cookie";

import {
  // getYear,
  // addMonths,
  // isAfter,
  // isValid,
  // addDays,
  addYears,
  // parseISO,
  // parseJSON,
} from "date-fns";
import "../../assets/react-datepicker.css";
// import { PrimaryBtn } from "../../component/button/Button";

const userGenderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Not comfortable to share", value: "Others" },
];

const ProfileForm = (props) => {
  // console.log("RegisterForm Component Props", props);
  const [name, setName] = useState(props.name);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(
    `+${props.dialing_code}${props.phonenumber}`
  );
  const [phoneData, setPhoneData] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState(new Date(props.dob));
  const [gender, setGender] = useState({
    label: props.gender,
    value: props.gender,
  });
  const [address, setAddress] = useState(props.address);

  const callApi = (formValues) => {
    console.log("call api formValues >>", formValues);
				console.log("call api phoneData >>", phoneData);

    let tempphonenumber = formValues.phone.replace(`${phoneData.dialCode}`, "");

				// console.log("dobValid >>", dobValid, formValues.dateOfBirth.toISOString());
				let dobVal = formValues.dateOfBirth 
					? (typeof formValues.dateOfBirth == "string" 
 					? formValues.dateOfBirth : formValues.dateOfBirth.toISOString())
					: props.dob;
								
    let requestBody = {
      profile_id: props.profile_id,
      name: formValues.name ? formValues.name : "",
      dialing_code: phoneData.dialCode ? phoneData.dialCode : "",
      phonenumber: tempphonenumber,
      email: formValues.email ? formValues.email : "",
      dob: dobVal,
      gender:
        formValues.gender && formValues.gender.value
          ? formValues.gender.value
          : "",
      address: formValues.address ? formValues.address : ""
    };

				if(!requestBody.dialing_code.length > 0){
					requestBody.dialing_code =  props.dialing_code;
					requestBody.phonenumber = props.phonenumber;
				}
    console.log("userUpdateActionCall requestBody >>", requestBody);
    props.userUpdateActionCall(requestBody, () => {
      console.log("userUpdateActionCall callback called >>", true);
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          dateOfBirth,
          gender,
          address,
        }}
        // validateOnBlur
        // validateOnChange
        validationSchema={editProfileValidations}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log("Form Values", values);
          try{

            callApi(values);
            props.closeModals()
          }catch(err){
            console.log(err)
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          validateForm,
          setFieldTouched,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              {console.log("form errors", errors)}
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      isInvalid={errors.name && touched.name}
                      placeholder="Full Name"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setName(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.name && touched.name ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled={props.inputState}
                      type="email"
                      name="email"
                      value={values.email}
                      isInvalid={errors.email && touched.email}
                      placeholder="test@example.com"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setEmail(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.email && touched.email ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    {/* <Form.Label>Investor Type</Form.Label> */}
                    <div
                      style={{
                        display: "inline-flex",
                        width: "max-content",
                        position: "inherit",
                      }}
                    >
                      {/* {console.log("gender >>", values.gender.value)} */}
                      {userGenderOptions.map((item) => (
                        <Form.Check
                          name="gender"
                          style={{ marginLeft: "15px" }}
                          id={item.label}
                          key={item.label}
                          value={item.value}
                          checked={values.gender.value === item.value}
                          onChange={(e) => {
                            setGender(item);
                            setFieldValue("gender", item);
                          }}
                          type="radio"
                          label={item.label}
                        />
                      ))}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className={`m-0 mb-1 phone_input`}
                  >
                    <Form.Label>Phone</Form.Label>
                    <PhoneNumberInput
                      inputStyle={{
                        backgroundColor: "rgb(255 255 255)",
                        color: `black`,
                      }}
                      buttonStyle={{ backgroundColor: "rgb(255 255 255)" }}
                      // disabled={inputsDisabled()}
                      value={phone}
                      changeHandler={(
                        changeValue,
                        country,
                        e,
                        formattedValue
                      ) => {
                        // console.log(
                        //   "PhoneNumberInput changeValue >>",
                        //   changeValue
                        // );
                        // console.log("PhoneNumberInput country >>", country);
                        // console.log(
                        //   "PhoneNumberInput formattedValue >>",
                        //   formattedValue
                        // );
                        setPhone(changeValue);
                        setPhoneData(country);
                        // setFieldValue("phone", changeValue);
                      }}
                      placeholder="Enter your telephone number*"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <div className="col">
                      <DatePicker
                        updateDateComp={0}
                        initialDate={addYears(Date.now(), -13)}
                        showTime={false}
                        minDate={addYears(Date.now(), -100)}
                        maxDate={addYears(Date.now(), -13)}
                        showDisabledMonthNavigation
                        changeHandler={(date) => {
                          // console.log("changeHandler DatePicker >>", date);
                          setDateOfBirth(date.toISOString());
                          setFieldValue("dateOfBirth", date.toISOString());
                        }}
                      />
                    </div>
                    {errors.dateOfBirth && touched.dateOfBirth ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.dateOfBirth}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col">
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      isInvalid={errors.address && touched.address}
                      placeholder="Shipping Address"
                      onChange={(e) => {
                        handleChange(e);
                        // setFieldValue("name", e.value);
                        setAddress(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    {errors.address && touched.address ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="form-group mt-5 mb-0">
                {/* <Button
                  onClick={() => {
                    props.closeModals();
                  }}
                  className="btn btn-primary mr-2"
                >
                  Cancel
                </Button> */}
                <Button
                  type="submit"
                  // disabled={props.modalBtnLoader}
                >
                  {props.modalBtnLoader ? (
                    <Spinner animation="border" variant="light" />
                  ) : (                    
                    `Save`
                  )}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  store: state,
  user: state.user,
  userData:
    state.user.userData && state.user.userData ? state.user.userData : [],
  userAuth:
    state.user.userData && state.user.userData.otp_verification_status === 1
      ? true
      : false,
  profile_id:
    state.user.userData && state.user.userData.profile_id
      ? state.user.userData && state.user.userData.profile_id
      : "",
  name:
    state.user.userData && state.user.userData.name
      ? state.user.userData && state.user.userData.name
      : "",
  email:
    state.user.userData && state.user.userData.email
      ? state.user.userData && state.user.userData.email
      : "",
  gender:
    state.user.userData && state.user.userData.gender
      ? state.user.userData && state.user.userData.gender
      : null,
  dialing_code:
    state.user.userData && state.user.userData.dialing_code
      ? state.user.userData && state.user.userData.dialing_code
      : "",
  phonenumber:
    state.user.userData && state.user.userData.phonenumber
      ? state.user.userData && state.user.userData.phonenumber
      : "",
  dob:
    state.user.userData && state.user.userData.dob
      ? state.user.userData && state.user.userData.dob
      : null,
  address:
    state.user.userData && state.user.userData.address
      ? state.user.userData && state.user.userData.address
      : "",
});

const mapDispatchToProps = (dispatch) => {
  return {
    userUpdateActionCall: (...args) => {
      dispatch(userUpdateActionRequest(...args));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
