import { Avatar, Empty, Image, Space, Typography } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import defaultImg from './../../../assets/images/default.png';
// import avatar from "./../../../assets/images/avatar.png";
// import chat_ftr from "./../../../assets/images/chat-ftr.svg";
import Notify from 'cogo-toast';
import jsCookie from 'js-cookie';
import io from 'socket.io-client';
import api from '../../../apis';
// import { roundToNearestMinutes } from "date-fns/esm";
// import { GrClose } from "react-icons/gr";
// import { FiSend } from "react-icons/fi";
import { BsChatRightDots, BsChatSquareText } from 'react-icons/bs';
import { IoSendSharp } from 'react-icons/io5';
import chat from '../../../assets/images/chat.png';
import logo from '../../../assets/images/logo.png';
import Footer from '../../../component/footer/Footer';
import { serverurl } from '../../../helper';
import NoResult from '../Error/NoResult';
import { getDealResult } from '../Live/service';
import SystemChat, { MerchantChat, SysChatBody } from './SingleChat';
import { Spinner } from 'react-bootstrap';

const { Title } = Typography;

const endpoint = serverurl;
let socket;

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      merchant: '',
      chats: [],
      productid: '',
      usergroups: [],
      roomid: '',
      textmessage: '',
      showChat: false,
      currentRoom: {},
      lastMessages: [],
      notifications: [],
      showDealMatch: true,
      dealresults: [],
      products: [],
      loading: true,
      dealResultsLoading: false
    };
  }

  componentDidMount() {
    const productid = this.props.location.search
      ? this.props.location.search.split('=')[1]
      : null;
    socket = io(endpoint);
    this.setState({ productid });
    const user = jsCookie.get('userinfo');
    if (!user) {
      Notify.error('You need to be logged in.');
    } else {
      //Load Products Name and Prices
      api
        .fetchProductsForLiveResults({})
        .then((res) => {
          this.setState({ products: res.result });
        })
        .catch((err) => {
          console.log(err);
        });

      this.setState({ user: JSON.parse(user) }, () => {
        api
          .getUserGroups({ id: this.state.user.profile_id })
          .then((res) => {
            api
              .getNoifications({ id: this.state.user.sno })
              .then((res) => {
                const notifications = res.notifications.map((note) => {
                  return { ...note, notify: true };
                });
                this.setState({ notifications });
              })
              .catch((err) => {
                console.log(err);
              }).finally(() => {
                this.setState({ loading: false });
              });

            const groups = res.groups.map((group) => {
              return { ...group, created_date: group.msg_date };
            });
            this.setState({ usergroups: groups }, () => {
              console.log('Current User groups?>>>', this.state.usergroups);
              if (productid) {
                api
                  .productDetail(productid)
                  .then((res) => {
                    this.setState(
                      { merchant: res.merchantid, showChat: true },
                      async () => {
                        const commongroup = this.state.usergroups.filter(
                          (group) =>
                            group.exhibitor_id == res.merchantid &&
                            group.product_id == productid
                        );
                        console.log('Commong group', commongroup);
                        if (commongroup.length === 0) {
                          await api
                            .createUserGroup({
                              userid: this.state.user.sno,
                              merchantid: res.merchantid,
                              productid: this.state.productid,
                            })
                            .then(async (res) => {
                              this.setState({ roomid: res.group }, () => {
                                api
                                  .getUserGroups({ id: this.state.user.sno })
                                  .then((data) => {
                                    const currentRoom = data.groups.filter(
                                      (grp) => grp.id == res.group
                                    )[0];
                                    this.setState({ currentRoom });
                                  });
                                console.log('Roommm', this.state.roomid);
                                socket.emit('join', {
                                  user: JSON.parse(user),
                                  room: this.state.roomid,
                                });
                              });
                            })
                            .catch((err) => {
                              Notify.error('Sorry, an error occured');
                            });
                        } else {
                          this.setState(
                            {
                              roomid: commongroup[0].id,
                              currentRoom: commongroup[0],
                            },
                            () => {
                              socket.emit('join', {
                                user: JSON.parse(user),
                                room: this.state.roomid,
                              });
                            }
                          );
                          const data = {
                            userid: commongroup[0].profile_id,
                            productid: commongroup[0].product_id,
                          };
                          api
                            .getProductChats(data)
                            .then((res) => {
                              this.setState({
                                chats: res.chats,
                                showChat: true,
                              });
                            })
                          
                           
                        }

                        // const merchant = this.state.merchant ? this.state.merchant : null;
                        // const room = this.state.room;
                      }
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                  })
              }
            });
          })
          .catch((err) => {
            Notify.error('An error occured');
            // console.log(err);
          })
      });
      api
        .getLastChats({ id: JSON.parse(user).sno })
        .then((res) => {
          console.log(res);
          this.setState({ lastMessages: res.messages });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    socket.on('message', (message) => {
      const newChats = [...this.state.chats];
      newChats.push(message.chat);
      console.log('New chats', newChats);
      this.setState({ chats: newChats });
    });

    socket.on('room-data', (users) => {
      console.log(users);
      this.setState({ users });
    });

    const getNotifications = (id) => {
      api
        .getNoifications({ id })
        .then((res) => {
          const notifications = res.notifications.map((note) => {
            return { ...note, notify: true };
          });
          this.setState({ notifications: notifications });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    socket.on('updatenotificaton', (message) => {
      console.log(message);
      if (jsCookie.get('userinfo')) {
        getNotifications(JSON.parse(jsCookie.get('userinfo')).sno);
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.textmessage.trim().length > 0) {
      console.log(this.state.user);
      const message = {
        userid: this.state.user.profile_id,
        merchantid: this.state.currentRoom.exhibitor_id,
        message: this.state.textmessage,
        adminMessage: false,
        product_id: this.state.currentRoom.product_id,
        room: this.state.currentRoom.id,
        created: new Date(),
      };
      console.log('I am send this>>', message);
      socket.emit('send-message', message);
      console.log('Submitted!');
      this.setState({ textmessage: '' });
    }
  };

  displayChat = (group) => {
    socket.emit('join', { user: this.state.user, room: group.id });
    this.setState({ currentRoom: group, roomid: group.id });
    // console.log(group)
    console.log(group);
    const data = { userid: group.profile_id, productid: group.product_id };
    api
      .getProductChats(data)
      .then((res) => {
        this.setState({
          chats: res.chats,
          showChat: true,
          showDealMatch: false,
        });
        console.log(res);
      })
      .catch((err) => {
        Notify.error('Error while getting messages');
      });
  };

  getDealResults = (deal_id, user_id) => {
    this.setState({
      dealResultsLoading: true
    });
    api
      .liveDealResults({ userid: user_id, deal_id: deal_id })
      .then((res) => {
        console.log(res);
        this.setState({
          dealresults: res.data,
          showChat: false,
          showDealMatch: true,
        });
        this.setState({
          dealResultsLoading: false
        });
      })
      .catch((err) => {
        Notify.error('Error fetching results..');
        console.log(err);
      });
  };

  deleteNotification = (note) => {
    console.log(note);
    api
      .deleteDealNotification(note)
      .then((res) => {
        console.log(res);
        api.getNoifications({ id: this.state.user.sno }).then((res) => {
          const notifications = res.notifications.map((note) => {
            return { ...note, notify: true };
          });
          this.setState({ notifications: notifications });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className='dots-page'>
          <div className='dots'></div>
        </div>
      );
    }
    return (
      <>
        <main className='__cha_t' style={{ background: '', paddingBottom: '' }}>
          <section className='main-sectio pt-3'>
            {this.state.user && this.state.user.profile_id ? (
              <div className='container-fluid large chat-container'>
                <div className='row'>
                  <div
                    className='col-md-6 bg-white pb-5'
                    style={{
                      borderRight: '1px solid #e6edf0',
                    }}
                  >
                    <div className='row'>
                      <h5
                        className='col-12 mb-0 mt-md-4 text-center'
                        style={{
                          fontSize: '1.3rem',
                          borderBottom: '1px solid #eee',
                          padding: '1rem',
                        }}
                      >
                        <span className='pr-3'>All Chats</span>{' '}
                        <BsChatRightDots />
                      </h5>
                      <div className='col-12 px-0 chat-left'>
                        {/* System Generated chat */}
                        {[...this.state.notifications, ...this.state.usergroups]
                          ? [
                              ...this.state.notifications,
                              ...this.state.usergroups,
                            ]
                              .sort(
                                (a, b) =>
                                  new Date(b.created_date).getTime() -
                                  new Date(a.created_date).getTime()
                              )
                              .map((group, index) =>
                                group.notify ? (
                                  <div className='__sys_chat'>
                                    <SystemChat
                                      key={index}
                                      merName='EAMR System'
                                      pdtName={group.live_deal_product}
                                      imageSrc={
                                        group.product_image ||
                                        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200'
                                      }
                                      date={new Date(
                                        group.created_date
                                      ).toDateString()}
                                      msg={getDealResult(this.state, {
                                        deal_name: group.live_deal_product,
                                        min_value: group.deal_min_price,
                                        max_value: group.deal_max_price,
                                      })}
                                      link={
                                        '/product-detail/' + group.product_id
                                      }
                                      status={group.status}
                                      onClick={() =>
                                        group.status !== 0 &&
                                        this.getDealResults(
                                          group.deal_id,
                                          group.user_id
                                        )
                                      }
                                      deleteNotification={() =>
                                        this.deleteNotification(group)
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div
                                    key={group.product_id}
                                    className='__mer_chat'
                                  >
                                    <MerchantChat
                                      key={group.id}
                                      avatar='https://joeschmoe.io/api/v1/random'
                                      imageSrc={group.photo1 || defaultImg}
                                      merName={group.company_name}
                                      pdtName={group.product_name}
                                      lastMsg={
                                        this.state.lastMessages
                                          ? this.state.lastMessages.filter(
                                              (msg) =>
                                                msg.product_id ===
                                                group.product_id
                                            )[0]
                                            ? this.state.lastMessages.filter(
                                                (msg) =>
                                                  msg.product_id ===
                                                  group.product_id
                                              )[0].message
                                            : ''
                                          : null
                                      }
                                      date={`${new Date(
                                        group.created_date
                                      ).getHours()}
                                        :
                                        ${new Date(
                                          group.created_date
                                        ).getMinutes()}`}
                                      onClick={() => this.displayChat(group)}
                                      count={1}
                                    />
                                  </div>
                                )
                              )
                          : null}

                        {/* Merchant chat */}
                        {/* {this.state.usergroups ? (
                          this.state.usergroups.reverse().map((group) => {
                            return (
                              <div
                                key={group.product_id}
                                className="__mer_chat"
                              >
                                <MerchantChat
                                key={group.id}
                                  avatar="https://joeschmoe.io/api/v1/random"
                                  imageSrc={group.photo1 || defaultImg}
                                  merName={group.company_name}
                                  pdtName={group.product_name}
                                  lastMsg={
                                    this.state.lastMessages
                                      ? this.state.lastMessages.filter(
                                          (msg) =>
                                            msg.product_id === group.product_id
                                        )[0]
                                        ? this.state.lastMessages.filter(
                                            (msg) =>
                                              msg.product_id ===
                                              group.product_id
                                          )[0].message
                                        : ""
                                      : null
                                  }
                                  date={`${new Date(
                                    group.created_date
                                  ).getHours()}
                                        :
                                        ${new Date(
                                          group.created_date
                                        ).getMinutes()}`}
                                  onClick={() => this.displayChat(group)}
                                  count={1}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <p>No chats has been initiated yet.</p>
                        )} */}
                      </div>
                    </div>
                  </div>

                  {/* System Generated chat body */}
                  {
                    this.state.showDealMatch && (
                      <div
                        className='col-md-6 px-0 pt-md-4 bg-white '
                        style={{
                          boxShadow:
                            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                          borderLeft: '1px solid #e6edf0',
                        }}
                      >
                        <div className='chat-right mb-4'>
                          <div className=' __wrap_ch'>
                            <div className='chat-head'>
                            <Image
                                        src={logo}
                                        preview={false}
                                        alt='product'
                                        width={40}
                                        height={40}
                                       
                                      />
                              {/* <div className='avatar-imgg'>
                                <Avatar
                                  size={50}
                                  className='__avtr'
                                  style={{ border: '1px solid #eee' }}
                                  src={logo}
                                />
                              </div> */}
                              <h6 className='text-uppercase'>
                                <strong>{`EAMR`}</strong>
                              </h6>
                            </div>
                          </div>

                          <div className='mt-4'>
                            {this.state.dealResultsLoading ?   <div className="dots-page"><div className="dots"></div></div>:
                            <>
                            {this.state.dealresults &&
                            this.state.dealresults.length > 0 ? (
                              this.state.dealresults.map((result) => (
                                <SysChatBody
                                  product_id={result.sno}
                                  photo={result.photo1}
                                  product_name={result.name}
                                  description={result.description}
                                  price={result.price}
                                />
                              ))
                            ) : (
                              <div className='text-center pt-3 mb-4'>
                                <span className='mb-2 d-block'>
                                  <BsChatSquareText />
                                </span>
                                No product matches this deal
                              </div>
                            )}
                            </>}
                          </div>
                        </div>
                      </div>
                    )
                    // )
                  }

                  {/* Merchant chat body */}
                  {this.state.showChat ? (
                    <>
                      <div
                        className='col-md-6 px-0 pt-md-4 bg-white '
                        style={{
                          boxShadow:
                            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                          borderLeft: '1px solid #e6edf0',
                        }}
                      >
                        <div className='chat-right'>
                          <div className=' __wrap_ch'>
                            <div className='chat-head'>
                              <div className='avatar-img'>
                                <Avatar
                                  size={50}
                                  className='__avtr'
                                  style={{ border: '1px solid #eee' }}
                                  src='https://joeschmoe.io/api/v1/random'
                                />
                              </div>
                              <h6 className='text-uppercase'>
                                <strong>
                                  {this.state.currentRoom.company_name}
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div
                            className='chat-body mt-4'
                            style={{ overflowY: 'scroll', maxHeight: '500px' }}
                          >
                            <div className='chat-item justify-content-end'>
                              <div className='deal-snip with-img'>
                                <Space size={25}>
                                  <Link
                                    to={
                                      '/product-detail/' +
                                      this.state.currentRoom.product_id
                                    }
                                  >
                                    <div className=''>
                                      <Image
                                        src={
                                          this.state.currentRoom
                                            ? this.state.currentRoom.photo1
                                            : defaultImg
                                        }
                                        preview={false}
                                        alt='product'
                                        width={80}
                                        style={{
                                          borderRadius: '5px',
                                          border: '1px solid #eee',
                                        }}
                                      />
                                    </div>
                                  </Link>
                                  <div className='ch-desc'>
                                    <h6 className='text-capitalize'>
                                      {this.state.currentRoom.product_name}
                                    </h6>
                                    <p className='desc'>
                                      {this.state.currentRoom.description}
                                    </p>
                                    <p className='opacity-3 mb-0'>
                                      <strong>
                                        ${this.state.currentRoom.price}.00
                                      </strong>
                                    </p>
                                  </div>
                                </Space>
                              </div>
                            </div>
                            <div className='mt-4'>
                              {this.state.chats &&
                              this.state.chats.length > 0 ? (
                                this.state.chats.map((chat) => {
                                  return chat ? (
                                    chat.admin_msg === 'N' ? (
                                      <div
                                        className={
                                          'chat-item justify-content-end mb-2'
                                        }
                                      >
                                        <div className='chat-bubble blue'>
                                          <p>{chat.message}</p>
                                        </div>
                                        <div className='time mb-2 mt-1'>
                                          {`${new Date(
                                            chat.created_date
                                          ).toDateString()}
                                        /
                                        ${new Date(
                                          chat.created_date
                                        ).getHours()}
                                        :
                                        ${new Date(
                                          chat.created_date
                                        ).getMinutes()}`}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='chat-item justify-content-start mb-2'>
                                        <div className='chat-bubble'>
                                          <p>{`${chat.message}`}</p>
                                        </div>
                                        <span className='time mb-2 mt-1'>
                                          {`
                                        ${new Date(
                                          chat.created_date
                                        ).getHours()}
                                        :
                                        ${new Date(
                                          chat.created_date
                                        ).getMinutes()}`}
                                        </span>
                                      </div>
                                    )
                                  ) : null;
                                })
                              ) : (
                                <div className='text-center pt-3 mb-4'>
                                  <span className='mb-2 d-block'>
                                    <BsChatSquareText />
                                  </span>
                                  No chat has been initiated yet
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='chat-footer'>
                            <form onSubmit={this.handleSubmit}>
                              <div className='d-flex justify-content-md-between align-content-center __wrap_c'>
                                <input
                                  type='text'
                                  value={this.state.textmessage}
                                  onChange={(e) =>
                                    this.setState({
                                      textmessage: e.target.value,
                                    })
                                  }
                                  className='__chatinput w-full'
                                />
                                <button
                                  className='__chatbtn'
                                  onClick={this.handleSubmit}
                                >
                                  <span className='pr-2 d-sm-none'>Send</span>
                                  <IoSendSharp />
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className='col-md-6 d-flex justify-content-center align-items-center bg-white pb-5 d-md-flex'
                      style={{
                        borderLeft: '1px solid #e6edf0',
                      }}
                    >
                      <div className='pb-4'>
                        <div className='text-center'>
                          <Empty
                            image={chat}
                            imageStyle={{
                              height: 350,
                            }}
                            description={
                              <Title level={4}>Send and receive messages</Title>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <NoResult
                desc='You need to be logged in to see chats'
                _chat={true}
              />
            )}
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default Chat;
