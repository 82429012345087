export const registerValidation = values => {
    const errors = {}
    if (!values.full_name) {
        errors.full_name = 'Enter Your Full Name'
    }
    if (!values.email) {
        errors.email = 'Enter Your EMail'
    }
    if (!values.mobile_number) {
        errors.mobile_number = 'Enter Your Mobile Number'
    }else if(!values.mobile_number>0){
        errors.mobile_number = 'Incorrect Mobile Number'
    }
    if (!values.address) {
        errors.address = 'Enter Your Address'
    }
    return errors
}

export const loginValidation = values => {
    const errors = {}
    if (!values.mobile_number) {
        errors.mobile_number = 'Enter Your Mobile Number'
    }
    return errors
}