import React, { Component } from "react";
import product_img1 from "./../../../assets/images/product-img1.png";
import avatar from "./../../../assets/images/avatar.png";
import star_yellow_ico from "./../../../assets/images/star-yellow-ico.svg";
import next_arrow from "./../../../assets/images/next-arrow.svg";
import { Link } from "react-router-dom";
import Layout from "../Layouts/PageLayouts";
import SingleReview from "./SingleReview";
import api from "../../../apis";
import Notify from "cogo-toast";
import jsCookie from "js-cookie";
import NoResult from "../Error/NoResult";

class MyReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [],
            productID: "",
        };
    }

    componentDidMount() {
        const userinfo = jsCookie.get("userinfo") ? JSON.parse(jsCookie.get("userinfo")) : null;
        if(!userinfo) {
            return this.props.history.push("/");
        }
        api.getMyReviews({profile_id: userinfo.sno})
            .then((res) => {
                if(res.success) {
                    this.setState({reviews: res.result})
                } else{
                    this.props.history.push("/")
                }
            })
            .catch((err) => {
                Notify.error("Error fetching reviews")
                this.props.history.push("/");
            });
    }

    formatDate = (num) => {
        return num < 10 ? "0" + num : num;
    };

    render() {
        return (
            <>
                <Layout style={{ marginTop: "100px" }}>
                    <div className="container large">
                        <div className="row">
                            <div className="col-12 review-title-wrap mb-4">
                                <Link
                                    to={
                                        "/profile"
                                    }
                                    className="back-ico"
                                >
                                    <img src={next_arrow} alt="icon" />
                                </Link>
                                <h1>My Reviews</h1>
                            </div>
                        </div>

                        <div className="row mt-2">
                            { this.state.reviews.length > 0 ?
                             this.state.reviews.reverse().map((review) => (
                                <SingleReview
                                    avatar={review.product_photo || avatar}
                                    product={{name: review.product_name, price: review.product_price, quantity: review.product_quantity, id: review.sno}}
                                    star_icon={star_yellow_ico}
                                    review={{date: review.review_date, rating: review.review_rating, comment: review.reviewdesc}}
                                    orderdate={ review.order_date}
                                />
                            )) : (
                                <NoResult desc="You have not added review for any product yet." />
                            )}
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default MyReviews;
